// import logger from "../utils/logger";
const isProduction = window.location.origin === "https://app.spacia.africa";

// Disable console.* methods in production
if (isProduction && window.console) {
    const noop = () => {};
    type ConsoleMethod = keyof Console;
    const methods: ConsoleMethod[] = ["log", "debug", "info", "warn", "error"];
    methods.forEach((method) => {
        (console[method] as any) = noop;
    });
}

const logger = {
    log: (...args: any[]) => {
        if (!isProduction) {
            window.console.log(...args);
        }
    },
    error: (...args: any[]) => {
        if (!isProduction) {
            window.console.error(...args);
        }
    },
    warn: (...args: any[]) => {
        if (!isProduction) {
            window.console.warn(...args);
        }
    },
    info: (...args: any[]) => {
        if (!isProduction) {
            window.console.info(...args);
        }
    },
    debug: (...args: any[]) => {
        if (!isProduction) {
            window.console.debug(...args);
        }
    },
};

export default logger;
