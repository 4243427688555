import React, { useState, useEffect, useCallback } from 'react';
import { Container, Table, Row, Col, InputGroup, FormControl, Card, Form, Modal } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import InformationModal from '../../../../components/informationModal';
import FeedbackModall from '../../../../components/FeedbackModall';
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import HostsRow from "../../../../components/hosts/pending";
import ReactPaginate from "react-paginate";
import Pagination from "../../../../components/Pagination";
import { HostStyles } from "../../../../components/HostStyles";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import rejectedImage from "../../../../assets/img/png/rejected.png";
import SuccessAlert from '../../../../components/Settings/alerts/SuccessAlert';
import logger from "../../../../utils/logger";

const CompanyApprovals = () => {
    const [pendingHosts, setPendingHosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [declineReason, setDeclineReason] = useState('');

    // Accept Changes Modal
    const [showModal, setShowModal] = useState(false);

    const [showDeclineReasonModal, setShowDeclineReasonModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    // Decline Changes Modal
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const openDeclineModal = () => setShowDeclineModal(true);
    const closeDeclineModal = () => setShowDeclineModal(false);


    // Show Feedback M
    const [showFeedbackModal, setshowFeedbackModal] = useState(false);

    const openDeclineModalFeedback = () => {
        setShowDeclineModal(false);
        setshowFeedbackModal(true);
    };
    const openAcceptModalFeedback = useCallback(() => {
        setShowModal(false);
        const companyIds = pendingHosts.filter(o => o.checked === true).map(o => o.companyId);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyIds.join(','), id, true)
            .then(res => {
                if (res.status === 200) {
                    window.location.reload(false);
                }
            })
            .finally(() => {
                setShowModal(false);
            });
    });

    const closeFeedbackDeclineModal = () => setshowFeedbackModal(false);

    useEffect(() => {
        apiService.getPendingHosts(currentPage)
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setPendingHosts(res.data.data);
                    const anyHostSelected = res.data.data.some(host => host.checked);
                    setIsAnyHostSelected(anyHostSelected);
                }
            });
    }, []);

    const declineChanges = (e) => {
        e.preventDefault();
        const companyIds = pendingHosts.filter(o => o.checked === true).map(o => o.companyId);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyIds.join(','), id, false, declineReason)
            .then(res => {
                handleStuffOnModalHide();
                setshowFeedbackModal(true);
            })
            .finally(() => {
                handleStuffOnModalHide();
                setshowFeedbackModal(true);
            });
    };

    const changePage = (selected) => {
        setCurrentPage(selected);
        setPendingHosts([]);

        apiService.getPendingHosts(selected - 1, searchValue)
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setPendingHosts(res.data.data);
                }
            });
    };

    const performSearch = (e) => {
        apiService.getPendingHosts(currentPage, searchValue)
            .then(res => {
                if (res.status === 200) {
                    const pendingHosts = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setPendingHosts((pendingHosts) ? pendingHosts : []);
                }
            });
    };

    function handleStuffOnModalHide() {
        setShowDeclineReasonModal(false);

        const companyApprovalsContainer = document.querySelector('.company-approvals');
        if (companyApprovalsContainer != null) {
            companyApprovalsContainer.classList.remove('blur-background');
        }
    }

    const displayDeclineModal = () => {
        setShowDeclineReasonModal(true);

        const companyApprovalsContainer = document.querySelector('.company-approvals');
        if (companyApprovalsContainer != null) {
            companyApprovalsContainer.classList.add('blur-background');
        }
    };

    const [isAnyHostSelected, setIsAnyHostSelected] = useState(false);

    const updateIsAnyHostSelected = () => {
        const anyHostSelected = pendingHosts.some(host => host.checked);
        setIsAnyHostSelected(anyHostSelected);
    };

    return (
        <Container fluid className={'company-approvals'}>
            <SuccessAlert show={ showFeedbackModal } message={ "Successfully declined hosts" } close={ ()=> setshowFeedbackModal(false) }/>

            <Row style={{ alignItems: 'center' }}>
                <Col md="4">
                    <h4><b>Pending Hosts</b></h4>
                </Col>
                <Col md="4">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1" onClick={performSearch}><FaSearch /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </InputGroup>
                </Col>
                <Col className={'text-right'}>
                    <button disabled={!isAnyHostSelected} style={{ marginRight: 10, padding: '10px 20px' , backgroundColor: isAnyHostSelected ? '#FE8D8D' : '#E0E0E0', color: isAnyHostSelected ? '#FAFAFA' : '#454545' }} className="button" onClick={displayDeclineModal}>Decline</button>
                    <button disabled={!isAnyHostSelected} className="button" style={{ padding: '10px 20px' , backgroundColor: isAnyHostSelected ? '#106EF1' : '#E0E0E0', color: isAnyHostSelected ? '#FAFAFA' : '#454545' }} onClick={openModal}>Approve</button>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <HostStyles>
                        <Card>
                            <Card.Body>
                                <div className='table-wrapper'>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th><h6>Company</h6></th>
                                                <th><h6>Company Rep.</h6></th>
                                                <th><h6>Documents</h6></th>
                                                <th><h6>SignUp</h6></th>
                                                {/* <td><h6>Check All</h6></td> */}
                                                <th> <h6>Check All</h6> </th>
                                            </tr>
                                        </thead>
                                        {
                                            Array.isArray(pendingHosts) && pendingHosts.length > 0 &&
                                            pendingHosts.map(pendingHost => {
                                                logger.log('pending host:', pendingHost);
                                                return <HostsRow {...pendingHost} togglePendingHosts={(e) => {
                                                    pendingHosts.find(o => o.companyId === pendingHost.companyId).checked = e.target.checked;
                                                    updateIsAnyHostSelected();
                                                }} />;
                                            }
                                            )
                                        }
                                    </Table>
                                    {
                                        Array.isArray(pendingHosts) && pendingHosts.length > 0 &&
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage || 1}
                                            totalCount={totalPageSize}
                                            pageSize={itemsPerPage}
                                            onPageChange={changePage}
                                        />
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </HostStyles>
                </Col>

            </Row>

            <Modal show={showDeclineReasonModal} onHide={handleStuffOnModalHide} onEnter={e => logger.log(e)} contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    fontFamily: "Montserrat",
                    borderRadius: "30px",
                }}>

                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "0px",
                        paddingRight: "20px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        // id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ height: '' }}>
                    <div className="text-center position-relative" style={{ bottom: '20px' }}>
                        <p className={'text-primary mt-0 mb-2 mx-auto'} style={{ fontSize: '1.3rem', width: '60%', fontWeight: 400 }}>
                            Reason For Rejection
                        </p>
                        <form className={'mt-0'} onSubmit={declineChanges}>
                            <textarea
                                className={'form-control'}
                                placeholder={'Text Here'}
                                cols="30" rows="4"
                                onChange={e => setDeclineReason(e.target.value)}
                                style={{ resize: 'none' }} />
                            <div className={'mt-4'}>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        borderRadius: "20px",
                                        marginLeft: "0px",
                                        width: "5rem"
                                    }}
                                    disabled={!declineReason}
                                    type={'submit'}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <InformationModal body="Are you sure you want to approve host?" title="Confirm Approve Host" isOpen={showModal} isClose={closeModal} onSubmit={openAcceptModalFeedback} acceptButton="Approve" declineButton="Cancel" >
                <div style={{fontWeight: 400, fontSize: "1.2rem"}}>Are you sure you want to approve host?</div>
            </InformationModal>

            {/* Decline Changes Modal */}
            <InformationModal body="Are you sure you want to decline host?" title="Confirm Decline Host" isOpen={showDeclineModal} isClose={closeDeclineModal} onSubmit={declineChanges} acceptButton="Decline" declineButton="Cancel">
                <div style={{fontWeight: 400, fontSize: "1.2rem"}}>Are you sure you want to decline host?</div>
            </InformationModal>


            {/* Feedback Modal */}
            {/* <FeedbackModall body="Host approvals completed" title="Done" isOpen={showFeedbackModal} isClose={closeFeedbackDeclineModal} onSubmit={closeFeedbackDeclineModal} acceptButton="Okay" doneButton="Okay" declineButton="Cancel">
                <img src="assets/img/Web/Spacia/check.png" alt="check" />

                <div  style={{ paddingTop: 20, fontWeight:400 }}>Hosts declined</div>
            </FeedbackModall> */}
        </Container>
    );
};

export default CompanyApprovals;
