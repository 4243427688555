// @ts-nocheck

/**
 * Project: spaces
 * File: SignUp
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

import React, { FormEvent, useEffect, useState } from "react";
import { CloseCircle, EyeOutline, EyeOffOutline } from "react-ionicons";
// import { toAbsoluteUrl } from "../layout/helpers";
import { Link, useHistory } from "react-router-dom";
import { PageTitle, RightComponent, LeftComponent } from "../../layout/PageData";
import { toAbsoluteUrl } from "../../layout/helpers";
import SERVICES from "../../services";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getApp } from "firebase/app";
import apiService from "../../services/apiService";
import { AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import logger from "../../utils/logger";
import PhoneInput from 'react-phone-number-input';
import { dispatchUser } from '../../redux/actions/auth';
import { useDispatch } from 'react-redux';

const StyledInput = ({
	type,
	id,
	label,
	value,
	onChange,
	showPasswordToggle = false,
	required = true
}) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="form-group basic">
			<div className="input-wrapper">
				<input
					type={showPasswordToggle ? (showPassword ? "text" : "password") : type}
					className="form-control input-new-rounded floating-input"
					id={id}
					placeholder=" "
					value={value}
					onChange={onChange}
					required={required}
					style={{
						backgroundColor: 'white',
						WebkitBoxShadow: '0 0 0 1000px white inset',
						WebkitTextFillColor: '#000'
					}}
				/>
				<label
					className="floating-label-new-background"
					htmlFor={id}
					style={{
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "18px",
						letterSpacing: "0.02em",
						color: "#263238",
					}}
				>
					{label}
				</label>
				<i className="clear-input">
					{showPasswordToggle ? (
						<div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
							{showPassword ? (
								<EyeOffOutline color={"#00000"} title={"Hide password"} />
							) : (
								<EyeOutline color={"#00000"} title={"Show password"} />
							)}
						</div>
					) : (
						value && <CloseCircle
							color={"#00000"}
							title={"Clear"}
							onClick={() => onChange({ target: { value: '' } })}
							style={{ cursor: 'pointer' }}
						/>
					)}
				</i>
			</div>
		</div>
	);
};

const StyledPhoneInput = ({ value, onChange }) => {
	return (
		<div className="form-group basic">
			<div className="input-wrapper">
				<PhoneInput
					international
					defaultCountry="KE"
					value={value}
					onChange={onChange}
					className="input-new-rounded"
					style={{
						border: '1px solid #E4E6EF',
						borderRadius: '40px',
						padding: '0.75rem 1rem',
						background: 'white'
					}}
				/>
				<label
					className="floating-label-new-background"
					htmlFor="phone"
					style={{
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "18px",
						letterSpacing: "0.02em",
						color: "#263238",
					}}
				>
					{/* Phone Number */}
				</label>
			</div>
			<style jsx>{`
				.PhoneInput {
					margin: 0;
					padding: 0;
				}
				
				.PhoneInputInput {
					border: none !important;
					background: white !important;
					padding: 0 !important;
					margin: 0 !important;
					outline: none !important;
				}

				.PhoneInputInput:focus {
					outline: none !important;
					box-shadow: none !important;
				}
			`}</style>
		</div>
	);
};

const SignUp = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [errorModal, setErrorModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const [mobile, setMobile] = useState<string>("");

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState("ROLE_SUBSCRIBER_OWNER");
	const [organizationName, setOrganizationName] = useState("");
	const [organizationSize, setOrganizationSize] = useState("");
	const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState('');
	const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
	const [isEmptyLastname, setIsEmptyLastname] = useState(false);
	const [isEmptyEmail, setIsEmptyEmail] = useState(false);
	const [isEmptyPassword, setIsEmptyPassword] = useState(false);
	const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
	const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
	const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
	const [phoneNumberValidationError, setPhoneNumberValidationError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isEmptyOrganizationName, setIsEmptyOrganizationName] = useState(false);
	const [isEmptyOrganizationSize, setIsEmptyOrganizationSize] = useState(false);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	function hasText(text: string) {
		return text != null && text !== '';
	}

	useEffect(() => {
		if (hasText(firstName)) {
			setIsEmptyFirstname(false);
		}
		if (hasText(lastName)) {
			setIsEmptyLastname(false);
		}
		if (hasText(email)) {
			setIsEmptyEmail(false);
		}
		if (hasText(password)) {
			setIsEmptyPassword(false);
		}
		if (hasText(confirmPassword)) {
			setIsInvalidConfirmPassword(false);
		}
		if (password && !confirmPassword) {
			setIsInvalidConfirmPassword(false);
		}
		if (role) {
			setIsRoleNotSelected(false);
		}
		if (mobile) {
			setIsInvalidPhoneNumber(false);
		}
		if (organizationName) {
			setIsEmptyOrganizationName(false);
		}
		if (organizationSize) {
			setIsEmptyOrganizationSize(false);
		}
	}, [firstName, lastName, email, password, confirmPassword, mobile, role, organizationName, organizationSize]);

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const response = await apiService.getSupportedRoles();
				if (response.status === 200) {
					setRoles(response.data.data);
				}
			} catch (error) {
				logger.error("Error fetching roles:", error);
			}
		};

		fetchRoles();
	}, []);

	const areFieldsOkay = () => {
		const requiredFields = [
			firstName,
			lastName,
			email,
			password,
			confirmPassword,
			mobile,
			organizationName,
			organizationSize,
		];

		// Check all required fields
		const anyEmpty = requiredFields.some((field) => !field);
		if (anyEmpty) {
			if (!hasText(firstName)) {
				setIsEmptyFirstname(true);
			}
			if (!hasText(lastName)) {
				setIsEmptyLastname(true);
			}
			if (!hasText(email)) {
				setIsEmptyEmail(true);
			}
			if (!mobile) {
				setIsInvalidPhoneNumber(true);
				setPhoneNumberValidationError("Please enter mobile number");
			}
			if (!organizationName) {
				setIsEmptyOrganizationName(true);
			}
			if (!organizationSize) {
				setIsEmptyOrganizationSize(true);
			}
			return false;
		}

		// Validate password requirements
		const isPasswordValid = validatePassword(password);
		const isConfirmPasswordValid = validateConfirmPassword(password, confirmPassword);

		return isPasswordValid && isConfirmPasswordValid;
	};

	const validatePassword = (password: string) => {
		// Implement password validation logic here
		return true;
	};

	const validateConfirmPassword = (password: string, confirmPassword: string) => {
		// Implement confirm password validation logic here
		return true;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const userData = {
				avatar: "https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg",
				companyId: 1,
				confirmPassword,
				contacts: [
					{
						isContactable: true,
						isPrimary: true,
						type: "mobile",
						value: mobile,
					},
				],
				firstName,
				lastName,
				password,
				username: email,
				role,
				organizationName,
				organizationSize,
			};

			const response = await apiService.signup(userData);

			if (response.status === 201 || response.status === 200) {
				// Store partial user data
				const partialUserData = {
					email,
					firstName,
					lastName
				};
				dispatch(dispatchUser(partialUserData));
				SERVICES.saveUser(partialUserData);

				// Redirect to verification page
				history.push('/verification-code', { email });
				toast.success('Please check your email for verification code');
			}
		} catch (error: any) {
			const errorMessage = error.response?.data?.message || "An error occurred during signup";
			toast.error(errorMessage);
		} finally {
			setIsLoading(false);
		}
	};

	const googleSignUp = async () => {
		try {
			const auth = getAuth();
			const provider = new GoogleAuthProvider();
			provider.setCustomParameters({
				prompt: "select_account"
			});

			const result = await signInWithPopup(auth, provider);
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const accessToken = credential?.accessToken;

			logger.log(accessToken);

			const payload = {
				accessToken,
				companyId: 1,
				provider: "GOOGLE"
			};

			const response = await apiService.googleAuth(payload);

			const fcmToken = await getFCMToken();
			if (fcmToken && response.data && response.data.data && response.data.data.length > 0) {
				await updateUserProfile(response.data.data[0], fcmToken);
			}

			onLoginSuccessful(response);
		} catch (error) {
			logger.error("Google sign up error:", error);
			onLoginError(error);
		}
	};

	const getFCMToken = async () => {
		try {
			const app = getApp();
			const messaging = getMessaging(app);
			const token = await getToken(messaging);
			logger.log("FCM Token:", token);
			return token;
		} catch (error) {
			logger.error("Error getting FCM token:", error);
			return null;
		}
	};

	const updateUserProfile = async (user: any, fcmToken: string) => {
		try {
			const payload = {
				firstName: user.firstName,
				lastName: user.lastName,
				contacts: [
					{
						deviceTokens: [],
						isContactable: true,
						isPrimary: true,
						type: "mobile",
						value: user.contacts.find((contact: any) => contact.type === "mobile")?.value,
					},
					{
						deviceTokens: [],
						isContactable: true,
						isPrimary: false,
						type: "deviceToken",
						value: fcmToken,
					},
				],
				avatar: user.avatar,
			};

			const response = await apiService.updateUserProfile(user.id, payload);
			logger.log("User profile updated successfully:", response.data);
		} catch (error) {
			logger.error("Error updating user profile:", error);
		}
	};

	const onLoginSuccessful = (res: AxiosResponse<any>) => {
		const response = res.data;
		const responseData = response?.data;
		const currentUser = (responseData && responseData.length > 0) ? responseData[0] : null;

		SERVICES.saveUser(currentUser);

		const role = currentUser?.role;
		logger.log('current user role is:', role);

		if (role != null) {
			switch (role) {
				case 'ROLE_ADMINISTRATOR':
				case 'ROLE_HOST':
				case 'ROLE_SUBSCRIBER_INDIVIDUAL':
				case 'ROLE_SUBSCRIBER_APPROVER':
				case 'ROLE_SUBSCRIBER_OWNER':
				case 'ROLE_HOST_OPERATIONS':
					history.push('/searchPage');
					break;

				default:
					history.push('/home');
			}
		}
	};

	const onLoginError = (res: any) => {
		logger.log(res);
		setErrorModal(false);
	};

	return (
		<div className="position-relative overflow-hidden" style={{ marginTop: "-56px" }}>
			<PageTitle>{false}</PageTitle>
			<RightComponent>{false}</RightComponent>
			<LeftComponent>{false}</LeftComponent>

			<div className="section position-relative p-0">
				<img
					className="w-100"
					decoding={"async"}
					src={toAbsoluteUrl("/img/Rectangle 18.png")}
					alt={"Logo"}
				/>

				<div className="section mt-2 position-absolute"
					style={{ bottom: "88px", left: "16px" }}>
					<h2 className={"text-capitalize text-white"}>Sign up</h2>
					<h4 className={"mt-2 text-white"}>Welcome to SPACIA!</h4>
				</div>
			</div>

			<div className="section mb-5 p-2 position-relative"
				style={{
					backgroundColor: "white",
					borderTopRightRadius: "30px",
					borderTopLeftRadius: "30px",
					marginTop: "-21px",
				}}>
				<form onSubmit={handleSubmit}>
					<div className="card-body pb-1">
						<StyledInput
							type="text"
							id="firstName"
							label="First Name"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>

						<StyledInput
							type="text"
							id="lastName"
							label="Last Name"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>

						<StyledInput
							type="email"
							id="email"
							label="Email Address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>

						<StyledInput
							type="text"
							id="organizationName"
							label="Organization Name"
							value={organizationName}
							onChange={(e) => setOrganizationName(e.target.value)}
						/>

						<StyledInput
							type="number"
							id="organizationSize"
							label="Team Size"
							value={organizationSize}
							onChange={(e) => setOrganizationSize(e.target.value)}
						/>

						<StyledPhoneInput
							value={mobile}
							onChange={setMobile}
						/>

						<StyledInput
							type="password"
							id="password"
							label="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							showPasswordToggle={true}
						/>

						<StyledInput
							type="password"
							id="confirmPassword"
							label="Confirm Password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							showPasswordToggle={true}
						/>

						<div className="d-flex flex-column gap-3 mt-4">
							<div className={"agree-to-terms"}>
								<p className={"text-dark"} style={{ marginBottom: "20px" }}>
									By creating an account, you agree to the{" "}
									<a
										href={process.env.PUBLIC_URL + '/assets/terms_conditions.pdf'}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											textDecoration: "underline",
											color: '#000000',
											fontWeight: 500,
											borderBottom: '1px solid #000000',
											paddingBottom: '2px'
										}}
									>
										terms & conditions
									</a>{" "}
									for the service.
								</p>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn-lg w-100"
								style={{ borderRadius: "40rem" }}
								disabled={isLoading}
							>
								{isLoading ? (
									<div className="spinner-border spinner-border-sm text-light" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								) : 'Sign up'}
							</button>

							<div className="text-center">
								<p className="text-dark mb-0">
									Already have an account? <Link to={"/signin"}>Sign In</Link>
								</p>
							</div>

							<div className="text-center mt-3">
								<Link 
									to="/home" 
									className="d-block mb-3" 
									style={{ 
										color: '#4F46E5', 
										textDecoration: 'none', 
										fontSize: '0.9rem',
										fontWeight: '500'
									}}
								>
									← Go to Home
								</Link>
								<p className="text-muted mb-1" style={{ fontSize: '0.7rem', opacity: 0.6 }}>
									Powered by theSOFTtribe
								</p>
								<p className="text-muted text-capitalize mb-0">
									&copy;2024 SPACIA All rights reserved
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignUp;
