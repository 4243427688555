import React, {useEffect} from "react";
import { useHistory } from 'react-router-dom';
import SERVICES from "../services";
import logger from "../utils/logger";

const PaymentTableRow = ({date, bank, account, status, amount,currency, onView, invoice, mobileWalletAccounts, setHasNoWalletWalletAccount}) => {
	const active = {
		backgroundColor: "#F3D5D1",
		color: "#F85A47",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const paid = {
		backgroundColor: "#D1F3D6",
		color: "rgb(6, 128, 10)",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const cancelled = {
		backgroundColor: "#F8D147",
		color: "#F3E5D1",
		border: "none",
		fontWeight: "bold",
		borderRadius: 5,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 20,
		paddingRight: 20,
	};

	const history = useHistory();

	const showMakePaymentPage = () => {
		if (mobileWalletAccounts && mobileWalletAccounts.length > 0) {
			history.push({
				pathname: '/make-payment',
				state: invoice
			});
		} else {
			setHasNoWalletWalletAccount(true);
		}
	}

	const statusHTML = (status) => {
		let button
		logger.log(status)
		if (status === 'ACTIVE'){
			button =  <button className="user-admin" style={active}>
				{status}
			</button>
		}
		else if (status === 'PAID'){
			button =  <button className="user-admin" style={paid}>
				{status}
			</button>
		}
		else if (status === 'CANCELLED'){
			button =  <button className="user-admin" style={cancelled}>
				{status}
			</button>
		}
		return button
	}

	return (
		<tr>
			<td style={{width: '20%', textAlign: 'left'}}>{date}</td>
			<td style={{display: 'none'}}>
				<div>
					{/* <img src={bank} style={{marginRight: 10}} alt="User" /> */}
					{account}
				</div>
			</td>
			<td style={{width: '30%', textAlign: 'right'}}>
				<h6 style={{margin: 0}}>{currency || 'KES'} {(amount ? Number(amount) : 0).toLocaleString()}</h6>
			</td>
			<td style={{width: '25%', textAlign: 'right'}}>
				<a href="#" onClick={onView}>View</a>
				<div style={{display: 'inline-block', marginLeft: '20px'}} onClick={showMakePaymentPage}>
					<span style={{color: '#0d6efd', cursor: 'pointer'}}>Pay</span>
				</div>
			</td>
			<td style={{width: '25%', textAlign: 'right'}}>
				{statusHTML(status)}
			</td>
		</tr>
	);
};

export default PaymentTableRow;
