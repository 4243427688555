import React from "react";
import { toAbsoluteUrl } from "../../../layout/helpers";
import { Link } from "react-router-dom";
import HostLandingLayout from "../../../layout/host-landing-layout-mobile/host-landing-layout";
import "./styles.scss";
// import HostLandingLayout from "../../layout/host-landing-layout-mobile/host-landing-layout";

// export type HostLandingProps = {};

// eslint-disable-next-line no-empty-pattern
export default function HostLanding() {
  return (
    <HostLandingLayout>
      <div className="host-landing-mobile">
        {/* Hero Section */}
        <section className="hero-section">
          <img
            src={toAbsoluteUrl("/assets/img/spacia/web/spacia_logo.png")}
            alt="Spacia Logo"
            className="logo"
          />
          <h1>Let Your Space Earn Smarter</h1>
          <p>
            With an increased demand for flexible workspace, now is the time to
            capitalize on your unused space and grow your earnings.
          </p>
          <Link to="/register-host" className="cta-button">
            Get Started
          </Link>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>Why Host with Spacia?</h2>
          <div className="features-grid">
            <div className="feature-card">
              <div className="icon-wrapper">
                <img src={toAbsoluteUrl("/img/analytics.svg")} alt="Revenue" />
              </div>
              <h3>Maximize Revenue</h3>
              <p>Turn your unused space into a profitable asset</p>
            </div>
            <div className="feature-card">
              <div className="icon-wrapper">
                <img src={toAbsoluteUrl("/img/calender.svg")} alt="Flexibility" />
              </div>
              <h3>Full Flexibility</h3>
              <p>Control your space availability and pricing</p>
            </div>
            <div className="feature-card">
              <div className="icon-wrapper">
                <img src={toAbsoluteUrl("/img/notification.svg")} alt="Network" />
              </div>
              <h3>Corporate Network</h3>
              <p>Access our pool of verified corporate clients</p>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works-section">
          <h2>How It Works</h2>
          <div className="steps">
            <div className="step">
              <div className="step-number">1</div>
              <div className="step-content">
                <h3>Sign Up</h3>
                <p>Create your free host account in minutes</p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <div className="step-content">
                <h3>List Your Space</h3>
                <p>Add photos, amenities, and set your pricing</p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <div className="step-content">
                <h3>Start Earning</h3>
                <p>Receive bookings and payments securely</p>
              </div>
            </div>
          </div>
        </section>

        {/* Tools Section */}
        <section className="tools-section">
          <h2>Powerful Tools for Hosts</h2>
          <div className="tools-list">
            <div className="tool-item">
              <div className="tool-icon" data-color="blue">
                <img src={toAbsoluteUrl("/img/calender.svg")} alt="Calendar" />
              </div>
              <span>Smart Calendar</span>
            </div>
            <div className="tool-item">
              <div className="tool-icon" data-color="green">
                <img src={toAbsoluteUrl("/img/analytics.svg")} alt="Analytics" />
              </div>
              <span>Real-time Analytics</span>
            </div>
            <div className="tool-item">
              <div className="tool-icon" data-color="orange">
                <img src={toAbsoluteUrl("/img/notification.svg")} alt="Notifications" />
              </div>
              <span>Instant Notifications</span>
            </div>
            <div className="tool-item">
              <div className="tool-icon" data-color="purple">
                <img src={toAbsoluteUrl("/img/qrCode.svg")} alt="QR Code" />
              </div>
              <span>QR Check-in</span>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="cta-section">
          <h2>Ready to Start?</h2>
          <p>Join thousands of successful hosts on Spacia</p>
          <Link to="/register-host" className="cta-button">
            Become a Host
          </Link>
        </section>
      </div>
    </HostLandingLayout>
  );
}
