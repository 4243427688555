import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import { WithContext as ReactTags } from "react-tag-input";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Tabs,
  Tab,
  Row,
  Dropdown,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";

import uploadButton from "../../assets/img/Web/Spacia/Group 426.png";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { useHistory, useLocation } from "react-router-dom";
import "react-bootstrap-tagsinput/dist/index.css";
import * as SERVICES from "../../services/localService";
import { Constants } from "../../utils/constants";

import { trackPromise } from "react-promise-tracker";
import InformationModal from "../../components/informationModal";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import { FaTrashAlt } from "react-icons/fa";
import StayAmenities from "../../components/ListProperties/Form/StayAmenities";
import WorkAmenities from "../../components/ListProperties/Form/WorkAmenities";

import Photo from "./Photo";
import Gallery from "react-photo-gallery";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import apiService from "../../services/apiService";
import Interactive from "../../components/Interactive";
import logger from "../../utils/logger";

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => {
  return <Photo {...item} />;
});

const SortableGallery = SortableContainer(({ items }) => {
  return (
    <Gallery
      photos={items}
      renderImage={(props) => <SortablePhoto {...props} />}
    />
  );
});

const ListPropertyForm = () => {
  const [loadingState, setloadingState] = useState(false);

  // refs initialization
  const billingPeriodDropdownElement = useRef(null);
  const listingTypeDropdownElement = useRef(null);

  const location = useLocation();

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  const [state, setState] = useState({
    tags: [
      // { id: "Thailand", text: "Thailand" },
      // { id: "India", text: "India" }
    ],
    // suggestions: [
    //     { id: 'USA', text: 'USA' },
    //     { id: 'Germany', text: 'Germany' },
    //     { id: 'Austria', text: 'Austria' },
    //     { id: 'Costa Rica', text: 'Costa Rica' },
    //     { id: 'Sri Lanka', text: 'Sri Lanka' },
    //     { id: 'Thailand', text: 'Thailand' }
    //  ]
  });

  const moveToPropertiesPage = () => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    const storedListingData = SERVICES.getListingData();
    const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

    if (userId && listingOwnerId && userId === listingOwnerId) {
      SERVICES.clearListingData();
    }

    history.push("/properties");
  };

  const handleDelete = (i) => {
    const { tags } = state;
    setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  };

  const handleAddition = (tag) => {
    logger.log(tag);
    setState((state) => ({ tags: [...state.tags, tag] }));
    logger.log(state);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const tags = [...state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setState({ tags: newTags });
    logger.log("newTags");
    logger.log(newTags);
  };
  const { tags, suggestions } = state;

  const [additionalSpec, setadditionalSpec] = useState("");
  const [description, setdescription] = useState("");
  // details
  const [capacity, setcapacity] = useState("1");
  const [floornumber, setfloornumber] = useState("");
  const [propertySize, setpropertySize] = useState("1");
  const [coWorkingSpaceNumber, setCoWorkingSpaceNumber] = useState("2");
  const [isCoWorkingSpaceChecked, setIsCoWorkingSpaceChecked] = useState(false);
  const [availableSlotsError, setAvailableSlotsError] = useState("");
  // location
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("Kenya");
  const [language, setlanguage] = useState("");
  const [longitude, setlongitude] = useState("");
  const [latitude, setlatitude] = useState("");
  const [streetname, setstreetname] = useState("");
  // propertyprice
  const [billingPeriod, setbillingperiod] = useState("");
  const [createOn, setcreateOn] = useState("");
  const [price, setprice] = useState("");
  const [updatedOn, setupdatedOn] = useState("");
  // Prop
  const [propertyStatus, setpropertyStatus] = useState("");
  const [propertyTitle, setpropertyTitle] = useState("");
  const [propertyType, setpropertyType] = useState("");
  // const [tags, settags] = useState("");
  const [typeOfWorkspace, settypeOfWorkspace] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [chosenImage, setChosenImage] = useState(false);
  const [tOfSpace, settOfSpace] = useState("Not Set");
  const [tOfWorkspace, settOfWorkspace] = useState("Not Set");

  useEffect(() => {
    logger.log("localStorage");
    logger.log(localStorage.getItem("propertyType"));
    settOfSpace(eTypeOfSpace(localStorage.getItem("propertyType")));
    logger.log(localStorage.getItem("Workspace"));

    settOfWorkspace(eTypeOfSpace(localStorage.getItem("Workspace")));
    localStorage.getItem("propertyType");
    logger.log(tOfWorkspace);
  }, []);

  const [currentUserId, setCurrentUserId] = useState(0);
  const userCurrency = SERVICES.getCurrency();
  const [dropdown1, setdropdown1] = useState("Published");
  const [dropdown2, setdropdown2] = useState("HOURLY");
  const [listingType, setListingType] = useState("PUBLISHED");
  const [mediaId, setMediaId] = useState(0);
  const [imgtitle, setImgTitle] = useState("");
  const [imgDescription, setImgDescription] = useState("");

  // Work Amenitites
  // Amenities
  const [access, setAccess] = useState(false);
  const [loungeSpace, setloungeSpace] = useState(false);
  const [mothersRoom, setmothersRoom] = useState(false);
  const [outdoorspace, setoutdoorspace] = useState(false);
  const [whiteboard, setwhiteboard] = useState(false);
  const [bicycleRacks, setbicycleRacks] = useState(false);
  const [kitchenFacilities, setkitchenFacilities] = useState(false);
  const [onsiteDining, setonsiteDining] = useState(false);
  const [printingServices, setprintingServices] = useState(false);
  const [storage, setstorage] = useState(false);
  const [disableFriendly, setdisableFriendly] = useState(false);
  const [onSiteParking, setonSiteParking] = useState(false);
  const [vcFacilities, setvcFacilities] = useState(false);

  // Stay Amenities

  const [cancelation, setcancelation] = useState();
  const [pool, setpool] = useState();
  const [airportshuttle, setairportshuttle] = useState();
  const [spa, setspa] = useState();
  const [nonsmokingrooms, setnonsmokingrooms] = useState();
  const [frontview, setfrontview] = useState();
  const [restaurant, setrestaurant] = useState();
  const [freewifi, setfreewifi] = useState();
  const [coffeemaker, setcoffeemaker] = useState();
  const [bar, setbar] = useState();
  const [lifts, setlifts] = useState();
  const [food, setfood] = useState();
  const [parking, setparking] = useState();
  const [rooms, setrooms] = useState();
  const [washingmachine, setwashingmachine] = useState();
  const [gym, setgym] = useState();
  const [garbage, setgarbage] = useState();
  const [laundry, setlaundry] = useState();
  const [housekeeping, sethousekeeping] = useState();
  const [cctv, setcctv] = useState();
  const [hotwater, sethotwater] = useState();
  const [bills, setbills] = useState();
  const [cookers, setcookers] = useState();
  const [security, setsecurity] = useState();
  const [generator, setgenerator] = useState();
  const [dstv, setdstv] = useState();
  const [fence, setfence] = useState();

  const [imageTitleError, setimageTitleError] = useState("");
  const [imageDescriptionError, setimageDescriptionError] = useState("");
  // const [state, setstate] = useState(initialState)
  const [linkToVideoError, setlinkToVideoError] = useState("");

  const [requestHasErrors, setRequestHasErrors] = useState(false);

  const defaultCenter = {
    lat: -1.286389,
    lng: 36.817223,
  };

  const [isMediaUploaded, setisMediaUploaded] = useState(false);

  useEffect(() => {
    setImgDescription(imgtitle);
  }, [imgtitle]);

  useEffect(() => {
    //get current user
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;

    setCurrentUserId(userId);
  }, []);

  const [previewFilesArray, setPreviewFilesArray] = useState([]);
  const [sortableImages, setSortableImages] = useState(previewFilesArray);
  const [imagesUpdatedBeforeDelete, setImagesUpdatedBeforeDelete] =
    useState(false);
  const [idOfImageToDelete, setIdOfImageToDelete] = useState(0);
  const [imageToDelete, setImageToDelete] = useState(null);

  const removeImage = (image) => {
    logger.log("I was called. image:", image);
    logger.log(
      "inside remove image function. sortable images array:",
      sortableImages,
    );

    setSortableImages((prevState) => {
      logger.log("sortable image prev state:", prevState);
      return prevState;
    });

    setImagesUpdatedBeforeDelete(true);
    // setIdOfImageToDelete(id);
    setImageToDelete(image);

    // setPreviewFilesArray(previewFilesArray.filter(file => file.id !== id));
    // setSortableImages(sortableImages.filter(file => file.id !== id));
    // setImages(images.filter(image => image.id !== id));
  };

  useEffect(() => {
    if (imagesUpdatedBeforeDelete) {
      logger.log("about to remove images. sortable images:", sortableImages);

      const imageClientId = imageToDelete.clientId;

      setPreviewFilesArray(
        sortableImages.filter((file) => file.clientId !== imageClientId),
      );
      setImagesUpdatedBeforeDelete(false);
    }
  }, [sortableImages, imagesUpdatedBeforeDelete]);

  // For Blob
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
        logger.log("file reader event target result:", event.target.result);
      };
      reader.readAsDataURL(file);
    });

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  // const [state, setState] = useState(["Test"]);
  const [dataUri, setDataUri] = useState(imgplaceholder);
  const [zone, setZone] = useState([]);

  const [mediaLoadingState, setMediaLoadingState] = useState(false);

  const [filesArray, setfilesArray] = useState([]);

  const fileMapArray = [];

  const [images, setImages] = useState([]);

  const [selectedAmenities, setSelectedAmenities] = useState({});

  useEffect(() => {
    setSelectedAmenities({
      ...selectedAmenities,
      access,
      disableFriendly,
      loungeSpace,
      kitchenFacilities,
      mothersRoom,
      onsiteDining,
      onSiteParking,
      outdoorspace,
      printingServices,
      vcFacilities,
      whiteboard,
      bicycleRacks,
      storage,
      cancelation,
      pool,
      airportshuttle,
      spa,
      nonsmokingrooms,
      restaurant,
      freewifi,
      coffeemaker,
      bar,
      lifts,
      food,
      parking,
      rooms,
      washingmachine,
      gym,
      garbage,
      laundry,
      housekeeping,
      cctv,
      hotwater,
      bills,
      cookers,
      security,
      generator,
      dstv,
      fence,
      frontview,
    });
  }, [
    access,
    disableFriendly,
    loungeSpace,
    kitchenFacilities,
    mothersRoom,
    onsiteDining,
    onSiteParking,
    outdoorspace,
    printingServices,
    vcFacilities,
    whiteboard,
    bicycleRacks,
    storage,
    cancelation,
    pool,
    airportshuttle,
    spa,
    nonsmokingrooms,
    restaurant,
    freewifi,
    coffeemaker,
    bar,
    lifts,
    food,
    parking,
    rooms,
    washingmachine,
    gym,
    garbage,
    laundry,
    housekeeping,
    cctv,
    hotwater,
    bills,
    cookers,
    security,
    generator,
    dstv,
    fence,
    frontview,
  ]);

  useEffect(() => {
    const locationState = location.state;
    const storedListingData = SERVICES.getListingData();

    const ownerIdFromLocationState = locationState ? locationState.ownerId : 0;
    const ownerIdFromStorage = storedListingData
      ? storedListingData.ownerId
      : 0;

    if (
      storedListingData &&
      ownerIdFromLocationState &&
      ownerIdFromStorage &&
      ownerIdFromLocationState === ownerIdFromStorage
    ) {
      logger.log("stored listing data are:", storedListingData);
      const descriptionObj = storedListingData.listingDescription;
      const propertyTitle = descriptionObj.propertyTitle;

      const description = descriptionObj.description;
      const tags = descriptionObj.tags;

      setpropertyTitle(propertyTitle);
      setdescription(description);

      const priceObj = storedListingData.listingPrice;
      const price = priceObj.price;
      const billingPeriod = priceObj.billingPeriod;

      setprice(price);
      setdropdown2(billingPeriod);

      const detailsObj = storedListingData.listingDetails;
      const propertySize = detailsObj.propertySize;
      const floorNumber = detailsObj.floorNumber;
      const capacity = detailsObj.capacity;
      const listingType = detailsObj.listingType;

      setpropertySize(propertySize);
      setfloornumber(floorNumber);
      setcapacity(capacity);
      setListingType(listingType);

      setState({
        tags,
      });

      const media = storedListingData.listingMedia;
      const mediaImages = media ? media.images : [];
      const imageTitle = media?.imageTitle;
      const imageDesc = media?.imageDescription;

      setImgTitle(imageTitle);
      setImgDescription(imageTitle);

      logger.log("images from storage:", mediaImages);

      const buffer = [];
      Promise.all(
        Array.from(mediaImages).map((image) => {
          return new Promise((resolve, reject) => {
            const url = image.blob;
            const id = image.id;
            logger.log("image url:", url);
            logger.log("image id:", id);

            dataUrlToFile(url, id)
              .then((file) => {
                buffer.push({ ...image, file });

                logger.log(buffer);

                resolve({});
              })
              .catch(reject);
          });
        }),
      )
        .then((array) => {
          logger.log("array here:", array);
        })
        .finally(() => {
          logger.log("buffer in finally:", buffer);

          setImages(buffer);
        });

      // setPreviewFilesArray([...images]);
      const mediaIdFromStorage = media?.mediaId;
      setMediaId(mediaIdFromStorage);

      const uploadOption = media?.videoOption;
      const uploadFrom = uploadOption?.uploadFrom;
      const videoLink = uploadOption?.videoLink;

      const geolocation = storedListingData.geolocation;
      const address = geolocation?.address;
      const city = geolocation?.city;
      const country = geolocation?.country;
      const latitude = geolocation?.latitude;
      const longitude = geolocation?.longitude;
      const region = geolocation?.region;
      const zone = geolocation?.zone;

      setaddress(address);
      setcity(city);
      setCountry(country);
      setlatitude(latitude);
      setlongitude(longitude);
      setRegion(region);
      setZone(zone);

      const selectedAmenities = storedListingData.selectedAmenities;
      const access = selectedAmenities ? selectedAmenities.access : false;
      const disableFriendly = selectedAmenities
        ? selectedAmenities.disableFriendly
        : false;
      const loungeSpace = selectedAmenities
        ? selectedAmenities.loungeSpace
        : false;
      const kitchenFacilities = selectedAmenities
        ? selectedAmenities.kitchenFacilities
        : false;
      const mothersRoom = selectedAmenities
        ? selectedAmenities.mothersRoom
        : false;
      const onsiteDining = selectedAmenities
        ? selectedAmenities.onsiteDining
        : false;
      const onSiteParking = selectedAmenities
        ? selectedAmenities.onSiteParking
        : false;
      const outdoorspace = selectedAmenities
        ? selectedAmenities.outdoorspace
        : false;
      const printingServices = selectedAmenities
        ? selectedAmenities.printingServices
        : false;
      const vcFacilities = selectedAmenities
        ? selectedAmenities.vcFacilities
        : false;
      const whiteboard = selectedAmenities
        ? selectedAmenities.whiteboard
        : false;
      const bicycleRacks = selectedAmenities
        ? selectedAmenities.bicycleRacks
        : false;
      const storage = selectedAmenities ? selectedAmenities.storage : false;
      const cancelation = selectedAmenities
        ? selectedAmenities.cancelation
        : false;
      const pool = selectedAmenities ? selectedAmenities.pool : false;
      const airportshuttle = selectedAmenities
        ? selectedAmenities.airportshuttle
        : false;
      const spa = selectedAmenities ? selectedAmenities.spa : false;
      const nonsmokingrooms = selectedAmenities
        ? selectedAmenities.nonsmokingrooms
        : false;
      const restaurant = selectedAmenities
        ? selectedAmenities.restaurant
        : false;
      const freewifi = selectedAmenities ? selectedAmenities.freewifi : false;
      const coffeemaker = selectedAmenities
        ? selectedAmenities.coffeemaker
        : false;
      const bar = selectedAmenities ? selectedAmenities.bar : false;
      const lifts = selectedAmenities ? selectedAmenities.lifts : false;
      const food = selectedAmenities ? selectedAmenities.food : false;
      const parking = selectedAmenities ? selectedAmenities.parking : false;
      const rooms = selectedAmenities ? selectedAmenities.rooms : false;
      const washingmachine = selectedAmenities
        ? selectedAmenities.washingmachine
        : false;
      const gym = selectedAmenities ? selectedAmenities.gym : false;
      const garbage = selectedAmenities ? selectedAmenities.garbage : false;
      const laundry = selectedAmenities ? selectedAmenities.laundry : false;
      const housekeeping = selectedAmenities
        ? selectedAmenities.housekeeping
        : false;
      const cctv = selectedAmenities ? selectedAmenities.cctv : false;
      const hotwater = selectedAmenities ? selectedAmenities.hotwater : false;
      const bills = selectedAmenities ? selectedAmenities.bills : false;
      const cookers = selectedAmenities ? selectedAmenities.cookers : false;
      const security = selectedAmenities ? selectedAmenities.security : false;
      const generator = selectedAmenities ? selectedAmenities.generator : false;
      const dstv = selectedAmenities ? selectedAmenities.dstv : false;
      const fence = selectedAmenities ? selectedAmenities.fence : false;
      const frontview = selectedAmenities ? selectedAmenities.frontview : false;

      setfrontview(frontview);
      setfence(fence);
      setdstv(dstv);
      setgenerator(generator);
      setsecurity(security);
      setcookers(cookers);
      setbills(bills);
      sethotwater(hotwater);
      setcctv(cctv);
      sethousekeeping(housekeeping);
      setlaundry(laundry);
      setgarbage(garbage);
      setgym(gym);
      setwashingmachine(washingmachine);
      setrestaurant(restaurant);
      setrooms(rooms);
      setparking(parking);
      setfood(food);
      setlifts(lifts);
      setbar(bar);
      setcoffeemaker(coffeemaker);
      setfreewifi(freewifi);
      setnonsmokingrooms(nonsmokingrooms);
      setspa(spa);
      setairportshuttle(airportshuttle);
      setpool(pool);
      setcancelation(cancelation);
      setAccess(access);
      setdisableFriendly(disableFriendly);
      setloungeSpace(loungeSpace);
      setkitchenFacilities(kitchenFacilities);
      setmothersRoom(mothersRoom);
      setonsiteDining(onsiteDining);
      setonSiteParking(onSiteParking);
      setoutdoorspace(outdoorspace);
      setprintingServices(printingServices);
      setvcFacilities(vcFacilities);
      setwhiteboard(whiteboard);
      setbicycleRacks(bicycleRacks);
      setstorage(storage);
    }
  }, []);

  useEffect(() => {
    // setPreviewFilesArray([...images]);
    setPreviewFilesArray(images);
  }, [images]);

  useEffect(() => {
    const data = {
      ownerId: currentUserId,
      listingDescription: {
        propertyTitle,
        description,
        tags,
      },
      listingPrice: {
        price,
        billingPeriod: dropdown2,
      },
      listingDetails: {
        propertySize,
        floorNumber: floornumber,
        capacity,
        listingType,
      },
      listingMedia: {
        mediaId,
        images,
        imageTitle: imgtitle,
        imageDescription: imgDescription,
      },
      geolocation: {
        address,
        city,
        region,
        zone,
        country,
        latitude,
        longitude,
      },
      selectedAmenities,
    };

    SERVICES.saveListingData(data);
  }, [
    propertyTitle,
    description,
    price,
    dropdown2,
    propertySize,
    floornumber,
    capacity,
    listingType,
    tags,
    suggestions,
    mediaId,
    images,
    imgtitle,
    imgDescription,
    address,
    city,
    region,
    zone,
    country,
    latitude,
    longitude,
    selectedAmenities,
  ]);

  useEffect(() => {
    logger.log("media id update. new media id:", mediaId);
  }, [mediaId]);

  const onChangePlace = (target) => {
    if (!target || !target.files) return;

    setChosenImage(true);
    setUploadErrorModal(false);
    setUploadErrorMsg("");

    const maxFileSizeLimit = Constants.maxFileSizeLimit;
    const allValidImages = [...target.files].filter(
      (file) => file.size < Number(maxFileSizeLimit),
    );

    if (allValidImages.length === 0) {
      setUploadErrorModal(true);
      setUploadErrorMsg(
        "All selected images exceed the maximum file size limit",
      );
      return;
    }

    setMediaLoadingState(true);

    Promise.all(
      allValidImages.map((file) => {
        return new Promise((resolve, reject) => {
          fileToDataUri(file)
            .then((dataUri) => {
              const fileName = file ? file.name : "";
              const updatedFile = {
                clientId: uuidv4(),
                alt: fileName,
                blob: dataUri,
                file: file,
                width: 1,
                height: 1,
                src: dataUri,
                removeImage,
              };
              resolve(updatedFile);
            })
            .catch(reject);
        });
      }),
    )
      .then((array) => {
        setPreviewFilesArray([...previewFilesArray, ...array]);
        setMediaLoadingState(false);
      })
      .catch(() => {
        setMediaLoadingState(false);
        setUploadErrorModal(true);
        setUploadErrorMsg("Error processing images");
      });
  };
  useEffect(() => {
    logger.log("updated preview files array:", previewFilesArray);
    setSortableImages(previewFilesArray);
  }, [previewFilesArray]);

  const [uploadErrorModal, setUploadErrorModal] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState("");

  const [mediaupload, setmediaupload] = useState(true);
  const uploadError = () => {
    // setUploadErrorModal(true)
    // setmediaupload(false)
  };

  const submitMedia = async () => {
    try {
      setChosenImage(false);

      const formData = new FormData();

      formData.append("title", imgtitle);
      formData.append("description", imgDescription);

      for (let i = 0; i < previewFilesArray.length; i++) {
        formData.append("files", previewFilesArray[i].file);
      }

      // Always append empty video ID and YOUTUBE as upload source
      formData.append("embedVideoId", "");
      formData.append("uploadFrom", "YOUTUBE");

      setMediaLoadingState(true);

      const userId = SERVICES.getUser().id;

      const response = await (mediaId
        ? apiService.updateListingMediaWithFormData(mediaId, formData, userId)
        : apiService.createListingMediaWithFormData(formData, userId));

      const newMediaId = response.data.data[0].id;

      // Set the mediaId and wait for it to be updated
      await new Promise((resolve) => {
        setMediaId(newMediaId);
        setTimeout(resolve, 100);
      });

      setisMediaUploaded(true);
      setMediaLoadingState(false);
      return true;
    } catch (error) {
      setMediaLoadingState(false);
      setUploadErrorModal(true);
      setUploadErrorMsg(
        error.response?.data?.message || "Error uploading media",
      );
      logger.error(error);
      return false;
    }
  };

  // const [dropdown1, setdropdown1] = useState("initialState")
  // const [dropdown2, setdropdown2] = useState("initialState")

  useEffect(() => {
    return () => {
      // Cleanup function to reset states
      setloadingState(false);
      setMediaLoadingState(false);
      setErrorAlert(false);
      setUploadErrorModal(false);
      setisMediaUploaded(false);
    };
  }, []);

  const [form, setform] = useState(0);

  const [img1, setimg1] = useState(imgplaceholder);
  const [img2, setimg2] = useState(imgplaceholder);
  const [img3, setimg3] = useState(imgplaceholder);
  const [img4, setimg4] = useState(imgplaceholder);
  const [img5, setimg5] = useState(imgplaceholder);

  const addFileHandler = (e) => {
    var rows = [];
    logger.log(e.target.files.length);
    const filesLength = e.target.files.length;
    for (var i = 0; i < filesLength; i++) {
      rows.push(e.target.files[i]);
      // const blahsrc = URL.createObjectURL(e.target.files)
      var binaryData = [];
      // const blahsrc = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
      const blahsrc = window.URL.createObjectURL(
        new Blob(binaryData, { type: "application/zip" }),
      );

      if ((i = 1)) {
        logger.log(e.target.files[0].src);
        binaryData.push(e.target.files[0].src);
        setimg1(blahsrc);
      } else if ((i = 2)) setimg2(e.target.src);
      else if ((i = 3)) setimg2(e.target.src);
    }
    logger.log(rows);
    // setfileimage(rows)
  };

  const shapes = [];

  // const clickkk = {
  // 	docume
  // }

  const [enumCountry, setEnumCountry] = useState("N/A");

  let history = useHistory();

  const eCountry = (country) => {
    if (country === "Kenya") {
      return "ke";
    } else if (country === "Nigeria") {
      return "ng";
    } else if (country === "Ghana") {
      return "gh";
    }
  };

  const eTypeOfSpace = (space) => {
    if (space === "WORK") {
      logger.log("Work -> WORK");
      return "OFFICE_SPACE";
    } else if (space === "Concierge") {
      logger.log("Concierge -> SERVICE");
      return "SERVICE";
    } else if (space === "Stay") {
      logger.log("Stay -> RESIDENTIAL_SPACE");
      return "RESIDENTIAL_SPACE";
    } else if (space === "STAY") {
      logger.log("STAY -> RESIDENTIAL_SPACE");
      return "RESIDENTIAL_SPACE";
    }
  };

  const eAddSpec = (space) => {
    if (space === "Junior Suite") {
      return "JUNIOR_SUITE";
    }
    if (space === "Standard") {
      return "STANDARD";
    }
    if (space === "Executive Suite") {
      return "EXECUTIVE_SUITE";
    }
    if (space === "1 Bedroom") {
      return "ONE_BEDROOM";
    }
    if (space === "2 Bedroom") {
      return "TWO_BEDROOM";
    }
    if (space === "3 Bedroom") {
      return "THREE_BEDROOM";
    }
    if (space === "Presidential Suite") {
      return "PRESIDENTIAL_SUITE";
    } else {
      return space.toUpperCase();
    }
  };

  const eWorkspace = (space) => {
    if (space === "Private Office") {
      return "PRIVATE_OFFICE";
    }
    if (space === "Co-working Space") {
      return "HOT_DESK";
    }
    if (space === "Serviced Apartments") {
      return "SERVICED_APARTMENTS";
    }
    if (space === "Hotel") {
      return "HOTEL";
    }
    if (space === "Unfurnished") {
      return "UNFURNISHED";
    }
    if (space === "Meeting Room") {
      return "MEETING_AND_VENUE";
    }
    if (space === "Complimentary Meeting Room") {
      return "COMPLIMENTARY_MEETING_ROOM";
    }
    if (space === "Dedicated Desk") {
      return "DEDICATED_DESK";
    }
    if (space === "Bed & Breakfast") {
      return "BED_AND_BREAKFAST";
    }
    if (space === "Homestays") {
      return "HOMESTAYS";
    }
  };

  const [amenities, setamenities] = useState([]);

  useEffect(() => {
    logger.log("is co working space checked?", isCoWorkingSpaceChecked);
  }, [isCoWorkingSpaceChecked]);

  // }
  let sub = 0;
  // const [sub, setsub] = useState(0)
  const submitForm = async () => {
    try {
      // Reset states
      setErrorAlert(false);
      setErrorMsg("");
      setUploadErrorModal(false);
      setUploadErrorMsg("");
      setloadingState(true);

      // Validate property size
      const propertySizeInput = document.getElementById('propertySize');
      if (propertySize && parseInt(propertySize) < 1) {
        propertySizeInput.reportValidity();
        setKey("Description");
        setloadingState(false);
        return;
      }

      // Validate capacity
      const capacityInput = document.getElementById('capacity');
      if (capacity && parseInt(capacity) < 1) {
        capacityInput.reportValidity();
        setKey("Description");
        setloadingState(false);
        return;
      }

      // Validate co-working space slots if checked
      if (isCoWorkingSpaceChecked) {
        const slotsInput = document.getElementById('availableSlots');
        if (!slotsInput.checkValidity()) {
          slotsInput.reportValidity();
          setKey("Description");
          setloadingState(false);
          return;
        }
      }

      // Validate image title
      if (!imgtitle) {
        setimageTitleError("Please add an image title");
        setKey("Media");
        setloadingState(false);
        return;
      }

      // Validate files
      if (previewFilesArray.length === 0) {
        setUploadErrorModal(true);
        setUploadErrorMsg("Please select at least one image");
        setKey("Media");
        setloadingState(false);
        return;
      }

      const formData = new FormData();

      formData.append("title", imgtitle);
      formData.append("description", imgDescription);

      for (let i = 0; i < previewFilesArray.length; i++) {
        formData.append("files", previewFilesArray[i].file);
      }

      // Always append empty video ID and YOUTUBE as upload source
      formData.append("embedVideoId", "");
      formData.append("uploadFrom", "YOUTUBE");

      setMediaLoadingState(true);
      const userId = SERVICES.getUser().id;

      const response = await (mediaId
        ? apiService.updateListingMediaWithFormData(mediaId, formData, userId)
        : apiService.createListingMediaWithFormData(formData, userId));

      const newMediaId = response.data.data[0].id;

      // Set the mediaId and wait for it to be updated
      await new Promise((resolve) => {
        setMediaId(newMediaId);
        setTimeout(resolve, 100);
      });

      setisMediaUploaded(true);
      setMediaLoadingState(false);

      // Now we have the media ID, proceed with form submission
      findAmenities();
      await submitFormWithMedia(newMediaId);
    } catch (error) {
      setloadingState(false);
      setErrorAlert(true);
      setRequestHasErrors(true);
      setErrorMsg(error.message || "An error occurred during submission");
    }
  };

  const submitFormWithMedia = async (confirmedMediaId) => {
    const currentUser = SERVICES.getUser();

    try {
      const response = await apiService.createListing({
        additionalSpec: eAddSpec(localStorage.getItem("addSpec")),
        description: description,
        mediaId: confirmedMediaId,
        details: {
          capacity: capacity,
          floorNumber: floornumber,
          propertySize: propertySize,
          maxCapacity:
            !isCoWorkingSpaceChecked || coWorkingSpaceNumber < 2
              ? 1
              : coWorkingSpaceNumber,
        },
        zone: zone && zone.length ? zone : null,
        location: {
          address: address,
          city: city,
          country: eCountry(country),
          latitude: latitude,
          longitude: longitude,
          streetName: streetname,
        },
        propertyPrice: {
          billingPeriod: dropdown2,
          // "createdOn": "2021-07-07T13:27:40.853Z",
          price: price,
          // "updatedOn": "2021-07-07T13:27:40.854Z"
        },
        propertyStatus: "FOR_RENT",
        listingType: listingType.toLowerCase(),
        propertyTitle: propertyTitle,
        propertyType: eTypeOfSpace(localStorage.getItem("propertyType")),
        tags: state.tags,
        typeOfWorkspace: eWorkspace(localStorage.getItem("Workspace")),
        userId: currentUser.id,
        amenities:
          localStorage.getItem("lsstayamenities") == ""
            ? []
            : localStorage.getItem("lsstayamenities").split(","),
        media: {
          images,
          imageTitle: imgtitle,
          imageDescription: imgDescription,
          videoOption: {
            uploadFrom: "YOUTUBE",
            videoLink: "",
          },
        },
      });

      // Handle success
      const data = response.data.data;
      const addedListing = data ? data[0] : null;
      const tagsFromResponse = addedListing ? addedListing.tags : [];

      // Update keywords meta
      updateKeywordsMeta(tagsFromResponse);

      // Clear stored data if needed
      clearStoredDataIfNeeded();

      // Redirect to properties page
      SERVICES.setnewProperty(true);
      history.push(`/properties`, { from: "successful" });
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to submit form");
    }
  };

  // Helper function to update keywords meta
  const updateKeywordsMeta = (tagsFromResponse) => {
    const keywordsMeta = document.querySelector(`meta[name='keywords']`);
    if (keywordsMeta) {
      const content = keywordsMeta.content;
      const tagTexts = tagsFromResponse
        ? tagsFromResponse.map((tag) => tag.text)
        : "";
      const existingKeywords = content ? content.split(",") : "";
      const tags = new Set([...existingKeywords, ...tagTexts]);
      keywordsMeta.content = Array.from(tags).join(",");
    }
  };

  // Helper function to clear stored data
  const clearStoredDataIfNeeded = () => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser ? currentUser.id : 0;
    const storedListingData = SERVICES.getListingData();
    const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

    if (userId && listingOwnerId && userId === listingOwnerId) {
      SERVICES.clearListingData();
    }
  };

  // AXIOS POST REQUEST FOR FORM

  // arrayOfAmenities.forEach(function (value, i) {
  // 	logger.log('%d: %s', i, value);
  // });

  const stayamenities = [];

  const amenities2 = [];

  const findAmenities = () => {
    if (bicycleRacks) {
      stayamenities.push("BicycleRacks");
      logger.log(amenities2);
    }
    //if (onSiteParking) {
    //  stayamenities.push("OnsiteParking");
    //  logger.log(stayamenities);
    //}
    if (vcFacilities) {
      stayamenities.push("VCFacilities");
      logger.log(amenities2);
    }
    if (access) {
      stayamenities.push("24HrAccess");
      logger.log(stayamenities);
    }
    if (gym) {
      // stayamenities.push("Gym");
      stayamenities.push("Gym");
      logger.log(amenities2);
      logger.log(stayamenities);
    }
    //if (lifts) {
    //  stayamenities.push("Lifts");
    //  logger.log(amenities2);
    //  logger.log(stayamenities);
    //}
    if (loungeSpace) {
      stayamenities.push("LoungeSpace");
      logger.log(amenities2);
    }
    if (mothersRoom) {
      stayamenities.push("MothersRoom");
      logger.log(amenities2);
    }
    if (outdoorspace) {
      stayamenities.push("OutdoorSpace");
      logger.log(amenities2);
    }
    if (whiteboard) {
      stayamenities.push("Whiteboard");
      logger.log(amenities2);
    }
    if (kitchenFacilities) {
      stayamenities.push("KitchenFacilities");
      logger.log(amenities2);
    }
    if (onsiteDining) {
      stayamenities.push("OnsiteDining");
      logger.log(amenities2);
    }
    if (printingServices) {
      stayamenities.push("PrintingServices");
      logger.log(amenities2);
    }
    if (storage) {
      stayamenities.push("Storage");
      logger.log(amenities2);
    }
    if (disableFriendly) {
      stayamenities.push("DisabledFriendly");
      logger.log(amenities2);
    }
    if (onSiteParking) {
      stayamenities.push("OnsiteParking");
      logger.log(amenities2);
    }
    if (cancelation) {
      stayamenities.push("CancelationFlexibility");
      logger.log(stayamenities);
    }
    if (lifts) {
      stayamenities.push("Lifts");
      logger.log(stayamenities);
    }
    if (pool) {
      stayamenities.push("SwimmingPools");
      logger.log(stayamenities);
    }
    if (airportshuttle) {
      stayamenities.push("AirportShuttle");
      logger.log(stayamenities);
    }
    if (spa) {
      stayamenities.push("Spa");
      logger.log(stayamenities);
    }
    if (nonsmokingrooms) {
      stayamenities.push("NonSmokingRooms");
      logger.log(stayamenities);
    }
    if (frontview) {
      stayamenities.push("CityView");
      logger.log(stayamenities);
    }
    if (restaurant) {
      stayamenities.push("Restaurant");
      logger.log(stayamenities);
    }
    if (freewifi) {
      stayamenities.push("FreeWiFi");
      logger.log(stayamenities);
    }
    if (rooms) {
      stayamenities.push("MeetingRooms");
      logger.log(stayamenities);
    }
    if (coffeemaker) {
      stayamenities.push("CoffeeMakerInRoom");
      logger.log(stayamenities);
    }
    if (bar) {
      stayamenities.push("Bar");
      logger.log(stayamenities);
    }
    if (garbage) {
      stayamenities.push("GarbageCollection");
      logger.log(stayamenities);
    }
    if (laundry) {
      stayamenities.push("LaundryServices");
      logger.log(stayamenities);
    }
    if (housekeeping) {
      stayamenities.push("Housekeeping");
      logger.log(stayamenities);
    }
    if (cctv) {
      stayamenities.push("CCTV");
      logger.log(stayamenities);
    }
    if (parking) {
      stayamenities.push("AmpleParking");
      logger.log(stayamenities);
    }
    if (hotwater) {
      stayamenities.push("HotWater");
      logger.log(stayamenities);
    }
    if (bills) {
      stayamenities.push("WaterAndElectricBills");
      logger.log(stayamenities);
    }
    if (cookers) {
      stayamenities.push("Cookers");
      logger.log(stayamenities);
    }
    if (security) {
      stayamenities.push("24HourSecurity");
      logger.log(stayamenities);
    }
    if (generator) {
      stayamenities.push("StandbyBackupGenerator");
      logger.log(stayamenities);
    }
    if (dstv) {
      stayamenities.push("TV");
      logger.log(stayamenities);
    }
    if (fence) {
      stayamenities.push("ElectricFence");
      logger.log(stayamenities);
    }
    if (food) {
      stayamenities.push("BreakfastLunchDinner");
      logger.log(stayamenities);
    }
    if (washingmachine) {
      stayamenities.push("WashingMachine");
      logger.log(stayamenities);
    } else {
      logger.log("No Amenities Found");
    }

    logger.log("amenities2");
    logger.log(amenities2);
    logger.log("stayamenities");
    logger.log(stayamenities);
    localStorage.setItem("lsstayamenities", stayamenities);
    localStorage.getItem("propertyType") === "WORK"
      ? setamenitiesArray(amenities2)
      : setamenitiesArray(stayamenities);

    // setamenitiesArray(amenities2);
    // setamenitiesArray([...amenitiesArray], stayamenities)
    logger.log(amenitiesArray);
    logger.log("print stay amenities");
    return stayamenities;
  };

  const [fileimage, setfileimage] = useState(null);

  // /api/v1/listings/media

  const [amenitiesArray, setamenitiesArray] = useState([]);

  // const localurl = `${Constants.BASE_URL}/booking/api/v1/listings`;
  const submit = () => {
    const currentUser = SERVICES.getUser();

    if (!mediaId) {
      setKey("Media");
      throw new Error("No media ID available");
    }
    // const userId = currentUser.id;
    logger.log(amenitiesArray);
    logger.log(localStorage.getItem("Workspace"));

    apiService.createListing({
      additionalSpec: eAddSpec(localStorage.getItem("addSpec")),
      description: description,
      mediaId: mediaId,
      details: {
        capacity: capacity,
        floorNumber: floornumber,
        propertySize: propertySize,
        maxCapacity:
          !isCoWorkingSpaceChecked || coWorkingSpaceNumber < 2
            ? 1
            : coWorkingSpaceNumber,
      },
      zone: zone && zone.length ? zone : null,
      location: {
        address: address,
        city: city,
        // Fix the drop down
        country: eCountry(country),

        latitude: latitude,
        longitude: longitude,
        streetName: streetname,
      },
      propertyPrice: {
        billingPeriod: dropdown2,
        // "createdOn": "2021-07-07T13:27:40.853Z",
        price: price,
        // "updatedOn": "2021-07-07T13:27:40.854Z"
      },
      propertyStatus: "FOR_RENT",
      listingType: listingType.toLowerCase(),
      propertyTitle: propertyTitle,
      //   work stay conc
      propertyType: eTypeOfSpace(localStorage.getItem("propertyType")),
      // propertyType: eTypeOfSpace(tOfSpace),
      // Write a loop to pick the items
      // tags: state.tags,
      // TODO
      tags: state.tags,
      typeOfWorkspace: eWorkspace(localStorage.getItem("Workspace")),
      // THIS IS ACC PROPERTY TYPE
      // typeOfWorkspace: localStorage.getItem("Workspace"),
      userId: currentUser.id,
      amenities:
        localStorage.getItem("lsstayamenities") == ""
          ? []
          : localStorage.getItem("lsstayamenities").split(","),
    })
      .then((res) => {
        logger.log("done adding new property. res.data:", res.data.data);

        const data = res.data.data;
        const addedListing = data ? data[0] : null;
        const tagsFromResponse = addedListing ? addedListing.tags : [];

        // get 'keywords' meta element
        const keywordsMeta = document.querySelector(`meta[name='keywords']`);
        if (keywordsMeta) {
          const content = keywordsMeta.content;

          // get all tag texts
          const tagTexts = tagsFromResponse
            ? tagsFromResponse.map((tag) => tag.text)
            : "";
          logger.log("tags from response:", tagTexts);

          // convert keyword content to a list of string
          const existingKeywords = content ? content.split(",") : "";

          // instantiate a set that will contain all tag keywords
          const tags = new Set();

          // add all existing keywords to set
          if (existingKeywords.length > 0) {
            existingKeywords.forEach((item) => tags.add(item));
          }

          // now add all tags from server to the set. since Sets pick
          // unique items, all duplicates are seen as one
          tagTexts.forEach((item) => tags.add(item));
        }

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const storedListingData = SERVICES.getListingData();
        const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

        if (userId && listingOwnerId && userId === listingOwnerId) {
          SERVICES.clearListingData();
        }

        // Set new property flag and redirect with fresh state
        SERVICES.setnewProperty(true);
        history.push("/properties", {
          from: "successful",
          timestamp: new Date().getTime(), // Add timestamp to ensure state is fresh
        });
      })
      .catch((err) => {
        setloadingState(false);
        setErrorAlert(true);
        setRequestHasErrors(true);

        const errorResponse = err.response;
        logger.log("checking error response:", errorResponse);

        const errorData = errorResponse?.data;
        const errorMsg = errorData?.message;

        setErrorMsg(errorMsg);
      });
  };

  const uploadimage = (fileimage) => {
    const currentUser = SERVICES.getUser();
    const userId = currentUser.id;

    logger.log(fileimage);
    apiService.createListingMedia({
      description: "First Test Item",
      files: fileimage,
      title: fileimage.name,
      userId,
    });
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects

    const Geocoder = new maps.Geocoder();

    maps.Polygon.prototype.Contains = function (point) {
      var crossings = 0,
        path = this.getPath();

      if (!path) {
        return false;
      }

      // for each edge
      for (var i = 0; i < path.getLength(); i++) {
        var a = path.getAt(i),
          j = i + 1;
        if (j >= path.getLength()) {
          j = 0;
        }
        var b = path.getAt(j);
        if (rayCrossesSegment(point, a, b)) {
          crossings++;
        }
      }

      // odd number of crossings?
      return crossings % 2 == 1;

      function rayCrossesSegment(point, a, b) {
        var px = point.lng(),
          py = point.lat(),
          ax = a.lng(),
          ay = a.lat(),
          bx = b.lng(),
          by = b.lat();
        if (ay > by) {
          ax = b.lng();
          ay = b.lat();
          bx = a.lng();
          by = a.lat();
        }
        // alter longitude to cater for 180 degree crossings
        if (px < 0) {
          px += 360;
        }
        if (ax < 0) {
          ax += 360;
        }
        if (bx < 0) {
          bx += 360;
        }

        if (py == ay || py == by) py += 0.00000001;
        if (px > Math.max(ax, bx)) return false;
        if (px < Math.min(ax, bx)) return true;

        var red = ax != bx ? (by - ay) / (bx - ax) : Infinity;
        var blue = ax != px ? (py - ay) / (px - ax) : Infinity;
        return blue >= red;
      }
    };

    let marker = new maps.Marker({
      position: defaultCenter,
      map,
      title: "Location",
    });

    const placeMarker = (e) => {
      marker.setMap(null);

      const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      Geocoder.geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            const result = response.results[0];
            setCountry(
              result.address_components.find(
                (o) => o.types.indexOf("country") > -1,
              )?.long_name,
            );
            setcity(
              result.address_components.find(
                (o) => o.types.indexOf("locality") > -1,
              )?.long_name || "",
            );
            setRegion(
              result.address_components.find(
                (o) => o.types.indexOf("administrative_area_level_1") > -1,
              )?.long_name,
            );
            setaddress(
              `${e.placeName ? e.placeName + ", " : ""}${result.formatted_address} ${result.address_components.find(
                (o) => o.types.indexOf("sublocality") > -1,
              )?.long_name || ""
              }`,
            );
          }
        })
        .catch(console.error);

      setlatitude(latLng.lat);
      setlongitude(latLng.lng);

      marker = new maps.Marker({
        position: latLng,
        map,
        title: "Location",
      });
    };

    map.addListener("click", (e) => {
      setZone([]);
      placeMarker(e);
    });

    const input = document.getElementById("pac-input");

    const autocomplete = new maps.places.Autocomplete(input, {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    });

    autocomplete.addListener("place_changed", (e) => {
      const place = autocomplete.getPlace();

      if (place?.geometry?.location) {
        placeMarker({ placeName: place.name, latLng: place.geometry.location });
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        let found = false;
        shapes.some(({ zoneName, polygon }, i, arr) => {
          if (polygon.Contains(place.geometry.location)) {
            setZone(zoneName);
            found = true;
            return;
          } else if (i === arr.length - 1 && !found) {
            setZone(zoneName);
          }
        });
      }
    });

    const promises = [];
    for (let i = 1; i <= 12; ++i) {
      promises.push(
        new Promise((resolve, reject) => {
          fetch(`/zones/Spacia Zone ${i}.txt`).then(function (response) {
            let reader = response.body.getReader();
            let decoder = new TextDecoder("utf-8");

            reader.read().then(function (result) {
              let fillColor = "#F0ADA5";
              let zoneName = [];
              let strokeOpacity = 0.8;

              const latLngs = decoder
                .decode(result.value)
                .split("\n")
                .map((line, i) => {
                  if (i > 0) {
                    const parts = line.split("\t");
                    if (i === 1) {
                      fillColor = parts[3] || fillColor;
                      strokeOpacity = parts[4] || strokeOpacity;
                      zoneName = (parts[6] || zoneName)
                        .toUpperCase()
                        .replace(/\s+/g, "_");
                    }

                    if (parts[1] && parts[2]) {
                      return new maps.LatLng(
                        Number(parts[1]),
                        Number(parts[2]),
                      );
                    }
                  }
                  return null;
                });

              shapes.push({
                zoneName,
                polygon: new maps.Polygon({
                  paths: latLngs.filter((o) => !!o),
                  strokeColor: "#B4B2C0",
                  strokeOpacity,
                  strokeWeight: 2,
                  fillColor,
                  fillOpacity: 0.35,
                }),
              });

              resolve({});
            });
          });
        }),
      );
    }

    Promise.all(promises).then(() => {
      shapes.forEach(({ zoneName, polygon }, i) => {
        maps.event.addListener(polygon, "click", function (e) {
          marker.setMap(null);

          setZone(zoneName);

          placeMarker(e);
        });

        polygon.setMap(map);
      });
    });
  };

  // const onChildClick = (key, childProps) => {
  // 	logger.log(childProps);
  // 	// this.props.onCenterChange([childProps.lat, childProps.lng]);
  // }

  // {
  //   "additionalSpec": "HOT_HOUR",
  //   "description": "string",
  //   "details": {
  //     "capacity": 50,
  //     "floorNumber": "string",
  //     "propertySize": 2
  //   },
  //   "location": {
  //     "address": "50 3rd Road",
  //     "city": "Accra",
  //     "country": "gh",
  //     "latitude": "12345",
  //     "longitude": "-12345",
  //     "streetName": "50 3rd Road"
  //   },
  //   "propertyPrice": {
  //     "billingPeriod": "HOURLY",
  //     "createdOn": "2021-07-07T13:27:40.853Z",
  //     "price": 3000,
  //     "updatedOn": "2021-07-07T13:27:40.854Z"
  //   },
  //   "propertyStatus": "FOR_RENT",
  //   "propertyTitle": "Nice House In the Hamptoms",
  //   "propertyType": "OFFICE_SPACE",
  //   "tags": [
  //     "string"
  //   ],
  //   "typeOfWorkspace": "PRIVATE_OFFICE",
  //   "userId": 2
  // }

  const fit = {
    display: "flex",
    flexWrap: "wrap",
    padding: "20px 0",
  };

  const label = {
    fontWeight: "300",
    marginBottom: 10,
  };

  const imgholder = {
    width: 100,
    height: 100,
    objectFit: "cover",
    marginRight: 10,
    postion: "absolute",
  };

  const imgholderActive = {
    border: "2px solid red",
  };

  const [key, setKey] = useState("Description");

  const photos = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
      width: 1,
      height: 1,
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/PpOHJezOalU/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      width: 4,
      height: 3,
    },
  ];

  useEffect(() => {
    logger.log("sortable images updated", sortableImages);
  }, [sortableImages]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    logger.log("on sort end called.");

    logger.log("inside on sort end. preview files array:", previewFilesArray);
    setPreviewFilesArray(
      arrayMoveImmutable(sortableImages, oldIndex, newIndex),
    );
  };

  return (
    <div>
      <ErrorAlert
        message={
          errorMsg ||
          "An error occurred while adding listing. Please try again!"
        }
        show={errorAlert}
        close={() => setErrorAlert(false)}
      />
      <div style={{ display: "flex" }}>
        <div className="headerListPropBtn">
          <Header title="List a Property" />
        </div>
        {/* <div className='headerListPropBtn'>
              <button>List a Properties</button>
            </div> */}
      </div>

      <Container>
        <div className="tab-container">
          <Tabs
            // className="pt-3"

            fill
            id="controlled-tab-example"
            activeKey={key}
            // variant="pills"
            onSelect={(k) => {
              // chosenImage ? submitMedia() : logger.log("Image is not chosen");
              setKey(k);

              // isMediaUploaded ? setKey(k) : submitMedia()
              // I think this should be general
              // k == '3.Location' ? submitMedia() : logger.log("Not Yet!!")
            }}
          >
            <Tab eventKey="Description" title="Description">
              <br />
              <Container>
                <h5>
                  <b>Property Description</b>
                </h5>

                <div className="form-group">
                  <label style={label} htmlFor="">
                    Property Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    value={propertyTitle}
                    onChange={(e) => setpropertyTitle(e.target.value)}
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label style={label} for="">
                    Property Descriptions
                  </label>
                  <textarea
                    style={{ height: "180px" }}
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder=""
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </div>
                {/* <div className="form-group"> */}
                <label style={label} for="">
                  Add Tags
                </label>
                <br />
                <div style={{ background: "white", flexWrap: "wrap" }}>
                  {/* <div
										style={{
											margin: 0,
											background: "#DDDDDD",
										}}
									> */}
                  {/* <InputTags
                  style={{
                    backgroundColor: "white",
                    fontSize: 16,

                  }}
                  values={state}
                  onTags={(value) => setState(value.values)}
                /> */}
                  {/* <InputTag tags={state}></InputTag> */}
                  <ReactTags
                    tags={tags}
                    placeholder="Enter here"
                    suggestions={suggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    delimiters={delimiters}
                  />
                  {/* </div> */}
                </div>
                <small className="text-muted">
                  Press Enter after every entry
                </small>
                {/* </div> */}
              </Container>
              <Container>
                <br />

                <h5>
                  <b>Property Price</b>
                </h5>

                <div className="form-group">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <label style={label} for="">
                        Price in {userCurrency} {" (numbers only)"}
                      </label>
                      <input
                        type="number"
                        style={{ width: "80%" }}
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        value={price}
                        onChange={(e) => {
                          e.target.value > 0
                            ? setprice(e.target.value)
                            : setprice(0);
                        }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <label style={label} for="">
                        Billing Period
                      </label>
                      <div
                        className="selectCard"
                        onClick={() =>
                          billingPeriodDropdownElement.current.click()
                        }
                        style={{
                          cursor: "pointer",
                          width: "80%",
                          padding: "5px",
                        }}
                      >
                        {/* billingperiod = {this dropdown} */}
                        <h6 className="flatText" style={{ fontSize: "0.9rem" }}>
                          {dropdown2}
                        </h6>
                        <Dropdown>
                          <Dropdown.Toggle
                            ref={billingPeriodDropdownElement}
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "none",
                            }}
                            id="dropdown-basic"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setdropdown2("HOURLY")}
                              href="#/action-2"
                            >
                              Hourly
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setdropdown2("DAILY")}
                              href="#/action-3"
                            >
                              Daily
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setdropdown2("WEEKLY")}
                              href="#/action-3"
                            >
                              Weekly
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setdropdown2("MONTHLY")}
                              href="#/action-3"
                            >
                              Monthly
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
              <Container>
                <br />
                <h5>
                  <b>Details</b>
                </h5>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "50%" }} className="form-group">
                    <label style={label} for="">
                      Size in ft2
                    </label>
                    <input
                      type="number"
                      style={{ width: "80%" }}
                      className="form-control"
                      name="propertySize"
                      id="propertySize"
                      min={1}
                      required
                      aria-describedby="helpId"
                      placeholder=""
                      value={propertySize}
                      onChange={(e) => {
                        setpropertySize(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ width: "50%" }} className="form-group">
                    <label style={label} for="">
                      Floor Number
                    </label>
                    <input
                      type="number"
                      style={{ width: "80%" }}
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={floornumber}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setfloornumber(e.target.value)
                          : setfloornumber(0);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={"pt-4"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ width: "50%" }} className="form-group">
                    <label style={label} htmlFor="">
                      Capacity (number of people)
                    </label>
                    <input
                      type="number"
                      style={{ width: "80%" }}
                      className="form-control"
                      name="capacity"
                      id="capacity"
                      min={1}
                      required
                      aria-describedby="helpId"
                      placeholder=""
                      value={capacity}
                      onChange={(e) => {
                        setcapacity(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ width: "50%" }} className="form-group d-flex">
                    <div className={"me-2"} style={{ alignSelf: "flex-end" }}>
                      <input
                        type="checkbox"
                        checked={isCoWorkingSpaceChecked}
                        onChange={(e) =>
                          setIsCoWorkingSpaceChecked(e.target.checked)
                        }
                      />
                    </div>
                    <div className={"w-100"}>
                      <label style={label} htmlFor="">
                        Number of available slots (Select to turn on edit)
                      </label>
                      <input
                        type="number"
                        style={{ width: "80%" }}
                        className="form-control"
                        name="availableSlots"
                        id="availableSlots"
                        min={1}
                        required={isCoWorkingSpaceChecked}
                        aria-describedby="helpId"
                        placeholder=""
                        disabled={!isCoWorkingSpaceChecked}
                        value={coWorkingSpaceNumber}
                        onChange={(e) => {
                          setCoWorkingSpaceNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/*<div style={{width: "50%"}}>*/}
                  {/*  <br/>*/}
                  {/*  <label style={label} htmlFor="">*/}
                  {/*    Type*/}
                  {/*  </label>*/}
                  {/*  <div*/}
                  {/*      className="selectCard"*/}
                  {/*      onClick={() => listingTypeDropdownElement.current.click()}*/}
                  {/*      style={{*/}
                  {/*        cursor: 'pointer',*/}
                  {/*        width: "80%",*/}
                  {/*        padding: "5px",*/}
                  {/*        marginTop: 0*/}
                  {/*      }}*/}
                  {/*  >*/}
                  {/*     billingperiod = {this dropdown} */}{" "}
                  {/*todo: This part was already commented out*/}
                  {/*    <h6 className="flatText" style={{fontSize: '0.9rem'}}>{listingType}</h6>*/}
                  {/*    <Dropdown>*/}
                  {/*      <Dropdown.Toggle*/}
                  {/*        ref={listingTypeDropdownElement}*/}
                  {/*          style={{*/}
                  {/*            backgroundColor: "transparent",*/}
                  {/*            color: "black",*/}
                  {/*            border: "none",*/}
                  {/*          }}*/}
                  {/*          id="dropdown-basic"*/}
                  {/*      ></Dropdown.Toggle>*/}
                  {/**/}
                  {/*      <Dropdown.Menu>*/}
                  {/*        <Dropdown.Item*/}
                  {/*            onClick={() => setListingType("PRIVATE")}*/}
                  {/*        >*/}
                  {/*          Private*/}
                  {/*        </Dropdown.Item>*/}
                  {/*        <Dropdown.Item*/}
                  {/*            onClick={() => setListingType("PUBLISHED")}*/}
                  {/*        >*/}
                  {/*          Published*/}
                  {/*        </Dropdown.Item>*/}
                  {/*      </Dropdown.Menu>*/}
                  {/*    </Dropdown>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </Container>
              <Container>
                {/* <br />
							<h5>
								<b>Select Property Status</b>
							</h5>
							<div style={{ width: "50%" }}>
								<label style={label} for="">
									Property status
								</label>
								<div
									className="selectCard"
									style={{ width: "100%", padding: "5px" }}
								>

									<h6 className="flatText">{dropdown1}</h6>
									<Dropdown>
										<Dropdown.Toggle
											style={{
												backgroundColor: "transparent",
												color: "black",
												border: "none",
											}}
											id="dropdown-basic"
										></Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() =>
													setdropdown1("Published")
												}
												href="#/action-2"
											>
												Published
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setdropdown1(
														"Not Published",
													)
												}
												href="#/action-3"
											>
												Not Published
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div> */}

                <br />
                <div style={{ display: "flex" }}>
                  {/* <button className="button-calm">Back</button> */}
                  <button
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      const propertySizeInput = document.getElementById('propertySize');
                      const capacityInput = document.getElementById('capacity');
                      
                      if (propertySize && parseInt(propertySize) < 1) {
                        propertySizeInput.reportValidity();
                        return;
                      }
                      
                      if (capacity && parseInt(capacity) < 1) {
                        capacityInput.reportValidity();
                        return;
                      }

                      if (isCoWorkingSpaceChecked) {
                        const slotsInput = document.getElementById('availableSlots');
                        if (!slotsInput.checkValidity()) {
                          slotsInput.reportValidity();
                          return;
                        }
                      }
                      setKey("Media");
                    }}
                    className="button"
                  >
                    Next Step
                  </button>
                </div>
              </Container>
              {/* <button className="ListPropBtn">Next Step</button> */}
            </Tab>
            <Tab eventKey="Media" title="Media">
              {/* Upload Box */}

              <Container>
                <br />
                <ErrorAlert
                  show={uploadErrorModal}
                  close={() => setUploadErrorModal(false)}
                  message={
                    uploadErrorMsg ||
                    "There was a problem uploading your images, please check and try again."
                  }
                />

                <div className="uploadBox mb-3">
                  <div style={{ marginLeft: "45%" }}>
                    <img src={uploadButton} alt="upload" />
                    <br />
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      style={{ color: "#FFCBC6" }}
                      onChange={(event) =>
                        onChangePlace(event.currentTarget || null)
                      }
                      className="uploadButton"
                    />
                  </div>
                </div>

                {/* Show processing or uploading state */}
                {mediaLoadingState && (
                  <div className="text-center mb-3">
                    <Spinner animation="border" variant="primary" size="sm" />
                    <span className="ms-2">
                      Processing and uploading images...
                    </span>
                  </div>
                )}

                {/* Rest of the Media tab content */}
                <SortableGallery
                  items={sortableImages}
                  onSortEnd={onSortEnd}
                  axis={"xy"}
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label style={label} for="">
                        Image Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder=""
                        value={imgtitle}
                        onChange={(e) => setImgTitle(e.target.value)}
                      />
                      <small style={{ color: "red" }}>{imageTitleError}</small>
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <button
                    className="button-calm"
                    onClick={() => {
                      setKey("Description");
                    }}
                  >
                    Back
                  </button>
                  <button
                    style={{ marginLeft: 20 }}
                    // onClick={submitMedia}
                    //
                    onClick={() => {
                      setKey("Location");
                    }}
                    className="button"
                  >
                    Next Step
                  </button>
                </div>
              </Container>
            </Tab>

            <Tab eventKey="Location" title="Location">
              {/* <h6>
							<b>Property Listing</b>
						</h6> */}
              <br />

              <div className="form-group">
                <label style={label} for="">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  id="pac-input"
                  aria-describedby="helpId"
                  placeholder="Search by location name or address on Google maps"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  style={{ paddingLeft: "10px" }}
                />
              </div>

              <Row>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} for="">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} for="">
                      Region/Province
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>
              </Row>

              <Row>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} for="">
                      Zone
                    </label>
                    {/* <select className="form-control">
                    {shapes.map((shape, i) => (
                      <option key={i}>ZONE_{i}</option>
                    ))}
                  </select> */}
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="zoneId"
                      placeholder=""
                      onChange={(e) => setZone(e.target.value)}
                      value={zone}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div style={{ width: "100%" }}>
                      <label style={label} for="">
                        Country
                      </label>
                      <div
                        className="selectCard"
                        style={{ width: "100%", padding: "5px", marginTop: 0 }}
                      >
                        {/* Property Status picks from this dropdown */}
                        <h6 className="flatText">{country}</h6>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{
                              backgroundColor: "transparent",
                              color: "black",
                              border: "none",
                            }}
                            id="dropdown-basic"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setCountry("Ghana")}>
                              Ghana
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry("Kenya")}>
                              Kenya
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setCountry("Nigeria")}>
                              Nigeria
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <Row>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} for="">
                      Latitude
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={latitude}
                      onChange={(e) => setlatitude(e.target.value)}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label style={label} for="">
                      Longitude
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      value={longitude}
                      onChange={(e) => setlongitude(e.target.value)}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>
              </Row>

              <div className="mapouter">
                <div className="gmap_canvas">
                  <div style={{ height: "100vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: Constants.firebaseConfig.apiKey,
                        libraries: ["places"],
                      }}
                      defaultCenter={defaultCenter}
                      defaultZoom={13}
                      options={(maps) => ({
                        zoomControl: true,
                        zoomControlOptions: {
                          position: maps.ControlPosition.TOP_RIGHT,
                        },
                      })}
                      onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(map, maps)
                      }
                    ></GoogleMapReact>
                  </div>

                  {/* <iframe
									width="100%"
									height="300"
									title="Maps"
									id="gmap_canvas"
									src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
									frameborder="0"
									scrolling="no"
									marginheight="0"
									marginwidth="0"
								></iframe> */}
                  <br />
                  {/* <a href="https://www.embedgooglemap.net">
									embed responsive google map
								</a> */}
                </div>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <button
                  onClick={() => {
                    setKey("Media");
                  }}
                  className="button-calm"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setKey("Amenities");
                  }}
                  style={{ margin: "auto 20" }}
                  className="button"
                >
                  Next Step
                </button>
              </div>
            </Tab>
            <Tab eventKey="Amenities" title="Amenities">
              <div className="form-group">
                <br />
                <h6 style={{ marginBottom: "auto 20" }}>
                  <b>Other facilities available</b>
                </h6>

                {/* Undo From Here */}

                {localStorage.getItem("propertyType") === "WORK" ? (
                  <WorkAmenities
                    access={access}
                    changeAccess={(e) => {
                      setAccess(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    disableFriendly={disableFriendly}
                    changeDisableFriendly={(e) => {
                      setdisableFriendly(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    loungeSpace={loungeSpace}
                    changeLoungeSpace={(e) => {
                      setloungeSpace(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    kitchenFacilities={kitchenFacilities}
                    changeKitchenFacilities={(e) => {
                      setkitchenFacilities(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    mothersRoom={mothersRoom}
                    changeMothersRoom={(e) => {
                      setmothersRoom(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    onsiteDining={onsiteDining}
                    changeOnSiteDining={(e) => {
                      setonsiteDining(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    onSiteParking={onSiteParking}
                    changeOnSiteParking={(e) => {
                      setonSiteParking(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    outdoorspace={outdoorspace}
                    changeOutdooSpace={(e) => {
                      setoutdoorspace(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    printingServices={printingServices}
                    changePrintingServices={(e) => {
                      setprintingServices(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    vcFacilities={vcFacilities}
                    changeVcFacilities={(e) => {
                      setvcFacilities(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    whiteboard={whiteboard}
                    changeWhiteboard={(e) => {
                      setwhiteboard(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    bicycleRacks={bicycleRacks}
                    changeBicycleRacks={(e) => {
                      setbicycleRacks(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    storage={storage}
                    changeStorage={(e) => {
                      setstorage(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                  />
                ) : (
                  <StayAmenities
                    cancelation={cancelation}
                    changeCancelation={(e) => {
                      setcancelation(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    pool={pool}
                    changePool={(e) => {
                      setpool(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    airportshuttle={airportshuttle}
                    changeAirportShuttle={(e) => {
                      setairportshuttle(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    spa={spa}
                    changeSpa={(e) => {
                      setspa(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    nonsmokingrooms={nonsmokingrooms}
                    changeNonsmokingrooms={(e) => {
                      setnonsmokingrooms(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    restaurant={restaurant}
                    changeRestaurant={(e) => {
                      setrestaurant(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    freewifi={freewifi}
                    changeFreewifi={(e) => {
                      setfreewifi(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    coffeemaker={coffeemaker}
                    changeCoffeemaker={(e) => {
                      setcoffeemaker(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    bar={bar}
                    changeBar={(e) => {
                      setbar(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    lifts={lifts}
                    changeLifts={(e) => {
                      setlifts(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    food={food}
                    changeFood={(e) => {
                      setfood(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    parking={parking}
                    changeParking={(e) => {
                      setparking(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    rooms={rooms}
                    changeROoms={(e) => {
                      setrooms(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    washingmachine={washingmachine}
                    changeWashingmachine={(e) => {
                      setwashingmachine(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    gym={gym}
                    changeGym={(e) => {
                      setgym(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    garbage={garbage}
                    changeGarbage={(e) => {
                      setgarbage(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    laundry={laundry}
                    changeLaundry={(e) => {
                      setlaundry(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    housekeeping={housekeeping}
                    changeHousekeeping={(e) => {
                      sethousekeeping(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    cctv={cctv}
                    changeCctv={(e) => {
                      setcctv(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    hotwater={hotwater}
                    changeHotwater={(e) => {
                      sethotwater(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    bills={bills}
                    changeBills={(e) => {
                      setbills(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    cookers={cookers}
                    changeCookers={(e) => {
                      setcookers(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    security={security}
                    changeSecurity={(e) => {
                      setsecurity(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    generator={generator}
                    changeGenerator={(e) => {
                      setgenerator(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    dstv={dstv}
                    changeDstv={(e) => {
                      setdstv(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    fence={fence}
                    changeFence={(e) => {
                      setfence(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                    frontview={frontview}
                    changeFrontview={(e) => {
                      setfrontview(e.currentTarget.checked);
                      findAmenities();
                      logger.log(stayamenities);
                      logger.log(e.currentTarget.checked);
                    }}
                  />
                )}

                <br />
                <br />
                <br />
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: requestHasErrors ? "space-between" : "none",
                    width: requestHasErrors && "60%",
                  }}
                >
                  <button
                    className="button-calm"
                    onClick={() => {
                      setKey("Location");
                    }}
                  >
                    Back
                  </button>
                  {mediaLoadingState ? (
                    <button
                      style={{
                        backgroundColor: "#FE9C93",
                        marginLeft: 20,
                        width: "auto",
                      }}
                      onClick={submitForm}
                      className="button"
                      disabled={mediaLoadingState || loadingState}
                    >
                      <Spinner size="sm" animation="border" role="status">
                        <span className="visually-hidden"></span>
                      </Spinner>{" "}
                      Uploading Images
                    </button>
                  ) : (
                    <button
                      style={{ marginLeft: 20, width: 200 }}
                      onClick={submitForm}
                      className="button"
                      disabled={mediaLoadingState || loadingState}
                    >
                      {loadingState ? (
                        <Spinner size="sm" animation="border" role="status">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : (
                        `Submit Property`
                      )}
                    </button>
                  )}
                  <button
                    style={{
                      display: requestHasErrors ? "block" : "none",
                      marginLeft: "20px",
                    }}
                    className="button-calm"
                    onClick={moveToPropertiesPage}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default ListPropertyForm;
