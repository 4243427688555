import React, { useState, useEffect } from "react";
import {
    Container,
    Button,
    Spinner,
    Form,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";
import styled from 'styled-components';
import Pagination from "../../../../components/Pagination";
import AdminOrdersTableRow from "../../../../components/AdminOrdersTableRow";
import logger from "../../../../utils/logger";

const MobileOrderStyles = styled.div`
  background: #ffffff;
  min-height: 100vh;
  padding-bottom: 16px;

  .container {
    padding: 12px;
    max-width: 100%;
  }

  .header {
    background: #fff;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    margin-bottom: 16px;
  }

  .header h4 {
    color: #1a1a1a;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .search-container {
    position: relative;
  }

  .search-container .form-control {
    height: 44px;
    border-radius: 22px;
    padding: 0 48px 0 16px;
    border: 1px solid #e2e8f0;
    font-size: 0.95rem;
    width: 100%;
    
    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.15);
    }

    &::placeholder {
      color: #a0aec0;
    }
  }

  .search-button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4299e1;
    border: none;
    color: white;
    
    &:active {
      background: #3182ce;
      transform: scale(0.95);
    }
  }

  .orders-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .order-row {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    overflow: hidden;
    
    & > div {
      padding: 0;  // Reset AdminOrdersTableRow padding
    }

    .order-header {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;
    }

    .order-date {
      font-size: 0.9rem;
      color: #666;
    }

    .order-content {
      padding: 12px;
    }

    .order-customer {
      font-weight: 500;
      margin-bottom: 4px;
      color: #2d3748;
    }

    .order-company {
      font-size: 0.9rem;
      color: #718096;
      margin-bottom: 8px;
    }

    .order-amount {
      font-weight: 600;
      color: #2d3748;
      font-size: 1.1rem;
      margin-bottom: 12px;
    }

    .order-actions {
      display: flex;
      gap: 8px;
      
      button {
        flex: 1;
        padding: 8px;
        font-size: 0.9rem;
        border-radius: 8px;
        
        &:active {
          transform: scale(0.98);
        }
      }
    }
  }

  .status-badge {
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    
    &.paid {
      background-color: #c6f6d5;
      color: #22543d;
    }
    
    &.active {
      background-color: #bee3f8;
      color: #2c5282;
    }
    
    &.cancelled {
      background-color: #fed7d7;
      color: #822727;
    }
    
    &.incomplete {
      background-color: #feebc8;
      color: #744210;
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  }

  .spinner-border {
    color: #4299e1;
    width: 2rem;
    height: 2rem;
  }

  .empty-state {
    text-align: center;
    padding: 40px 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    
    p {
      font-size: 1rem;
      color: #718096;
      margin: 0;
    }
  }

  .pagination-container {
    margin-top: 16px;
    padding: 8px;
    display: flex;
    justify-content: center;

    .pagination-bar {
      background: #fff;
      padding: 4px;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    }
  }
`;

interface Order {
    createdOn: string;
    grossAmount: number;
    referenceCode: string;
    isPaid: boolean;
    isActive: boolean;
    isCancelled: boolean;
    createdById?: number;
    order?: {
        orderStatus: string;
        id: number;
    };
    createdBy?: {
        firstName?: string;
        lastName?: string;
        company?: {
            name: string;
        };
        role?: string;
    };
}

const MobileOrders: React.FC = () => {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [mobileWalletAccounts, setMobileWalletAccounts] = useState([]);
    const [hasNoMobileWalletAccount, setHasNoMobileWalletAccount] = useState(false);
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(new Date());
    const [monthStartDate, setMonthStartDate] = useState("");
    const [monthEndDate, setMonthEndDate] = useState("");

    const history = useHistory();

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        setCurrency(SERVICES.getCurrency());
        
        // Initial orders fetch
        apiService.getOrders(currentUser.id, 0, searchValue)
            .then((res) => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setOrders(res.data.data);
                }
            })
            .catch(error => {
                logger.error('Error fetching orders:', error);
                setErrorMessage('Failed to fetch orders');
                setShowErrorAlert(true);
            });

        // Fetch mobile wallet accounts
        apiService.getMobileWalletAccounts(currentUser.id)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data.data;
                    setMobileWalletAccounts(responseData);
                }
            })
            .catch(error => {
                logger.error('Error fetching mobile wallet accounts:', error);
            });
    }, []);

    const formatDateToString = (date: Date): string | null => {
        let dateStr = null;
        if (date) {
            try {
                dateStr = moment(date).format("DD/MM/yyyy");
            } catch (e) {
                logger.error("Error formatting date:", e);
            }
        }
        return dateStr;
    };

    const handleMonthAndYearChange = (value: Date) => {
        const year = value.getFullYear();
        const month = value.getMonth();

        const startOfMonth = formatDateToString(new Date(year, month, 1));
        const endOfMonth = formatDateToString(new Date(year, (month + 1), 0));

        setMonthStartDate(startOfMonth || "");
        setMonthEndDate(endOfMonth || "");
        setSelectedMonthAndYear(value);
    };

    const downloadStatement = () => {
        let startAt = monthStartDate;
        let endAt = monthEndDate;

        const year = selectedMonthAndYear.getFullYear();
        const month = selectedMonthAndYear.getMonth();

        const startOfMonth = formatDateToString(new Date(year, month, 1));
        const endOfMonth = formatDateToString(new Date(year, (month + 1), 0));

        if (!startAt) startAt = startOfMonth || "";
        if (!endAt) endAt = endOfMonth || "";

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        apiService.downloadStatement(userId, startAt, endAt)
            .then((response: any) => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'statement.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                logger.error('Error downloading statement:', error);
                setErrorMessage('Failed to download statement');
                setShowErrorAlert(true);
            });
    };

    const viewPayment = (token: string) => {
        const currentUser = SERVICES.getUser();
        apiService.downloadInvoice(currentUser.id, token)
            .then((response: any) => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice-${token}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                logger.error('Error downloading invoice:', error);
                setErrorMessage('Failed to download invoice');
                setShowErrorAlert(true);
            });
    };

    const changePage = (pageNumber: number) => {
        const currentUser = SERVICES.getUser();
        setCurrentPage(pageNumber + 1);

        apiService.getOrders(currentUser.id, pageNumber, searchValue)
            .then((res) => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setOrders(res.data.data);
                }
            })
            .catch(error => {
                logger.error('Error fetching orders:', error);
                setErrorMessage('Failed to fetch orders');
                setShowErrorAlert(true);
            });
    };

    const performSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setCurrentPage(1);

        const currentUser = SERVICES.getUser();
        apiService.getOrders(currentUser.id, 0, searchValue)
            .then((res) => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setOrders(res.data.data);
                }
            })
            .catch(error => {
                logger.error('Error searching orders:', error);
                setErrorMessage('Failed to search orders');
                setShowErrorAlert(true);
            });
    };

    return (
        <MobileOrderStyles>
            <SuccessAlert 
                show={showSuccessAlert} 
                message="Operation completed successfully" 
                close={() => setShowSuccessAlert(false)} 
            />
            <ErrorAlert 
                show={showErrorAlert} 
                message={errorMessage || "An error occurred while completing action"} 
                close={() => setShowErrorAlert(false)} 
            />
            
            <Container>
                <div className="header">
                    <h4>Orders</h4>
                    <Form onSubmit={performSearch} className="search-container">
                        <Form.Group className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search orders..."
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />
                            <Button 
                                type="submit"
                                className="search-button"
                            >
                                <FaSearch />
                            </Button>
                        </Form.Group>
                    </Form>
                </div>

                {isLoading ? (
                    <div className="loading-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : orders.length === 0 ? (
                    <div className="empty-state">
                        <p>No orders found</p>
                    </div>
                ) : (
                    <>
                        <div className="orders-container">
                            {orders.map((order, index) => {
                                const customer = order.createdBy ? 
                                    `${order.createdBy.firstName || ''} ${order.createdBy.lastName || ''}`.trim() : '';
                                
                                let status = 'ACTIVE';
                                if (order.isPaid) status = 'PAID';
                                if (order.isCancelled) status = 'CANCELLED';
                                if (order.order?.orderStatus === 'incomplete') status = 'INCOMPLETE';

                                return (
                                    <div key={index} className="order-row">
                                        <div className="order-header">
                                            <span className="order-date">
                                                {moment(order.createdOn).format('DD-MMM-YYYY HH:mm')}
                                            </span>
                                            <span className={`status-badge ${status.toLowerCase()}`}>
                                                {status}
                                            </span>
                                        </div>
                                        <div className="order-content">
                                            <div className="order-customer">{customer}</div>
                                            {order.createdBy?.company?.name && (
                                                <div className="order-company">
                                                    {order.createdBy.company.name}
                                                </div>
                                            )}
                                            <div className="order-amount">
                                                {currency} {order.grossAmount.toLocaleString()}
                                            </div>
                                            <div className="order-actions">
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => viewPayment(order.referenceCode)}
                                                >
                                                    View Invoice
                                                </Button>
                                                {order.order?.orderStatus === 'incomplete' && (
                                                    <Button
                                                        variant="outline-success"
                                                        size="sm"
                                                        onClick={() => {
                                                            const row = document.querySelector(`[data-order-id="${order.order?.id}"]`);
                                                            if (row) {
                                                                const completeBtn = row.querySelector('.complete-btn') as HTMLElement;
                                                                completeBtn?.click();
                                                            }
                                                        }}
                                                    >
                                                        Complete
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {orders.length > 0 && (
                            <div className="pagination-container">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalPageSize}
                                    pageSize={itemsPerPage}
                                    siblingCount={1}
                                    onPageChange={changePage}
                                />
                            </div>
                        )}
                    </>
                )}
            </Container>
        </MobileOrderStyles>
    );
};

export default MobileOrders;
