// @ts-nocheck

/**
 * Project: spaces
 * File: Reservation
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState, useLayoutEffect} from "react";
import { SearchOutline} from "react-ionicons";
import Items  from '../../data/reservations'
import ReservationCard from "../../components/ReservationCard";
import {PageTitle} from "../../layout/PageData";
import SERVICES from "../../services";
import apiService from '../../services/apiService';
import type {AxiosResponse} from "axios";
import {Booking, User} from "../../utils/interfaces";
import { v4 as uuidv4 } from 'uuid';
import Pagination from "../../components/Pagination";
import InformationModal from "../../components/informationModal";
import {useHistory} from "react-router-dom";
import Search from "../../components/Search";
import {Spinner} from "react-bootstrap";
import { Constants } from '../../utils/constants';
import logger from "../../utils/logger";

const Reservation: React.FC =(props) => {
    enum Status {ACTIVE = 'ACTIVE', CANCELLED = 'CANCELLED'}

    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [bookingStatus, setBookingStatus] = useState<Status>(Status.ACTIVE);
    const [searchValue, setSearchValue] = useState('');
    const [bookings, setBookings] = useState([] as Array<Booking>);
    const [selectedReservation, setSelectedReservation] = useState(0);
    const [showCancelReservationModal, setShowCancelReservationModal] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [requestCompleted, setRequestCompleted] = useState(false);

    const history = useHistory();

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    }

    useEffect(() => {
        // redirect to sign in page when user is not logged in

        const user = SERVICES.getUser() as User;

        if (!isLoggedIn(user)) {
            history.push('/signin');
        }
    }, []);

    const getBookingSuccessful = (res: AxiosResponse<any>) => {
        setBookings(res.data.data)
    }

    const displayCancelReservationModal = (bookingId: number) => {
        setShowCancelReservationModal(true);
        setSelectedReservation(bookingId);
    }

    const cancelBooking = (bookingId: number) => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser.id;

        apiService.cancelBooking(bookingId, userId)
            .then(res => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((err) => logger.log(err));
    }

    useEffect(() => {
        const currentUser = SERVICES.getUser() as User;
        const userRole = currentUser?.role;

        setCurrentUserRole(userRole ? userRole : '');
        setBookingStatus(Status.ACTIVE);
    }, []);

    useEffect(() => {
        setRequestCompleted(false);

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        logger.log('current user id exists:', (userId !== null));

        // Check for newly created bookings
        const lastCreatedBookings = localStorage.getItem('lastCreatedBookings');
        const lastBookingTime = localStorage.getItem('lastBookingTime');
        
        if (lastCreatedBookings && lastBookingTime) {
            const bookingTime = new Date(lastBookingTime);
            const now = new Date();
            const timeDiff = now.getTime() - bookingTime.getTime();
            
            // Only consider bookings created in the last 5 minutes
            if (timeDiff < 300000) {
                logger.log('Found recently created bookings:', lastCreatedBookings);
                // Clear the stored bookings
                localStorage.removeItem('lastCreatedBookings');
                localStorage.removeItem('lastBookingTime');
            }
        }

        if (currentUserRole && currentUserRole === 'ROLE_ADMINISTRATOR') {
            apiService.getAllBookings({ 
                sortBy: 'updatedOn', 
                status: bookingStatus 
            })
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalPages);
                    setItemsPerPage(res.data.size);
                    getBookingSuccessful(res);
                }
                setRequestCompleted(true);
            })
            .catch(err => {
                logger.error('Error fetching bookings:', err);
                setRequestCompleted(true);
            });
        } else {
            apiService.getUserBookings(userId, {
                sortBy: 'updatedOn',
                status: bookingStatus,
                page: currentPage - 1
            })
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalPages);
                    setItemsPerPage(res.data.size);
                    getBookingSuccessful(res);
                }
                setRequestCompleted(true);
            })
            .catch(err => {
                logger.error('Error fetching user bookings:', err);
                setRequestCompleted(true);
            });
        }
    }, [currentUserRole, bookingStatus, currentPage]);

    const changePage = (selected: number) => {
        setRequestCompleted(false);
        setBookings([]);

        const currentUser = SERVICES.getUser();
        const userId = currentUser.id;
        setCurrentPage(selected);

        if (currentUserRole === 'ROLE_ADMINISTRATOR') {
            apiService.getAllBookings({
                sortBy: 'updatedOn',
                status: bookingStatus,
                page: selected - 1,
                search: searchValue
            })
                .then(res => {
                    if (res.status === 200) {
                        setTotalPageSize(res.data.totalPages);
                        setItemsPerPage(res.data.size);
                        getBookingSuccessful(res);
                        setRequestCompleted(true);
                    }
                })
                .catch(err => {
                    logger.log(err);
                    setRequestCompleted(true);
                });
        } else {
            apiService.getUserBookings(userId, {
                sortBy: 'updatedOn',
                status: bookingStatus,
                page: selected - 1,
                search: searchValue
            })
                .then(res => {
                    if (res.status === 200) {
                        setTotalPageSize(res.data.totalPages);
                        setItemsPerPage(res.data.size);
                        getBookingSuccessful(res);
                        setRequestCompleted(true);
                    }
                })
                .catch((err) => {
                    logger.log(err);
                    setRequestCompleted(true);
                });
        }
    }

    const truncateDescription = (desc: string) => {
        if (desc.length > 45) {
            return desc.substring(0, 44) + '...';
        } else {
            return desc;
        }
    }

    const performSearch = (e: any) => {
        e.preventDefault();
        setCurrentPage(1);
        setRequestCompleted(false);

        const currentUser = SERVICES.getUser();
        const userId = currentUser.id;

        if (currentUserRole === 'ROLE_ADMINISTRATOR') {
            apiService.getAllBookings({
                sortBy: 'updatedOn',
                status: bookingStatus,
                page: 0,
                search: searchValue
            })
                .then(res => {
                    if (res.status === 200) {
                        setTotalPageSize(res.data.totalPages);
                        setItemsPerPage(res.data.size);
                        getBookingSuccessful(res);
                        setRequestCompleted(true);
                    }
                })
                .catch(err => {
                    logger.log(err);
                    setRequestCompleted(true);
                });
        } else {
            apiService.getUserBookings(userId, {
                sortBy: 'updatedOn',
                status: bookingStatus,
                page: 0,
                search: searchValue
            })
                .then(res => {
                    if (res.status === 200) {
                        setTotalPageSize(res.data.totalPages);
                        setItemsPerPage(res.data.size);
                        getBookingSuccessful(res);
                        setRequestCompleted(true);
                    }
                })
                .catch((err) => {
                    logger.log(err);
                    setRequestCompleted(true);
                });
        }
    }

    const handleSwitch = (e: any) => {

        if (e.target.id === "cancelledBtn") {
            setBookingStatus(Status.CANCELLED);
        }else {
            setBookingStatus(Status.ACTIVE);
        }
    }

    const componentToRender = () => {
        if (requestCompleted && Array.isArray(bookings) && bookings.length > 0) {
            return (
                <div>
                    {
                        bookings.map(booking =>
                            <ReservationCard
                                key={uuidv4()}
                                booking={booking}
                                hasIcon={true}
                                showCancelBookingModal={displayCancelReservationModal}
                            />
                        )
                    }
                </div>
            )
        } else if (requestCompleted && Array.isArray(bookings) && bookings.length === 0) {
            return (
                <div className="container-for-perfect-center">
                    <div className='perfect-center'>
                        <p style={{fontWeight: 'bold', fontSize: '1.3rem'}}>No Reservations Found</p>
                    </div>
                </div>
            )
        } else if (!requestCompleted) {
            return (
                <div className='container-for-perfect-center'>
                    <div className='perfect-center'>
                        <Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
                            <span className="visually-hidden"></span>
                        </Spinner>{" "}
                    </div>
                </div>
            )
        }
    }

    useLayoutEffect(() => {
        const sidebarPanel = document.getElementById('sidebarPanel');
        if (sidebarPanel) {
            sidebarPanel.addEventListener('shown.bs.modal', function (event) {
                sidebarPanel.setAttribute('data-bs-dismiss', 'modal');
            });
        }
    }, []);

    return (
        <div className='mobile-view' style={{background: "#FCFCFC"}}>
            <div className="section mt-2 p-3">
                <PageTitle>Reservations</PageTitle>
                <div className="mt-4">
                    <form className="search-form" onSubmit={performSearch}>
                        <div className="form-group searchbox">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={'Search Reservations'}
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />
                                <i className="input-icon">
                                    <SearchOutline
                                    />
                                </i>
                        </div>
                    </form>
                </div>
                <div className={'mt-3 text-center'}>
                    <div className="btn-group btn-lg " role="group">
                        <input type="radio" className="btn-check" name="activeBtn" id="activeBtn" checked={bookingStatus === Status.ACTIVE} onChange={handleSwitch} onClick={handleSwitch} />
                            <label className={bookingStatus === Status.ACTIVE ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="activeBtn">Active</label>

                            <input type="radio" className="btn-check" name="cancelledBtn" id="cancelledBtn"   checked={bookingStatus === Status.CANCELLED} onChange={handleSwitch}  onClick={handleSwitch} />
                                <label className={bookingStatus === Status.CANCELLED ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="cancelledBtn">Cancelled</label>

                    </div>
                </div>
                <div className={'mt-5'}>
                    {
                        componentToRender()
                    }
                    {/*{checked === 1 && Items.cancelled.map(({name, location, date},key)=><ReservationCard key={key.toString()}  hasIcon={true} name={name} location={location} date={date} bg={'bg-warning'} />)}*/}
                </div>
                {
                    Array.isArray(bookings) && bookings.length > 0 &&
                    <div className='mobile-pagination'>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalPageSize}
                            pageSize={itemsPerPage}
                            onPageChange={changePage}
                        />
                    </div>
                }
            </div>
            <InformationModal body="Are you sure you want to cancel reservation?" title="Confirm Cancel Reservation" isOpen={showCancelReservationModal} isClose={() => setShowCancelReservationModal(false)} onSubmit={() => cancelBooking(selectedReservation)} acceptButton="Accept" declineButton="Cancel" >
                <h6>Are you sure you want to cancel reservation?</h6>
            </InformationModal>
        </div>
    )
}

export default Reservation
