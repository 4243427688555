import React, { useState, useEffect } from "react";
import {
    Form,
    Button,
    Card,
    ListGroup,
} from "react-bootstrap";
import apiService from "../../../../services/apiService";
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { TextField, Select, MenuItem, Chip, Box, OutlinedInput, Checkbox, ListItemText, Autocomplete, SelectChangeEvent } from '@mui/material';
import { PageTitle } from "../../../../layout/PageData";
import logger from "../../../../utils/logger";
import { AnnouncementPayload, Role as RoleType } from "../../../../types";

interface Announcement {
    id: number;
    title: string;
    message: string;
    datetimeToSend: {
        year: number;
        monthValue: number;
        dayOfMonth: number;
        hour: number;
        minute: number;
        second: number;
    };
    roles?: string[];
    userIds?: number[];
}

interface Role {
    value: string;
    label: string;
    description: string;
}

interface User {
    id: number;
    firstName: string | null;
    lastname: string;
    email: string;
    role: string;
}

interface FormState {
    title: string;
    message: string;
    selectedRoles: string[];
    userIds: string[];
    datetimeToSend: Date;
}

const MobileAnnouncements: React.FC = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [newAnnouncement, setNewAnnouncement] = useState<FormState>({
        title: '',
        message: '',
        selectedRoles: [],
        userIds: [],
        datetimeToSend: new Date(moment().add(1, 'hour').toDate())
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage] = useState(0);
    const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
    const [searchResults, setSearchResults] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const pageSize = 10;

    const menuItemStyle = {
        fontSize: '0.8125rem',
    };

    const checkboxStyle = {
        padding: 0,
        marginRight: 8,
    };

    useEffect(() => {
        const loadData = () => {
            fetchAnnouncements();
            fetchRoles();
        };
        loadData();
    }, []);

    const fetchAnnouncements = () => {
        logger.log("Fetching announcements...");
        apiService.getAnnouncements(currentPage + 1, pageSize)
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setAnnouncements(response.data);
                } else {
                    setErrorMessage("Invalid data received from server");
                }
            })
            .catch(error => {
                logger.error("Error fetching announcements:", error);
                setErrorMessage("Error fetching announcements. Please try again later.");
            });
    };

    const fetchRoles = () => {
        logger.log("Fetching roles...");
        apiService.getSupportedRoles()
            .then(response => {
                if (response.data && response.data.data) {
                    const formattedRoles = response.data.data.map((role: any) => ({
                        value: role.value,
                        label: role.name,
                        description: role.description
                    }));
                    setAvailableRoles(formattedRoles);
                }
            })
            .catch(error => {
                logger.error("Error fetching roles:", error);
                setErrorMessage("Error fetching roles. Please try again later.");
            });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewAnnouncement(prev => ({ ...prev, [name]: value }));
    };

    const handleRoleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        setNewAnnouncement(prev => ({
            ...prev,
            selectedRoles: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleDeleteRole = (roleToDelete: string) => {
        setNewAnnouncement(prev => ({
            ...prev,
            selectedRoles: prev.selectedRoles.filter(role => role !== roleToDelete),
        }));
    };

    const searchUsers = async (term: string) => {
        if (!term || term.length < 2) {
            setSearchResults([]);
            return;
        }

        setIsSearching(true);
        try {
            const response = await apiService.searchUsers(term);
            if (Array.isArray(response.data)) {
                const searchTerm = term.toLowerCase();
                const filteredResults = response.data
                    .filter((user: User) => !selectedUsers.some(selectedUser => selectedUser.id === user.id))
                    .sort((a, b) => {
                        const aExactMatch = 
                            a.firstName?.toLowerCase() === searchTerm ||
                            a.lastname?.toLowerCase() === searchTerm ||
                            a.email?.toLowerCase() === searchTerm;
                        const bExactMatch = 
                            b.firstName?.toLowerCase() === searchTerm ||
                            b.lastname?.toLowerCase() === searchTerm ||
                            b.email?.toLowerCase() === searchTerm;
                        
                        if (aExactMatch && !bExactMatch) return -1;
                        if (!aExactMatch && bExactMatch) return 1;
                        
                        const aStartMatch = 
                            a.firstName?.toLowerCase().startsWith(searchTerm) ||
                            a.lastname?.toLowerCase().startsWith(searchTerm) ||
                            a.email?.toLowerCase().startsWith(searchTerm);
                        const bStartMatch = 
                            b.firstName?.toLowerCase().startsWith(searchTerm) ||
                            b.lastname?.toLowerCase().startsWith(searchTerm) ||
                            b.email?.toLowerCase().startsWith(searchTerm);
                        
                        if (aStartMatch && !bStartMatch) return -1;
                        if (!aStartMatch && bStartMatch) return 1;
                        
                        return 0;
                    });
                setSearchResults(filteredResults);
            }
        } catch (error) {
            logger.error('Error searching users:', error);
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    const handleDeleteUser = (userToDelete: User) => {
        setNewAnnouncement(prev => ({
            ...prev,
            userIds: prev.userIds.filter(id => id !== userToDelete.id.toString())
        }));
        setSelectedUsers(prev => prev.filter(user => user.id !== userToDelete.id));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const utcTime = moment(newAnnouncement.datetimeToSend).utc();

        const pushNotificationPayload: AnnouncementPayload = {
            title: newAnnouncement.title,
            message: newAnnouncement.message,
            datetimeToSend: utcTime.format('YYYY-MM-DDTHH:mm:ss'),
            actionType: "newShipmentRequest",
            data: {},
            hasCTA: true,
            token: "",
            topic: "all_users"
        };

        if (newAnnouncement.selectedRoles.length > 0) {
            pushNotificationPayload.roles = newAnnouncement.selectedRoles;
        }

        if (newAnnouncement.userIds.length > 0) {
            pushNotificationPayload.userIds = newAnnouncement.userIds.map(id => parseInt(id));
        }

        try {
            const response = await apiService.createAnnouncement(pushNotificationPayload);
            if (response.status === 200 || response.status === 201) {
                setShowSuccessAlert(true);
                setNewAnnouncement({
                    title: '',
                    message: '',
                    selectedRoles: [],
                    userIds: [],
                    datetimeToSend: new Date(moment().add(1, 'hour').toDate())
                });
                setSelectedUsers([]);
                setSearchResults([]);
                fetchAnnouncements();
            }
        } catch (error) {
            logger.error("Error sending announcement:", error);
            setErrorMessage("Failed to send announcement");
            setShowErrorAlert(true);
        }
    };

    const formatDateTime = (dateTime: any) => {
        if (!dateTime) return 'N/A';
        const date = moment.utc({
            year: dateTime.year,
            month: dateTime.monthValue - 1,
            date: dateTime.dayOfMonth,
            hour: dateTime.hour,
            minute: dateTime.minute,
            second: dateTime.second
        });
        return date.local().format('MMM D, YYYY h:mm A');
    };

    const getDisplayLabel = (roleValue: string) => {
        const role = availableRoles.find(r => r.value === roleValue);
        return role ? role.label : roleValue;
    };

    return (
        <div className="mobile-view" style={{ background: "#FCFCFC" }}>
            <div className="section mt-2 p-3">
                <PageTitle>Announcements</PageTitle>

                <SuccessAlert show={showSuccessAlert} message="Announcement sent successfully" close={() => setShowSuccessAlert(false)} />
                <ErrorAlert show={showErrorAlert} message={errorMessage} close={() => setShowErrorAlert(false)} />

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Create New Announcement</Card.Title>
                        <Form onSubmit={handleSubmit} className="mt-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={newAnnouncement.title}
                                    onChange={handleInputChange}
                                    placeholder="Enter announcement title"
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={newAnnouncement.message}
                                    onChange={handleInputChange}
                                    placeholder="Enter announcement message"
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Select Roles</Form.Label>
                                <Select<string[]>
                                    multiple
                                    value={newAnnouncement.selectedRoles}
                                    onChange={handleRoleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected.length > 0 ? 'Roles selected' : 'Select roles'}
                                    style={{ width: '100%' }}
                                    className="mobile-select"
                                >
                                    {availableRoles.map((role) => (
                                        <MenuItem key={role.value} value={role.value} style={menuItemStyle}>
                                            <Checkbox
                                                checked={newAnnouncement.selectedRoles.indexOf(role.value) > -1}
                                                style={checkboxStyle}
                                            />
                                            <ListItemText primary={role.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 1 }}>
                                    {newAnnouncement.selectedRoles.map((value) => (
                                        <Chip
                                            key={value}
                                            label={getDisplayLabel(value)}
                                            onDelete={() => handleDeleteRole(value)}
                                            className="role-chip"
                                        />
                                    ))}
                                </Box>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Search Users (Optional)</Form.Label>
                                <Autocomplete
                                    multiple
                                    options={searchResults}
                                    getOptionLabel={(option: User) => {
                                        if (!option.firstName && !option.lastname) return option.email;
                                        return `${option.firstName || ''} ${option.lastname || ''} (${option.email})`.trim();
                                    }}
                                    value={selectedUsers}
                                    onChange={(event, newValue) => {
                                        setSelectedUsers(newValue);
                                        setNewAnnouncement(prev => ({
                                            ...prev,
                                            userIds: newValue.map(user => user.id.toString())
                                        }));
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        searchUsers(newInputValue);
                                    }}
                                    loading={isSearching}
                                    filterOptions={(options, { inputValue }) => {
                                        const searchTerm = inputValue.toLowerCase();
                                        return options.filter((option) => {
                                            const fullName = `${option.firstName ?? ''} ${option.lastname || ''}`.toLowerCase();
                                            return fullName.includes(searchTerm) ||
                                                option.firstName?.toLowerCase().includes(searchTerm) ||
                                                option.lastname?.toLowerCase().includes(searchTerm) ||
                                                option.email?.toLowerCase().includes(searchTerm) ||
                                                option.role?.toLowerCase().includes(searchTerm);
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search by name, email, or role..."
                                            className="mobile-autocomplete"
                                        />
                                    )}
                                    renderOption={(props, option: User) => (
                                        <li {...props}>
                                            <div>
                                                <div style={{ fontWeight: 500 }}>
                                                    {option.firstName || ''} {option.lastname || ''}
                                                </div>
                                                <div style={{ fontSize: '0.8rem', color: '#666' }}>
                                                    {option.email} ({option.role})
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 1 }}>
                                    {selectedUsers.map((user) => (
                                        <Chip
                                            key={user.id}
                                            label={`${user.firstName || ''} ${user.lastname || ''} (${user.email})`.trim()}
                                            onDelete={() => handleDeleteUser(user)}
                                            className="user-chip"
                                        />
                                    ))}
                                </Box>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Schedule Time</Form.Label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDateTimePicker
                                        value={newAnnouncement.datetimeToSend}
                                        onChange={(newValue: Date | null) => {
                                            if (newValue) {
                                                setNewAnnouncement(prev => ({
                                                    ...prev,
                                                    datetimeToSend: newValue
                                                }));
                                            }
                                        }}
                                        className="mobile-datetime-picker"
                                        minDateTime={new Date()}
                                    />
                                </LocalizationProvider>
                            </Form.Group>

                            <Button variant="primary" type="submit" className="w-100">
                                Send Announcement
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body>
                        <Card.Title>Recent Announcements</Card.Title>
                        <ListGroup variant="flush" className="mt-3">
                            {announcements.map((announcement, index) => (
                                <ListGroup.Item key={index} className="px-0">
                                    <div className="d-flex justify-content-between align-items-start mb-2">
                                        <h6 className="mb-0 fw-bold">{announcement.title}</h6>
                                        <small className="text-muted">
                                            {formatDateTime(announcement.datetimeToSend)}
                                        </small>
                                    </div>
                                    <p className="mb-1">{announcement.message}</p>
                                    {announcement.roles && (
                                        <div className="mt-2">
                                            {announcement.roles.map((role, i) => (
                                                <Chip
                                                    key={i}
                                                    label={getDisplayLabel(role)}
                                                    size="small"
                                                    className="me-1 mb-1 role-chip-small"
                                                />
                                            ))}
                                        </div>
                                    )}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>

            <style>{`
                .mobile-select {
                    background-color: #fff;
                    border-radius: 4px;
                }
                .role-chip {
                    background-color: #007bff;
                    color: white;
                    margin: 2px;
                    font-size: 0.8125rem;
                }
                .user-chip {
                    background-color: #28a745;
                    color: white;
                    margin: 2px;
                    font-size: 0.8125rem;
                }
                .role-chip-small {
                    background-color: #e9ecef;
                    color: #495057;
                    font-size: 0.75rem;
                }
                .mobile-autocomplete {
                    background-color: #fff;
                    border-radius: 4px;
                }
                .mobile-datetime-picker {
                    width: 100%;
                    background-color: #fff;
                    border-radius: 4px;
                }
            `}</style>
        </div>
    );
};

export default MobileAnnouncements;