import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import bg from "../../../assets/img/spacia/web/Background.png";
import logo from "../../../assets/img/spacia/web/spacia_logo.png";
import apiService from '../../../services/apiService';
import logger from "../../../utils/logger";

const GoToMail = () => {
	const bgImage = {
		width: "100vw",
		height: "100vh",
		background: "white",
		backgroundAttachment: "fixed",
		backgroundPosition: "center",
		backgroundSize: "cover",
		position: "relative",
		backgroundImage: `url(${bg})`,
	};
	const formCard = {
		width: "35%",
		position: "absolute",
		right: "10vw",
		top: "10vh",
		backgroundColor: "white",
		padding: 30,
		boxShadow: "0px 4px 38px #000000, 6%",
	};

	const logopos = {
		position: "relative",
		left: "5vw",
		top: "5vh",
	};

	const [user, setUser] = useState('');

	const resetButton = async () => {
		try {
			const response = await apiService.resetPassword(user.username);
			logger.log(response);
		} catch (error) {
			logger.error('Error resetting password:', error);
		}
	};

	return (
		<Row style={bgImage}>
			<div style={logopos}>
				<img src={logo} alt="logo" />
			</div>
			<div style={formCard}>
				<Container>
					<h5>Password reset request submitted</h5>
					<br />
					<hr/>
					<h6>
						Please go to your mail to complete your password reset
					</h6>
					<br />
					<br />
					<br />
					<div style={{margin: "auto", textAlign: "center"}}>
					</div>
				</Container>
			</div>
		</Row>
	);
};

export default GoToMail;
