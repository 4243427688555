import React from 'react';
import { motion } from 'framer-motion';
import './styles.css';

type AnimationVariants = {
  [key: string]: {
    [key: string]: any;
  };
};

interface Feature {
  icon: string;
  title: string;
  description: string;
  color: string;
}

const features: Feature[] = [
  {
    icon: '🏢',
    title: 'Flexible Workspaces',
    description: 'Book modern offices, meeting rooms, and coworking spaces that fit your needs.',
    color: '#4A90E2'
  },
  {
    icon: '🏠',
    title: 'Premium Accommodations',
    description: 'Find and book quality accommodations for short or long-term stays.',
    color: '#50E3C2'
  },
  {
    icon: '🎯',
    title: 'Instant Booking',
    description: 'Secure your space instantly with our real-time booking system.',
    color: '#F5A623'
  },
  {
    icon: '📱',
    title: 'Mobile Access',
    description: 'Manage your bookings and access spaces right from your phone.',
    color: '#E74C3C'
  }
];

const FeatureSection: React.FC = () => {
  const containerVariants: AnimationVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants: AnimationVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  };

  const iconVariants: AnimationVariants = {
    hover: { 
      scale: 1.2,
      rotate: [0, -10, 10, -10, 0],
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <section className="feature-section">
      <motion.div 
        className="feature-container"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.h2 
          className="feature-heading"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          Why Choose <span className="text-primary">Spacia</span>
        </motion.h2>
        
        <div className="features-grid">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              variants={itemVariants}
              whileHover={{ 
                y: -10,
                transition: { duration: 0.2 }
              }}
            >
              <motion.div 
                className="feature-icon"
                style={{ backgroundColor: feature.color }}
                variants={iconVariants}
                whileHover="hover"
              >
                {feature.icon}
              </motion.div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default FeatureSection;
