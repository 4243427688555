// @ts-nocheck

/**
 * Project: spaces
 * File: SearchDetails
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { toAbsoluteUrl } from "../../layout/helpers";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { BookmarkOutline, Location, LogOutOutline } from "react-ionicons";
import { Toast } from "../../layout/assets/js/Toast";
import { PageTitle, RightComponent } from "../../layout/PageData";
import { useLocation, useHistory } from "react-router-dom";
import { Listing, PropertyImage } from "../../utils/interfaces";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import SERVICES from "../../services";
import apiService from "../../services/apiService";
import { saveCartDetails, setHasItems } from "../../redux/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import logger from "../../utils/logger";


const SearchDetails: React.FC = () => {
    // @ts-ignore
    const cartDetails = useSelector(state => state.dashboard.cartDetails);
    // @ts-ignore
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters); // all user selected filters

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [listing, setListing] = useState({} as Listing);
    const [source, setSource] = useState('');
    const [mainImage, setMainImage] = useState(imgplaceholder);
    const [images, setImages] = useState([] as Array<PropertyImage>);
    const [propertyStatus, setPropertyStatus] = useState('');
    const [listingListedBy, setListingListedBy] = useState('');
    const [floorNumber, setFloorNumber] = useState('');
    const [capacity, setCapacity] = useState('');
    const [size, setSize] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [period, setPeriod] = useState('');
    const [userCurrency, setUserCurrency] = useState('');
    const [image, setImage] = useState(imgplaceholder);
    const [userRole, setUserRole] = useState('');
    const [isIndividualUser, setIsIndividualUser] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorOnBooking, setErrorOnBooking] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [paymentEnabled, setPaymentEnabled] = useState(false);

    useEffect(() => {
        logger.log('search details location:', location);
        const locationState = location?.state;
        const source = locationState?.source;
        const listing = locationState?.listing;

        setSource(source);
        setListing(listing);

        const currency = SERVICES.getCurrency();
        setUserCurrency(currency || '');

        // Get user role and determine user type
        const currentUser = SERVICES.getUser();
        const role = currentUser?.userRole || '';
        setUserRole(role);
        setIsIndividualUser(role.includes('SUBSCRIBER') || role.includes('HOST'));

        // Get dates from selected filters and ensure they are in the correct format
        if (selectedFilters) {
            const from = selectedFilters.from;
            const to = selectedFilters.to;
            
            if (from) {
                setStartDate(from);
                logger.log('Setting start date from filters:', from);
            }
            
            if (to) {
                setEndDate(to);
                logger.log('Setting end date from filters:', to);
            }
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (listing != null && Object.keys(listing).length !== 0) {
            const media = listing.media;
            const images = media && media.images;
            const mainImage = (images && Array.isArray(images) && images.length > 0) ? images[0].resourceUrl : imgplaceholder;

            // setMainImage(mainImage);
            setImage(mainImage);

            // set images in carousel
            setImages(images);

            const propertyStatus = listing.propertyStatus;
            const propertyStatusAsString = propertyStatus ? propertyStatus.label : '-';
            setPropertyStatus(propertyStatusAsString);

            const subCategory = listing.subCategory;
            const owner = listing.createdBy;

            const firstName = owner?.firstName;
            const lastName = owner?.lastName;

            let fullName = '-';
            if (firstName && lastName) {
                fullName = `${firstName} ${lastName}`;
            } else if (firstName && !lastName) {
                fullName = firstName;
            } else if (!firstName && lastName) {
                fullName = lastName;
            }

            const listingListedBy = `${subCategory} listed by ${fullName}`;
            setListingListedBy(listingListedBy);

            const listingDetails = listing.listingDetails;
            const floorNumber = listingDetails ? listingDetails.floorNumber : '';
            const capacity = listingDetails?.capacity;
            const capacityAsString = capacity ? `${capacity}` : '';

            const size = listingDetails?.propertySize;
            const sizeAsString = size ? `${size}` : '';

            setFloorNumber(floorNumber);
            setCapacity(capacityAsString);
            setSize(sizeAsString);

            const location = listing.location;
            const address = location ? location.address : '-';

            setAddress(address);

            const description = listing.description;
            setDescription(description);

            // get property price
            const propertyPrice = listing.propertyPrice;
            const price = propertyPrice && propertyPrice.price;
            const billingPeriod = propertyPrice && propertyPrice.billingPeriod;

            let period = '';
            if (price && billingPeriod) {
                switch (billingPeriod) {
                    case 'HOURLY':
                        period = 'hour';
                        break;

                    case 'WEEKLY':
                        period = 'week';
                        break;

                    case 'MONTHLY':
                        period = 'month';
                        break;

                    case 'DAILY':
                        period = 'day';
                        break;

                    default:
                        period = 'month';
                }

            }

            setAmount(`${price}` || '-');
            setPeriod(period);
        }
    }, [listing]);

    const changeImage = (e: any) => {
        setImage(e.target.src);
    };

    const addToCart = () => {
        const listingId = listing ? listing.id : 0;
        const propertyPrice = listing.propertyPrice;
        const amount = propertyPrice ? propertyPrice.price : 0;

        // calculate sub total
        const subTotal = amount * 1; // 1 because quantity is 1 for now

        const currentUser = SERVICES.getUser();
        const id = currentUser ? currentUser.id : 0;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        const from = selectedFilters.from || '';
        const to = selectedFilters.to || '';

        const payload = {
            endOn: to,
            itemPrice: amount,
            listingId,
            quantity: 1,
            startOn: from,
            subTotal,
            userId: id
        };

        apiService.addToCart(payload, { cartId })
            .then((res) => {
                logger.log(res.data.data[0]);

                if (res.status === 200) {
                    toast.success('Item Successfully added', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    logger.log('done adding to cart. response:', res.data.data[0]);
                    const cartDetails = res.data.data[0];
                    dispatch(saveCartDetails(cartDetails));
                    dispatch(setHasItems(cartDetails !== null));
                } else {
                    logger.warn('an error occurred while adding item to cart');
                }
            }).catch(err => {
                const statusCode = err.response.status;

                switch (statusCode) {
                    case 400:
                        // there may be an issue with the booking start and end date
                        logger.log('status code 400:', err.response);
                        let responseMsg = err.response.data.message;
                        responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);
                        logger.log(responseMsg);
                        break;
                }
            });
    };

    const handleShow = (e: any) => {
        const toast = new Toast();
        toast.showToast('toast-16', 1000);
    };

    const handleDirectBooking = async () => {
        setSaving(true);
        setErrorOnBooking(null);
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const listingId = listing.id;

        if (!startDate || !endDate) {
            toast.error('Please select start and end dates', {
                position: "top-right",
                theme: "colored"
            });
            setSaving(false);
            return;
        }

        try {
            const payload = {
                userId,
                listingId,
                startOn: startDate,
                endOn: endDate
            };

            const response = await apiService.createBooking(payload);

            if (response.status === 200) {
                setSaving(false);
                setErrorOnBooking(null);

                const bookingResponse = response.data?.data;
                const bookingIdsArray = [];

                bookingResponse && bookingResponse.forEach(booking => {
                    bookingIdsArray.push(booking.id);
                });

                await apiService.sendBookingConfirmationEmails(bookingIdsArray.join(','));

                toast.success('Booking confirmed!', {
                    position: "top-right",
                    theme: "colored"
                });

                history.goBack();
            }
        } catch (err) {
            setSaving(false);
            const errorResponse = err.response;
            const data = errorResponse?.data;
            setErrorOnBooking(data?.errorOnBooking);

            if (data?.errorOnBooking === 'SPACE_NOT_PART_OF_PACKAGE' ||
                data?.errorOnBooking === 'NUMBER_OF_CHECKINS_EXCEEDED' ||
                data?.errorOnBooking === 'PERIOD_EXCEEDS_ALLOCATION') {
                setPaymentEnabled(true);
            }

            toast.error(data?.message || 'An error occurred', {
                position: "top-right",
                theme: "colored"
            });
        }
    };

    const createOrderForImmediatePayment = async () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const listingId = listing.id;

        try {
            const payload = {
                userId,
                listingId,
                startDate: startDate, // Use component state instead of selectedFilters
                endDate: endDate
            };

            logger.log('Creating order with dates:', { startDate, endDate });

            const response = await apiService.createOrderForListingPayment(payload);

            if (response.status === 200) {
                const orderData = response.data?.data?.[0];

                // Pass the dates from component state
                history.push({
                    pathname: '/booking-order-summary',
                    state: {
                        listing,
                        order: orderData,
                        user: currentUser,
                        startDate: startDate,
                        endDate: endDate
                    }
                });
            }
        } catch (err) {
            const errorResponse = err.response;
            const data = errorResponse?.data;
            setErrorOnBooking(data?.errorOnBooking);

            toast.error(data?.message || 'Failed to create payment order', {
                position: "top-right",
                theme: "colored"
            });
        }
    };

    return (
        <div className='mobile-view' style={{ background: "#FCFCFC" }}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <PageTitle>Details</PageTitle>
            {/*<RightComponent><BookmarkOutline/></RightComponent>*/}
            <div className="section mt-5 p-3">
                <div className={'position-relative mx-3 h-200px rounded'} style={{ background: `url(${image}) center center` }}>
                    <div className={'position-absolute bottom-0 end-0 mb-2 mx-3'}>
                        <button role={'button'} className={'btn btn-light square text-uppercase border-0'}><span className={'text-white'}>{propertyStatus}</span></button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="on-mobile image-siblings-container">
                        {
                            (images && images.length > 0) &&
                            images.map(image => {
                                const imageUrl = image.resourceUrl || imgplaceholder;

                                return (
                                    <div key={uuid()}>
                                        <img src={imageUrl} onClick={changeImage} className="sibling-images img-fluid" alt="Image 1" />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={'mt-2'}>
                    <h2>{listingListedBy}</h2>
                    <div className={'d-flex justify-content-between'}>
                        <p className={'text-dark'}>  <img src={toAbsoluteUrl('/img/stairs.png')} alt="image" /> {`${floorNumber} floors`} </p>
                        <p className={'text-dark'}> <img src={toAbsoluteUrl('/img/capacity.png')} alt="image" /> {`${capacity} capacity`}</p>
                        <p className={'text-dark'}> <img src={toAbsoluteUrl('/img/size.png')} alt="image" /> {`${size} size`}</p>
                    </div>
                </div>

                <div className={'mt-3'}>
                    <h3>Where you'll be</h3>
                    <div className={'d-flex'}>
                        <Location
                            color={'#6E7070'}
                            title={''}
                            width={'16px'}
                            height={'16px'}
                        /> &nbsp;
                        <p className={'p-0 fontsize-sub'}>{address}</p>
                    </div>
                </div>
                <div className={'mt-3'}>
                    <h2>Description</h2>
                    <p className={'mt-2 text-dark'}>{description}</p>
                </div>
                <div className={'mt-2'}>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <p className={'text-dark pb-0 mb-0'}>
                            <span className={'fw-bolder'}>{userCurrency} {amount}</span> / {period}
                        </p>
                        {
                            (source != null && source === 'properties-page') ?
                                <div /> :
                                <div className="d-flex gap-2">
                                    <button
                                        type={'button'}
                                        className={'btn btn-success btn-lg'}
                                        onClick={createOrderForImmediatePayment}
                                        disabled={!paymentEnabled}
                                        style={{ minWidth: '110px', height: '40px' }}
                                    >
                                        Pay
                                    </button>

                                    <button
                                        type={'button'}
                                        className={'btn btn-primary btn-lg'}
                                        onClick={handleDirectBooking}
                                        style={{ minWidth: '110px', height: '40px' }}
                                    >
                                        {saving ? (
                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : 'Book'}
                                    </button>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {/*<div className={'form-button-group transparent'}>*/}
            {/*    <button role={'button'} className={'btn btn-lg btn-block square fw-bolder'} style={{background: "#D4EDDA", color: "#155724"}}>*/}
            {/*        Item Successfully added</button>*/}
            {/*</div>*/}
        </div>
    );
};
export default SearchDetails;
