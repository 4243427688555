import orderSummaryImage from "../../../../assets/img/png/order-summary-image.png";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Constants } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { toAbsoluteUrl } from "../../../../layout/helpers";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import { Modal } from "react-bootstrap";
import successImage from "../../../../assets/img/png/success-with-bubbles.png";
import SERVICES from "../../../../services";
import rejectedImage from "../../../../assets/img/png/rejected-image.png";
import finishSetupIllustration from "../../../../assets/img/png/finish-setup-illustration.png";
import axios from "axios";
import apiService from '../../../../services/apiService';
import CryptoJS from 'crypto-js';
import aesEcb from "aes-ecb";
import { toast } from 'react-toastify';
// import { paymentService } from '../../../../services/api/payment.service';

// import termsConditionsPDF from '../../../../assets/terms_conditions.pdf';
import logger from "../../../../utils/logger";

const SSE_STORAGE_KEY = 'activeSSEUrl';

function roundToTwo(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
}

function generateKey(keyLength: number) {
    const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}

function hasText(text: string | null) {
    return text != null && text !== "";
}

const PackageOrderSummary = () => {
    // @ts-ignore
    const bundleTypeAndQuantities = useSelector(
        (state: { dashboard: { bundleTypeAndQuantities: any; }; }) =>
            state.dashboard.bundleTypeAndQuantities
    );
    // @ts-ignore
    const { userSessionForBundles: user } = useSelector((state) => state.auth);

    const [currency, setCurrency] = useState("");
    const [orderSummary, setOrderSummary] = useState<any>(null);
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [order, setOrder] = useState<any>({});
    const [referer, setReferer] = useState();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorizationUrl, setAuthorizationUrl] = useState("");

    const location = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [companyRSAPublicKey, setCompanyRSAPublicKey] = useState("");

    const [sseInstance, setSseInstance] = useState<EventSource | null>(null);

    useEffect(() => {
        const handlePaymentMessage = (event: MessageEvent) => {
            if (event.data?.type === 'PAYMENT_COMPLETE') {
                const { redirectPath } = event.data;
                cleanupSSE();
                history.push(redirectPath);
            }
        };

        window.addEventListener('message', handlePaymentMessage);
        return () => window.removeEventListener('message', handlePaymentMessage);
    }, [history]);

    const handlePayment = () => {
        const currentUser = SERVICES.getUser();
        logger.log("User before payment:", currentUser);

        const errors = [];
        if (errors.length === 0) {
            const requestId = uuid();
            const currentUser = SERVICES.getUser();
            const userId = currentUser?.id || order?.billingAccount?.id || 0;
            const companyId = currentUser?.companyId || order?.billingAccount?.company?.id || 0;

            setIsLoading(true);

            // Fix currency determination
            let paymentCurrency = order?.currency || currency || 'KES';
            logger.log("Using currency:", paymentCurrency);

            var invoiceTokens: any[] = [];
            if (order?.referenceCode) {
                invoiceTokens.push(order.referenceCode);
            }

            logger.log(
                "[ " + requestId + " ] about to make request to fetch company RSA public key"
            );

            apiService.getCompanyRsaKey(companyId, userId)
                .then((res) => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        const publicKey = responseData.rsaPublicKey;

                        // generate 256 bit key
                        const bit256 = generateKey(32);

                        // encrypt 256 bit key with RSA Public Key
                        apiService.generateEncryptedKey({
                            bitKey: bit256,
                            rsaPublicKey: publicKey,
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    const requestKey = res.data;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency: paymentCurrency,
                                            paymentMethodCode: null,
                                            userId,
                                            paymentMethod: "webCheckout",
                                            amount: orderSummary?.total,
                                            mobilewallet: null,
                                            electronicCardRequest: null,
                                            bankTransferRequest: null,
                                            isCashOnDelivery: false,
                                        },
                                    };

                                    makeRequestToProcessPayment(
                                        bit256,
                                        payload,
                                        userId,
                                        false
                                    );
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((err) => {
                                logger.log({ err });
                                const errorMsg = err?.response?.data?.message ||
                                    err?.message ||
                                    'An error occurred while processing the payment';

                                setErrorModal(true);
                                setErrorMsg(errorMsg);
                                setShowFailureModal(true);
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    logger.log(err.response);
                    const errorMsg = err?.response?.data?.message || "Error processing payment";
                    setErrorModal(true);
                    setErrorMsg(errorMsg);
                    setShowFailureModal(true);
                });
        }
    };

    useEffect(() => {
        logger.log("location:", location);
        const locationState = location.state;

        // Get currency from user in localStorage
        const currentUser = SERVICES.getUser();
        const userCurrency = currentUser?.country?.currency || 'KES';
        setCurrency(userCurrency);

        const orderSummary = locationState?.summary;
        setOrderSummary(orderSummary);

        const referer = locationState?.referer;
        setReferer(referer);
    }, [location]);

    const cleanupSSE = () => {
        if (sseInstance) {
            logger.log('Closing SSE connection');
            sseInstance.close();
            setSseInstance(null);
            localStorage.removeItem(SSE_STORAGE_KEY);
        }
    };

    useEffect(() => {
        // Clean up any existing SSE connections from previous sessions
        const existingSSEUrl = localStorage.getItem(SSE_STORAGE_KEY);
        if (existingSSEUrl) {
            const oldSSE = new EventSource(existingSSEUrl);
            oldSSE.close();
            localStorage.removeItem(SSE_STORAGE_KEY);
        }

        // Cleanup on unmount
        return () => {
            cleanupSSE();
            localStorage.removeItem(SSE_STORAGE_KEY);
        };
    }, []);

    useEffect(() => {
        const handleUnload = () => {
            cleanupSSE();
        };

        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const observePaymentStatusCallBack = (
        invoiceToken: any,
        callBackurl: any
    ) => {
        cleanupSSE();

        logger.log("callBackurl from paystack is:", callBackurl);
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        // Use apiService to create event source
        const sse = apiService.createEventSource(callBackurl, userId);
        setSseInstance(sse);
        localStorage.setItem(SSE_STORAGE_KEY, `${callBackurl}%2B&userId=${userId}`);

        let hasProcessedPayment = false;
        let retryCount = 0;
        const MAX_RETRIES = 15;

        sse.addEventListener("event", (e: MessageEvent) => {
            let data;
            try {
                data = JSON.parse(e.data);
                logger.log('SSE data received:', data);
                retryCount = 0; // Reset retry count on successful data

                if (!hasProcessedPayment) {
                    if (data && Array.isArray(data) && data.length > 0) {
                        const paymentInfo = data[0];
                        if (paymentInfo.isPaid === true) {
                            hasProcessedPayment = true;
                            cleanupSSE();
                            setShowSuccessModal(true);
                        }
                    } else if (data && data.isPaid === true) {
                        hasProcessedPayment = true;
                        cleanupSSE();
                        setShowSuccessModal(true);
                    }
                }
            } catch (ex) {
                logger.log("Error parsing event data:", ex);
                // Don't increment retry count for parsing errors
            }
        });

        sse.addEventListener("error", (e: any) => {
            logger.log("SSE connection error", e);

            if (!hasProcessedPayment) {
                retryCount++;
                if (retryCount >= MAX_RETRIES) {
                    logger.log("Max retries reached, closing SSE connection");
                    cleanupSSE();
                    setShowFailureModal(true);
                } else {
                    logger.log(`SSE connection error - retry ${retryCount}/${MAX_RETRIES}`);
                    // Let the browser handle automatic reconnection
                }
            }
        });

        // Set a timeout for the overall payment process
        const paymentProcessingTimeout = Number(Constants.paymentProcessingTimeout);
        setTimeout(() => {
            if (!hasProcessedPayment) {
                logger.log("Payment processing timeout reached");
                cleanupSSE();
                setShowFailureModal(true);
            }
        }, paymentProcessingTimeout);
    };

    useEffect(() => {
        if (orderSummary != null) {
            const order = orderSummary.order;
            const summaryList = orderSummary.summaryList;

            setOrder(order);
            setSummaryList(summaryList);
        }
    }, [orderSummary]);

    const isAllOfTheQuantityZero = () => {
        if (
            summaryList &&
            Array.isArray(summaryList) &&
            summaryList.length > 0
        ) {
            return summaryList.every((e) => e.quantity === 0);
        }

        return true;
    };

    const handleStuffOnModalHide = (type: string) => {
        switch (type) {
            case "success":
                setShowSuccessModal(false);
                const paymentSource = localStorage.getItem('paymentSource');

                // Redirect based on payment source
                switch (paymentSource) {
                    case 'membership-plan':
                        history.push("/membership-plan");
                        break;
                    case 'package-signup':
                        history.push("/signin");
                        break;
                    default:
                        history.push("/signin");
                }

                // Clean up storage
                // localStorage.removeItem('paymentSource');
                // localStorage.removeItem('paymentType');
                break;

            case "failure":
                setShowFailureModal(false);
                break;
        }

        const orderSummaryContainer = document.querySelector(".pkg-order-summary");
        if (orderSummaryContainer != null) {
            orderSummaryContainer.classList.remove("blur-background");
        }
    };

    const isInvoiceAlreadyPaid = (error: any) => {
        const errorResponse = error?.response?.data;
        const errors = errorResponse?.errors;

        if (errors && Array.isArray(errors)) {
            return errors.some(err =>
                err.field?.toLowerCase().includes('already paid')
            );
        }
        return false;
    };

    const makeRequestToProcessPayment = (
        bit256: string,
        payload: { key?: any; data: any; },
        userId: any,
        isMultiple: boolean
    ) => {
        try {
            const encrypted = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
            payload.data = encrypted;

            logger.log('Making payment request with payload:', {
                userId,
                isMultiple,
                encryptedDataLength: encrypted.length
            });

            apiService.processPayment(payload, userId)
                .then((res) => {
                    logger.log('Payment API Response:', {
                        status: res.status,
                        fullResponse: res
                    });

                    if (res.status === 200 && res.data?.data) {
                        const responseData = res.data.data;
                        logger.log('Payment response data:', responseData);

                        // Check if we have valid response data
                        if (!Array.isArray(responseData) || responseData.length === 0) {
                            logger.error('Invalid response data format:', {
                                isArray: Array.isArray(responseData),
                                length: responseData?.length,
                                data: responseData
                            });
                            throw new Error("Invalid payment response format");
                        }

                        const paymentData = responseData[0];
                        logger.log('Payment data details:', {
                            paymentData,
                            authUrl: paymentData?.paymentRequestResponse?.data?.authorizationUrl,
                            sseCallback: paymentData?.subscriptionCallbackUrl
                        });

                        const authorizationUrl = paymentData?.paymentRequestResponse?.data?.authorizationUrl;
                        const sseCallbackUrl = paymentData?.subscriptionCallbackUrl;

                        if (!authorizationUrl) {
                            logger.error('Missing authorization URL:', {
                                paymentRequestResponse: paymentData?.paymentRequestResponse,
                                fullPaymentData: paymentData
                            });
                            throw new Error("Payment authorization URL not found");
                        }

                        // Open Paystack window
                        logger.log('Opening payment window with URL:', authorizationUrl);
                        window.open(authorizationUrl, "_blank");

                        // Start observing payment status if we have a callback URL
                        if (sseCallbackUrl && payload.data?.invoiceTokens?.[0]) {
                            logger.log('Starting payment status observation:', {
                                sseCallbackUrl,
                                invoiceToken: payload.data.invoiceTokens[0]
                            });
                            observePaymentStatusCallBack(payload.data.invoiceTokens[0], sseCallbackUrl);
                        } else {
                            logger.warn('Missing SSE data:', {
                                hasCallback: !!sseCallbackUrl,
                                hasInvoiceTokens: !!payload.data?.invoiceTokens,
                                invoiceTokensLength: payload.data?.invoiceTokens?.length
                            });
                        }
                    } else {
                        logger.error('Invalid response:', {
                            status: res.status,
                            hasData: !!res.data,
                            hasDataArray: !!res.data?.data
                        });
                        throw new Error("Invalid response from payment service");
                    }
                })
                .catch((error) => {
                    logger.error("Payment processing error:", {
                        error,
                        message: error.message,
                        response: error.response?.data,
                        stack: error.stack
                    });

                    // Check if invoice is already paid
                    if (isInvoiceAlreadyPaid(error)) {
                        logger.log('Invoice already paid, showing success modal');
                        setShowSuccessModal(true);
                    } else {
                        const errorMsg = error?.response?.data?.message ||
                            error?.message ||
                            'An error occurred while processing the payment';
                        logger.error('Showing error modal with message:', errorMsg);
                        setErrorMsg(errorMsg);
                        setShowFailureModal(true);
                    }
                })
                .finally(() => {
                    logger.log('Payment request completed, resetting loading state');
                    setIsLoading(false);
                });
        } catch (error) {
            logger.error("Payment encryption error:", {
                error,
                message: error,
                stack: error
            });
            setErrorMsg("Failed to process payment. Please try again.");
            setShowFailureModal(true);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        localStorage.setItem("paymentSource", "package-signup");
    }, []);

    return (
        <section
            className={"pkg-order-summary"}
            style={{ padding: "0 70px 70px 70px" }}
        >
            <header style={{ paddingTop: "30px" }}>
                <figure style={{ width: "150px" }}>
                    <img
                        alt={"logo"}
                        decoding={"async"}
                        className={"w100 img-fluid"}
                        src={toAbsoluteUrl("/img/logo-black.png")}
                    />
                </figure>
            </header>
            <div className="row">
                <div className="col-7">
                    <h4 style={{ marginTop: "30px", fontSize: "2rem" }}>
                        Order Summary
                    </h4>
                    <div style={{ fontSize: "1.17rem", marginTop: "50px" }}>
                        <h5>
                            Order No:{" "}
                            <span style={{ fontWeight: "normal" }}>
                                {order ? order.id : ""}
                            </span>
                        </h5>
                        <table style={{ tableLayout: "fixed", width: "100%" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th scope={"col"}>Quantity</th>
                                    <th scope={"col"}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaryList &&
                                    Array.isArray(summaryList) &&
                                    summaryList.length > 0 &&
                                    summaryList.map((summary) => {
                                        const bundle = summary.bundle;
                                        const bundleType = bundle?.bundleType;
                                        const bundleTypeLabel =
                                            bundleType?.label;

                                        const subTotal = summary.subTotal;

                                        return (
                                            <tr key={uuid()}>
                                                <td>{bundleTypeLabel}</td>
                                                <td
                                                    style={{
                                                        borderBottom:
                                                            "1px dotted black",
                                                    }}
                                                />
                                                <td>x{summary.quantity}</td>
                                                <td>
                                                    {subTotal
                                                        ? subTotal.toLocaleString()
                                                        : 0}{" "}
                                                    {currency}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "50px" }}>
                            <p style={{ fontSize: ".93rem", color: "#262626" }}>
                                Total + VAT 16%
                            </p>
                            <p
                                className={"text-primary mb-3"}
                                style={{
                                    fontSize: "2rem",
                                    fontWeight: 700,
                                    marginTop: "15px",
                                }}
                            >
                                {currency}.
                                <span>
                                    {order && order.total
                                        ? order.total.toLocaleString()
                                        : 0}
                                </span>
                            </p>
                            <p>
                                By Clicking “Pay” I agree to the <br />
                                company's <span style={{ textDecoration: 'underline' }}><a className='text-black' href={'/assets/terms_conditions.pdf'} target="_blank" rel="noopener noreferrer">terms & conditions</a></span> for the service
                            </p>
                        </div>

                        <div
                            className={"d-flex justify-content-between"}
                            style={{ width: "35%", marginTop: "100px" }}
                        >
                            <button
                                className="btn btn-outline-info"
                                style={{
                                    width: "100px",
                                    borderRadius: "100px",
                                    height: "40px",
                                }}
                                onClick={() => {
                                    history.go(-1);
                                }}
                            >
                                Back
                            </button>
                            <div
                                style={{
                                    display:
                                        !order || isAllOfTheQuantityZero()
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <button
                                    className="btn btn-outline-info"
                                    style={{
                                        width: "160px",
                                        borderRadius: "100px",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        handlePayment();
                                    }}
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5 order-summary-bg">
                    <figure>
                        <img
                            className={"order-summary"}
                            src={orderSummaryImage}
                            alt="Order Summary"
                        />
                    </figure>
                </div>
            </div>

            {/* payment successful modal */}
            <Modal
                show={showSuccessModal}
                onHide={() => handleStuffOnModalHide("success")}
            >
                <header className={"position-relative"} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: "15px" }}>
                        <img
                            onClick={() => handleStuffOnModalHide("success")}
                            src={closeButton}
                            alt="close button"
                            style={{ width: "25px" }}
                        />
                    </figure>
                </header>
                <Modal.Body style={{ height: "320px" }}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <figure>
                            <img
                                className={"position-relative"}
                                src={successImage}
                                alt="Success"
                                style={{ width: "40%" }}
                            />
                        </figure>
                        <p
                            className={"text-primary font-weight-bolder mt-5"}
                            style={{ fontSize: "1.3rem" }}
                        >
                            Payment Successful!
                        </p>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("success")
                                }
                            >
                                Back to Membership Plan
                            </button>
                        </div>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("success")
                                }
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* payment failed modal */}
            <Modal
                show={showFailureModal}
                onHide={() => handleStuffOnModalHide("failure")}
            >
                <header className={"position-relative"} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: "15px" }}>
                        <img
                            onClick={() => handleStuffOnModalHide("failure")}
                            src={closeButton}
                            alt="close button"
                            style={{ width: "25px" }}
                        />
                    </figure>
                </header>
                <Modal.Body style={{ height: "300px" }}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <figure>
                            <img
                                className={"position-relative"}
                                src={rejectedImage}
                                alt="Failure"
                                style={{ width: "25%" }}
                            />
                        </figure>
                        <p
                            className={"text-primary font-weight-bolder mt-5"}
                            style={{ fontSize: "1.3rem" }}
                        >
                            Payment Failed!
                        </p>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("failure")
                                }
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Error Modal */}
            <Modal
                show={errorModal}
                onHide={() => setErrorModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{
                        borderBottom: 'none',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        margin: '0px',
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: '100%', textAlign: 'center' }}
                    >
                        <h6
                            style={{ fontSize: '1.3rem', fontWeight: 200 }}
                            className="text-primary"
                        >
                            Error
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        height: '300px',
                        padding: '0px',
                        width: '450px',
                        margin: 'auto',
                    }}
                >
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <figure>
                            <img
                                className={"position-relative"}
                                src={rejectedImage}
                                alt="Error"
                                style={{ width: "25%" }}
                            />
                        </figure>
                        <p
                            className={"text-primary font-weight-bolder mt-5"}
                            style={{ fontSize: "1.3rem" }}
                        >
                            {errorMsg}
                        </p>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    setErrorModal(false)
                                }
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        borderTop: 'none',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                </Modal.Footer>
            </Modal>

            {/* authentication modal */}

            <Modal
                show={showAuthModal}
                onHide={() => {
                    setShowAuthModal(false);
                    user ? history.push("/startBooking") : history.push("/signin");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{
                        borderBottom: 'none',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        margin: '0px',
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: '100%', textAlign: 'center' }}
                    >
                        <h6
                            style={{ fontSize: '1.3rem', fontWeight: 200 }}
                            className="text-primary"
                        >
                            Web Checkout
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        height: '850px',
                        padding: '0px',
                        width: '450px',
                        margin: 'auto',
                    }}
                >
                    <iframe
                        src={authorizationUrl}
                        style={{
                            padding: '0px',
                            width: '100%',
                            height: '100%',
                            margin: '0',
                            border: 'none',
                        }}
                        title="Web Checkout"
                    ></iframe>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        borderTop: 'none',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default PackageOrderSummary;
