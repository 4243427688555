// import HostLandingLayout from "../../layout/host-landing-layout-desktop/host-landing-layout";
// import { toAbsoluteUrl } from "../../../layout/helpers";
// import HostLandingLayout from "../../../layout/host-landing-layout-desktop/host-landing-layout";
// import { User } from "../../../utils/interfaces";
import { Link, NavLink } from "react-router-dom";
// import SERVICES from "../../../services";
import "./index.css";
// import { height } from "@mui/system";
import { LogoFacebook, LogoInstagram, LogoTwitter } from "react-ionicons";
import Footer from "../../../footer/footer";

// export type HostLandingWebProps = {};

// eslint-disable-next-line no-empty-pattern
export default function LandingWeb() {
	// const history = useHistory();

	// const isLoggedIn = (user: User) => {
	// 	return user != null && Object.keys(user).length !== 0;
	// };

	// const redirectBasedOnRole = () => {
	// 	const currentUser = SERVICES.getUser() as User;
	// 	let destination = "/startBooking";

	// 	if (!isLoggedIn(currentUser)) {
	// 		destination = "/signin";
	// 	} else {
	// 		const role = currentUser.role;

	// 		switch (role) {
	// 			case "ROLE_ADMINISTRATOR":
	// 				destination = "/dashboard";
	// 				break;

	// 			case "ROLE_SUBSCRIBER_OWNER":
	// 				destination = "/startBooking";
	// 				break;

	// 			default:
	// 				destination = "/properties";
	// 		}
	// 	}

	// 	history.push(destination);
	// };

	return (
		<div className="main_landing_wrapper">
			{/* Hero section */}
			<div className="landingpage_hero_wrapper">
				<div className="landingpage_text_wrapper">
					{/* Header Area */}
					<div className="landingpage_header">
						<Link to="/home">
							<img
								src="/assets/img/new-logo.svg"
								alt="Spacia"
								width={150}
							// onClick={() => window.location.replace("/home")}
							/>
						</Link>
						<div style={{ 
							display: "flex", 
							alignItems: "center", 
							justifyContent: "space-between",
							width: "100%",
							marginLeft: "50px",
							marginRight: "50px"
						}}>
							<nav style={{ fontSize: "16px", display: "flex", gap: "30px", color: "black" }}>
								<NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
								<NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
								<NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
								<a href="/corporate-page#faqSection" style={{ color: "black" }} >FAQ</a>
							</nav>
							<Link 
								to="/signin"
								style={{
									padding: "8px 24px",
									borderRadius: "100px",
									backgroundColor: "#017BEF",
									color: "white",
									textDecoration: "none",
									fontSize: "16px",
									fontWeight: "500",
									transition: "all 0.3s ease"
								}}
								onMouseOver={(e) => {
									e.currentTarget.style.backgroundColor = "#0056b3";
								}}
								onMouseOut={(e) => {
									e.currentTarget.style.backgroundColor = "#017BEF";
								}}
							>
								Sign In
							</Link>
						</div>
					</div>

					<div className="landing_text_area">
						<h2 style={{ fontWeight: "600", fontSize: "29px" }}>The Future of</h2>
						<h1 style={{ color: "#08D3BB", fontSize: "110px", fontWeight: "500", lineHeight: 0.9 }}>work</h1>
						<h1 style={{ color: "#017BEF", fontSize: "110px", whiteSpace: "nowrap", fontWeight: "500", lineHeight: 0.9 }}>
							stay & <span style={{ color: "#170F49" }}>play</span>
						</h1>
						<div style={{ width: "50px", backgroundColor: "#000", height: "3px", marginTop: "15px", marginBottom: "10px" }}></div>
						<p>Spacia gives you the keys to the city's best spaces, whether you're looking for the ideal workspace, a place to stay, or your next adventure.</p>
					</div>

					<div
						style={{
							marginLeft: "80px",
							display: "flex",
							alignItems: "center",
							marginTop: "50px",
							paddingBottom: "50px",
						}}
					>
						<a 
							href="https://apps.apple.com/gh/app/spacia-work-stay-play/id6739746297"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/assets/img/appstore.svg"
								alt="apple store"
								style={{ width: "180px", cursor: "pointer" }}
							/>
						</a>
						<a 
							href="https://play.google.com/store/apps/details?id=com.lucid_array.spacia.individual&pcampaignid=web_share"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/assets/img/googleplay.svg"
								alt="google play store"
								style={{ marginLeft: "20px", width: "180px", cursor: "pointer" }}
							/>
						</a>
					</div>
				</div>
			</div>
			{/* Gradient Area */}
			<div
				style={{
					width: "100vw",
				}}
				className="gradient_image_div"
			></div>
			<div style={{ margin: "20px 100px 0px 100px" }}>
				{/* Why Spacia Section */}
				<div>
					<h3 style={{ textAlign: "center", fontWeight: 600 }}>Why SPACIA</h3>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							width: "100%",
							marginTop: "20px",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginTop: "10px",
							}}
						>
							<div
								style={{
									width: "45%",
									height: "300px",
									backgroundColor: "#08D3BB29",
									padding: "40px 30px",
									borderRadius: "20px",
								}}
							>
								<h3 style={{ color: "#08D3BB", marginBottom: "20px" }}>
									Instant <span style={{ color: "black" }}>Booking</span>
								</h3>
								<p style={{ fontSize: "21px" }}>
									Access the largest catalogue of work and stay locations at the
									most competitive rates. Conveniently pay in-app through a
									centralised billing system.
								</p>
							</div>
							<div>
								<img
									src="/assets/img/home/phone-finance.svg"
									alt="phone and finance"
									width={"350px"}
								/>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginTop: "80px",
							}}
						>
							<div>
								<img
									src="/assets/img/home/wifi-analytics.svg"
									alt="wifi and analytics"
									width={"350px"}
								/>
							</div>
							<div
								style={{
									width: "45%",
									height: "300px",
									backgroundColor: "#0F94D13D",
									padding: "40px 30px",
									borderRadius: "20px",
								}}
							>
								<h3 style={{ color: "#0F94D1", marginBottom: "20px" }}>
									Detailed <span style={{ color: "black" }}>Analytics</span>
								</h3>
								<p style={{ fontSize: "21px" }}>
									Manage distributed teams and have real-time visibility of
									remote work check-ins. Utilise data insights to better
									understand employee performance and ensure informed
									decision-making.
								</p>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginTop: "80px",
							}}
						>
							<div
								style={{
									width: "45%",
									height: "300px",
									backgroundColor: "#8C17C333",
									padding: "40px 30px",
									borderRadius: "50px",
								}}
							>
								<h3 style={{ color: "#8C17C3", marginBottom: "20px" }}>
									Surplus{" "}
									<span style={{ color: "black" }}>Space Management</span>
								</h3>
								<p style={{ fontSize: "21px" }}>
									Repurpose & commercialise your under utilised office space
									through Spacia. Our team of experts will help you to redesign
									your space to maximise its utility.
								</p>
							</div>
							<div>
								<img
									src="/assets/img/home/people-report.svg"
									alt="people report"
									width={"350px"}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* Work Stay Play Section */}
				<div>
					{/* Work */}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "7rem",
							alignItems: "center",
						}}
					>
						<div style={{ width: "40%" }}>
							<h3 className="bold_heading">Work</h3>
							<p style={{ fontSize: "21px", width: "100%", fontWeight: 400, lineHeight: "43px", color: "#263238" }}>
								Find remote work locations to suit the kind of work you want to
								do, whether you need a quiet place to work or a collaborative
								space. Uncover new, creative spaces and streamline your workflow
								on our fast and secure WiFi networks.
							</p>
						</div>
						<div
							style={{
								display: "flex",
								width: "60%",
								height: "400px",
							}}
						>
							<img
								src="/assets/img/home/unsplash3.svg"
								alt="from unsplash"
								width={500}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "20px",
								}}
							>
								<img
									src="/assets/img/home/unsplash2.svg"
									alt="from unsplash"
									style={{ marginBottom: "20px" }}
									width={330}
								/>
								<img
									src="/assets/img/home/unsplash1.svg"
									alt="from unsplash"
									width={330}
								// height={350}
								/>
							</div>
						</div>
					</div>
					{/* Stay */}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "4rem",
							alignItems: "center",
						}}
					>
						<div style={{ width: "40%" }}>
							<h3 className="bold_heading">Stay</h3>
							<p style={{ fontSize: "21px", width: "100%", fontWeight: 400, lineHeight: "43px", color: "#263238" }}>
								Access world-class corporate travel management with tailor-made
								packages for training, retreats, team building and other
								business travel. Explore hidden gems and unforgettable stays
								ideal for your next staycation or city escape.
							</p>
						</div>
						<div
							style={{
								display: "flex",
								width: "60%",
								height: "400px",
							}}
						>
							<img
								src="/assets/img/home/unsplash4.svg"
								alt="from unsplash"
								width={500}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "20px",
								}}
							>
								<img
									src="/assets/img/home/unsplash5.svg"
									alt="from unsplash"
									style={{ marginBottom: "20px" }}
									width={330}
								/>
								<img
									src="/assets/img/home/unsplash6.svg"
									alt="from unsplash"
									width={330}
								/>
							</div>
						</div>
					</div>
					{/* Play */}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "4rem",
							alignItems: "center",
						}}
					>
						<div style={{ width: "40%" }}>
							<h3 style={{ marginBottom: "20px" }}>Play</h3>
							<p style={{ fontSize: "21px", width: "100%", fontWeight: 400, lineHeight: "43px", color: "#263238" }}>
								Enhance your work-life balance with our selection of wellness
								activities, local travel packages and exciting after-work events
								to help you play as hard as you work.
							</p>
						</div>
						<div
							style={{
								display: "flex",
								width: "60%",
								height: "400px",
							}}
						>
							<img
								src="/assets/img/home/unsplash7.svg"
								alt="from unsplash"
								width={500}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginLeft: "20px",
								}}
							>
								<img
									src="/assets/img/home/unsplash8.svg"
									alt="from unsplash"
									style={{ marginBottom: "20px" }}
									width={330}
								/>
								<img
									src="/assets/img/home/unsplash9.svg"
									alt="from unsplash"
									width={330}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Monetize your space */}
			<div
				style={{
					height: "340px",
					background:
						"linear-gradient(91.52deg, #BE6BE5 36.53%, #E8EA78 77.24%, #E3FFCD 106.27%)",
					margin: "50px 0px 20px 0px",
					position: "relative",
				}}
			>
				<div style={{ marginLeft: "80px", paddingTop: "80px" }}>
					<div style={{ color: "white" }}>
						<h1 style={{ fontWeight: "600" }}>MONETIZE your SPACE</h1>
						<p style={{ fontSize: "30px", marginTop: "5px" }}>Today!</p>
					</div>
					<button
						onClick={() => window.location.replace("/host")}
						style={{
							borderRadius: "44px",
							color: "#017BEF",
							boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
							backgroundColor: "white",
							padding: "20px 30px",
							marginTop: "30px",
							fontWeight: "500",
						}}
					>
						Become a Host
					</button>
				</div>
				<img
					src="/assets/img/home/debitcard-hand-elipsis.svg"
					alt="hand holding debitcard"
					style={{ position: "absolute", right: 0, width: "40%", bottom: 0 }}
				/>
			</div>
			{/* Get your team on spacia */}
			<div
				style={{
					height: "340px",
					background:
						"linear-gradient(91.52deg, #017BEF 36.53%, #CDE4FF 106.27%)",
					position: "relative",
				}}
			>
				<div style={{ marginLeft: "80px", paddingTop: "80px" }}>
					<div style={{ color: "white" }}>
						<p style={{ fontSize: "30px", }}>
							Ready to dive in?
						</p>
						<h1 style={{ fontWeight: "600", marginTop: "5px" }}>Get Your Team on SPACIA</h1>
					</div>
					<button
						onClick={() => window.location.replace("/package-signup")}
						style={{
							borderRadius: "44px",
							color: "#017BEF",
							boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
							backgroundColor: "white",
							padding: "20px 30px",
							marginTop: "30px",
							fontWeight: "500",
						}}
					>
						Become a Member
					</button>
				</div>
				<img
					src="/assets/img/home/laptop-men.svg"
					alt="men with laptops"
					style={{ position: "absolute", right: 0, width: "40%", bottom: 0 }}
				/>
			</div>
			<div
				style={{
					background: "rgba(1, 123, 239, 0.73)",
					marginTop: "20px",
					height: "550px",
					position: "relative",
				}}
			>
				<div
					style={{
						marginLeft: "80px",
						paddingTop: "80px",
					}}
				>
					<h1 style={{ color: "white" }}>
						Download <span style={{ color: "black" }}>Our App Now</span>
					</h1>
					<p
						style={{
							color: "black",
							width: "40%",
							fontSize: "18px",
							lineHeight: "2",
							marginTop: "50px",
						}}
					>
						{" "}
						Search, compare & book flexible workspaces, accommodation and other
						lifestyle services from the convenience of the Spacia app
					</p>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginTop: "80px",
							paddingBottom: "50px",
						}}
					>
						<a 
							href="https://apps.apple.com/gh/app/spacia-work-stay-play/id6739746297"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/assets/img/appstore.svg"
								alt="apple store"
								style={{ width: "150px", cursor: "pointer" }}
							/>
						</a>
						<a 
							href="https://play.google.com/store/apps/details?id=com.lucid_array.spacia.individual&pcampaignid=web_share"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/assets/img/googleplay.svg"
								alt="google play store"
								style={{ marginLeft: "20px", width: "150px", cursor: "pointer" }}
							/>
						</a>
					</div>
				</div>
				<img
					style={{ position: "absolute", top: "0", right: "18rem" }}
					src="/assets/img/home/phonemockup1.svg"
					alt="phone mockup"
				/>
				<img
					style={{ position: "absolute", top: "5rem", right: "0" }}
					src="/assets/img/home/phonemockup2.svg"
					alt="phone mockup"
				/>
			</div>
			{/* footer area */}
			<Footer />
		</div>
	);
}
