// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { LeftComponent, PageTitle } from '../../../layout/PageData';
import SERVICES from '../../../services';
import apiService from '../../../services/apiService';
import { format } from 'date-fns';
import { SearchOutline, MenuOutline } from "react-ionicons";
import { Spinner } from "react-bootstrap";
import moment from 'moment';
import { toAbsoluteUrl } from '../../../layout/helpers';
import logger from "../../../utils/logger";
import {
    BsFillPersonFill,  // For private/individual notifications
    BsFillPeopleFill,      // For role-based notifications
    BsAlarm       // For public notifications - using info circle for announcements
} from 'react-icons/bs';

// Define types as a module
export type NotificationType = 'private' | 'public' | 'roleBased';

export interface NotificationData {
    id: string;
    title: string;
    message: string;
    body?: string;
    createdAt: Date;
    isRead: boolean;
    readStatus?: boolean;
    type: NotificationType;
}

export interface NotificationsProps { }

const Notifications: React.FC<NotificationsProps> = () => {
    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    const [filter, setFilter] = useState<'all' | 'read' | 'unread'>('all');
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [requestCompleted, setRequestCompleted] = useState(false);

    useEffect(() => {
        fetchNotifications();
    }, [filter]); // Refetch when filter changes

    const fetchNotifications = async () => {
        setRequestCompleted(false);
        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser?.id;

            const response = await apiService.getNotifications(userId);

            if (response.status === 200) {
                const { privateNotifications = [], publicNotifications = [], roleBasedNotifications = [] } = response.data;

                let allNotifications = [
                    ...privateNotifications.map((n) => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'private' as const
                    })),
                    ...publicNotifications.map((n) => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'public' as const
                    })),

                    ...roleBasedNotifications.map((n) => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'roleBased' as const
                    }))
                ];

                allNotifications.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
                setNotifications(allNotifications);
            }
        } catch (error) {
            logger.error('Error fetching notifications:', error);
            setNotifications([]);
        } finally {
            setRequestCompleted(true);
            setLoading(false);
        }
    };

    const performSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setRequestCompleted(true);
    };

    const handleSwitch = (newFilter: 'all' | 'read' | 'unread') => {
        setFilter(newFilter);
    };

    const getFilteredNotifications = () => {
        let filtered = [...notifications];

        if (searchValue.trim()) {
            filtered = filtered.filter(notification =>
                notification.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                notification.message.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        switch (filter) {
            case 'read':
                return filtered.filter(notification => notification.isRead);
            case 'unread':
                return filtered.filter(notification => !notification.isRead);
            default:
                return filtered;
        }
    };

    const getNotificationIcon = (type: NotificationType) => {
        switch (type) {
            case 'private':
                return (
                    <BsFillPersonFill
                        className="notification-icon"
                        size={15}
                        color="#0D6EFD"  // Blue for private
                    />
                );
            case 'public':
                return (
                    <BsAlarm
                        className="notification-icon"
                        size={15}
                        color="#198754"  // Green for public
                    />
                );
            case 'roleBased':
                return (
                    <BsFillPeopleFill
                        className="notification-icon"
                        size={15}
                        color="#6C757D"  // Gray for role-based
                    />
                );
            default:
                return null;
        }
    };

    const componentToRender = () => {
        const filteredNotifications = getFilteredNotifications();

        if (requestCompleted && filteredNotifications.length > 0) {
            return (
                <div>
                    {filteredNotifications.map(notification => (
                        <div
                            key={notification.id}
                            className="card mb-3"
                            style={{
                                borderRadius: '10px',
                                border: 'none',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                cursor: 'pointer'
                            }}
                            onClick={() => !notification.isRead && markAsRead(notification.id)}
                        >
                            <div className="card-body p-3">
                                <div className="d-flex align-items-start">
                                    <div
                                        className="rounded-circle me-3 d-flex align-items-center justify-content-center"
                                        style={{
                                            width: '42px',
                                            height: '42px',
                                            backgroundColor: notification.type === 'private' ? '#EBF3FF' :   // Light blue for private
                                                notification.type === 'public' ? '#E8F8F3' :     // Light green for public
                                                    '#F8F9FA',                          // Light gray for role-based
                                            transition: 'all 0.2s ease'
                                        }}
                                    >
                                        {getNotificationIcon(notification.type)}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <h6
                                            className="mb-1"
                                            style={{
                                                fontSize: '1rem',
                                                color: '#000',
                                                fontWeight: !notification.isRead ? '600' : '400'
                                            }}
                                        >
                                            {notification.title}
                                        </h6>
                                        <p
                                            className="mb-2"
                                            style={{
                                                fontSize: '0.9rem',
                                                color: '#666',
                                                lineHeight: '1.4'
                                            }}
                                        >
                                            {notification.message}
                                        </p>
                                        <small
                                            className="text-muted"
                                            style={{ fontSize: '0.8rem' }}
                                        >
                                            {moment(notification.createdAt).fromNow()}
                                        </small>
                                    </div>
                                    {!notification.isRead && (
                                        <span
                                            className="bg-primary rounded-circle ms-2 mt-2"
                                            style={{
                                                width: '8px',
                                                height: '8px',
                                                display: 'inline-block',
                                                flexShrink: 0
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else if (requestCompleted && filteredNotifications.length === 0) {
            return (
                <div
                    className="container-for-perfect-center"
                    style={{
                        padding: '0 40px',  // Add horizontal padding
                        minHeight: '300px', // Give more vertical space
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <div className='perfect-center text-center'>
                        <p style={{
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                            color: '#6E7070',  // Softer color for empty state
                            lineHeight: '1.5'
                        }}>
                            {searchValue.trim()
                                ? 'No notifications found matching your search'
                                : `No ${filter === 'all' ? '' : filter} notifications found`}
                        </p>
                    </div>
                </div>
            );
        } else if (!requestCompleted) {
            return (
                <div className='container-for-perfect-center'>
                    <div className='perfect-center'>
                        <Spinner size="sm" animation="border" role="status" style={{ width: '50px', height: '50px' }}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            );
        }
    };

    const markAsRead = async (notificationId: string) => {
        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser?.id;

            const response = await apiService.markNotificationAsRead(notificationId, userId);

            if (response.status === 200) {
                setNotifications(prevNotifications =>
                    prevNotifications.map(notif =>
                        notif.id === notificationId
                            ? { ...notif, isRead: true }
                            : notif
                    )
                );
            }
        } catch (error) {
            logger.error('Error marking notification as read:', error);
        }
    };

    const getNotificationCounts = () => {
        const total = notifications.length;
        const unread = notifications.filter(n => !n.isRead).length;
        const read = notifications.filter(n => n.isRead).length;
        return { total, unread, read };
    };

    return (
        <div className='mobile-view' style={{ background: "#FCFCFC" }}>
            <div className="section mt-2 p-3">
                <PageTitle>Notifications</PageTitle>
                <div className="mt-4">
                    <form className="search-form" onSubmit={performSearch}>
                        <div className="form-group searchbox">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={'Search Notifications'}
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />
                            <i className="input-icon">
                                <SearchOutline />
                            </i>
                        </div>
                    </form>
                </div>

                <div className={'mt-3 text-center'}>
                    <div className="btn-group btn-lg" role="group">
                        <input
                            type="radio"
                            className="btn-check"
                            name="allBtn"
                            id="allBtn"
                            checked={filter === 'all'}
                            onChange={() => handleSwitch('all')}
                        />
                        <label
                            className={filter === 'all' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'}
                            htmlFor="allBtn"
                        >
                            All ({getNotificationCounts().total})
                        </label>

                        <input
                            type="radio"
                            className="btn-check"
                            name="unreadBtn"
                            id="unreadBtn"
                            checked={filter === 'unread'}
                            onChange={() => handleSwitch('unread')}
                        />
                        <label
                            className={filter === 'unread' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'}
                            htmlFor="unreadBtn"
                        >
                            Unread ({getNotificationCounts().unread})
                        </label>

                        <input
                            type="radio"
                            className="btn-check"
                            name="readBtn"
                            id="readBtn"
                            checked={filter === 'read'}
                            onChange={() => handleSwitch('read')}
                        />
                        <label
                            className={filter === 'read' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'}
                            htmlFor="readBtn"
                        >
                            Read ({getNotificationCounts().read})
                        </label>
                    </div>
                </div>

                <div className={'mt-5'}>
                    {componentToRender()}
                </div>
            </div>
        </div>
    );
};

export default Notifications;
