import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import bg from '../../assets/img/spacia/web/Background.png';
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import apiService from '../../services/apiService';
import logger from "../../utils/logger";
import SuccessAlert from "../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";

const isBase64 = (str) => {
    if (!str) return false;
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
};

const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
        errors.push("Password must be at least 8 characters long");
    }
    // if (!/[A-Z]/.test(password)) {
    //     errors.push("Password must contain at least one uppercase letter");
    // }
    // if (!/[a-z]/.test(password)) {
    //     errors.push("Password must contain at least one lowercase letter");
    // }
    // if (!/[0-9]/.test(password)) {
    //     errors.push("Password must contain at least one number");
    // }
    // if (!/[!@#$%^&*]/.test(password)) {
    //     errors.push("Password must contain at least one special character (!@#$%^&*)");
    // }
    return errors;
};

const SavePassword = () => {
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const location = useLocation();
    const search = queryString.parse(location.search);
    const params = search ? search.payload : "";

    let payload = null;
    if (isBase64(params)) {
        try {
            payload = JSON.parse(atob(params));
        } catch (e) {
            logger.error('Error parsing Base64 payload:', e);
            setError('Error processing payload.');
        }
    } else if (params) {
        logger.error('Invalid Base64 string:', params);
        setError('Invalid Base64 data.');
    }

    useEffect(() => {
        if (payload && payload.otp) {
            setToken(payload.otp);
        }
    }, [payload]);

    const bgImage = {
        width: '100vw',
        height: '100vh',
        background: 'white',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        backgroundImage: `url(${bg})`
    };

    const formCard = {
        width: '35%',
        position: 'absolute',
        right: '10vw',
        top: '10vh',
        backgroundColor: 'white',
        padding: 30,
        boxShadow: "0px 4px 38px rgba(0, 0, 0, 0.06)"
    };

    const logopos = {
        position: 'relative',
        left: '5vw',
        top: '5vh'
    };

    const history = useHistory();

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setValidationErrors(validatePassword(newPassword));
    };

    const activateAccount = async () => {
        if (!password || !confirmPassword) {
            setErrorMsg('Password fields cannot be empty');
            setResetError(true);
            return;
        }

        if (validationErrors.length > 0) {
            setErrorMsg('Please fix all password validation errors');
            setResetError(true);
            return;
        }

        if (password !== confirmPassword) {
            setErrorMsg('Passwords do not match');
            setResetError(true);
            return;
        }

        setIsLoading(true);
        setResetError(false);

        try {
            await apiService.savePassword({ confirmPassword, password, token });
            setSuccessAlert(true);
            setTimeout(() => {
                history.push("/signin");
            }, 2000);
        } catch (err) {
            logger.error('Error during API call:', err);
            const errors = err.response?.data?.errors || [];
            const errorMessage = errors.length > 0 ? errors[0].message : 'This password reset link has expired. Please request a new one.';
            setErrorMsg(errorMessage);
            setResetError(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (error && !validationErrors.length) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <ErrorAlert message={error} show={true} close={() => setError(null)} />
            </div>
        );
    }

    return (
        <Row style={bgImage}>
            <div style={logopos}>
                <img src="/assets/img/new-logo.svg" alt="logo" />
            </div>
            <div style={formCard}>
                <SuccessAlert 
                    message="Password changed successfully!" 
                    show={successAlert} 
                    close={() => setSuccessAlert(false)} 
                />
                <ErrorAlert 
                    message={errorMsg} 
                    show={resetError} 
                    close={() => setResetError(false)} 
                />
                <Container>
                    <h5>Change password</h5>
                    <br />
                    <h6>Please fill in your new password to continue to the signin page.</h6>
                    <br />
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input
                            type="password"
                            className={`form-control ${validationErrors.length > 0 ? 'is-invalid' : ''}`}
                            placeholder=""
                            onChange={handlePasswordChange}
                            value={password}
                        />
                        {validationErrors.length > 0 && (
                            <div className="invalid-feedback d-block">
                                {validationErrors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm New Password</label>
                        <input
                            type="password"
                            className={`form-control ${password !== confirmPassword && confirmPassword ? 'is-invalid' : ''}`}
                            placeholder=""
                            onChange={e => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                        />
                        {password !== confirmPassword && confirmPassword && (
                            <div className="invalid-feedback">
                                Passwords do not match
                            </div>
                        )}
                    </div>
                    <br />
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <button
                            onClick={activateAccount}
                            className="button"
                            style={{ width: '100%' }}
                            disabled={isLoading || validationErrors.length > 0}
                        >
                            {isLoading ? 'Changing Password...' : 'Change Password'}
                        </button>
                    </div>
                </Container>
            </div>
        </Row>
    );
};

export default SavePassword;
