import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiService from '../../services/apiService';
import logger from '../../utils/logger';
import { dispatchUser } from '../../redux/actions/auth';
import SERVICES from '../../services';
import { CloseCircle } from "react-ionicons";
import { PageTitle, RightComponent, LeftComponent } from "../../layout/PageData";
import { toAbsoluteUrl } from "../../layout/helpers";
import { toast } from 'react-toastify';

const StyledInput = ({ value, onChange }) => {
    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type="text"
                    className="form-control input-new-rounded floating-input"
                    id="verificationCode"
                    placeholder=" "
                    value={value}
                    onChange={onChange}
                    required
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="verificationCode"
                    style={{
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.02em",
                        color: "#263238",
                    }}
                >
                    Verification Code
                </label>
                {value && (
                    <i className="clear-input">
                        <CloseCircle
                            color={"#00000"}
                            title={"Clear"}
                            onClick={() => onChange({ target: { value: '' } })}
                            style={{ cursor: 'pointer' }}
                        />
                    </i>
                )}
            </div>
        </div>
    );
};

const VerificationCode = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const email = location.state?.email;

    useEffect(() => {
        if (!email) {
            history.push('/signup');
        }
    }, [email, history]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await apiService.verifyOTP({
                otp: verificationCode,
                username: email
            });

            const user = response.data?.data?.[0] || {};
            
            // persist user in session for use in package selection page
            dispatch(dispatchUser(user));
            SERVICES.saveUser(user);
            
            // Also persist for bundles like desktop flow
            dispatch({ type: 'PERSIST_USER_FOR_BUNDLES', payload: user });
            
            toast.success('Email verified successfully');
            history.push('/mobile-package-selection');
        } catch (error) {
            logger.error('Verification error:', error);
            const status = error.response?.data?.status;
            
            let message = 'Failed to verify code. Please try again.';
            if (status === 404) {
                message = 'The verification code you entered is incorrect. Please try again.';
            } else if (status === 400) {
                message = 'Bad request. Please check your input.';
            } else if (status === 401) {
                message = 'Unauthorized access. Please log in again.';
            } else if (status === 500) {
                message = 'Internal server error. Please try again later.';
            }
            
            toast.error(message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="position-relative overflow-hidden" style={{ marginTop: "-56px" }}>
            <PageTitle>{false}</PageTitle>
            <RightComponent>{false}</RightComponent>
            <LeftComponent>{false}</LeftComponent>

            <div className="section position-relative p-0">
                <img
                    className="w-100"
                    decoding={"async"}
                    src={toAbsoluteUrl("/img/Rectangle 18.png")}
                    alt={"Logo"}
                />

                <div className="section mt-2 position-absolute"
                    style={{ bottom: "88px", left: "16px" }}>
                    <h2 className={"text-capitalize text-white"}>Verify Email</h2>
                    <h4 className={"mt-2 text-white"}>Almost there!</h4>
                </div>
            </div>

            <div className="section mb-5 p-2 position-relative"
                style={{
                    backgroundColor: "white",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                    marginTop: "-21px",
                }}>
                <form onSubmit={handleSubmit}>
                    <div className="card-body pb-1">
                        <div className="text-center mb-4">
                            <p className="text-dark">
                                Please enter the verification code sent to<br />
                                <strong>{email}</strong>
                            </p>
                        </div>

                        <StyledInput
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />

                        <div className="d-flex flex-column gap-3 mt-4">
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg w-100"
                                style={{ borderRadius: "40rem" }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : 'Verify Email'}
                            </button>

                            <div className="text-center mt-3">
                                <p className="text-muted mb-1" style={{ fontSize: '0.7rem', opacity: 0.6 }}>
                                    Powered by theSofttribe
                                </p>
                                <p className="text-muted text-capitalize mb-0">
                                    &copy;2022 SPACIA All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerificationCode; 