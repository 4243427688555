import { Link, useHistory } from "react-router-dom";
import { User } from "../../../../utils/interfaces";
import { toAbsoluteUrl } from "../../../../layout/helpers";
import { LogoFacebook, LogoInstagram, LogoTwitter } from "react-ionicons";
import SERVICES from "../../../../services";
import { Constants } from "../../../../utils/constants";
import React, { useEffect, useMemo, useState } from "react";
import {
  packageSelectDataSignUp,
  Package,
  Bundle,
  UpdatedBundle,
  BundleTypeAndQuantity,
} from "../../../../data/package-select-data";
import PackageSelectCard from "../../../../components/bundles/package-select-card";
import PackageSelectOffersCard from "../../../../components/bundles/package-select-offers-card";
import axios, { AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { resetBundleQuantity } from "../../../../redux/actions/dashboard";
import Footer from "../../../../footer/footer";
import apiService from "../../../../services/apiService";
import logger from "../../../../utils/logger";

// const createPaymentOrder = async (cartId: string, userId: string) => {
//     try {
//         const response = await axios.post(
//             `${Constants.BASE_URL}/booking/api/v1/order/create/before/payment`,
//             {
//                 userId,
//                 cartId,
//                 startDate: "2022-10-22T12:30",
//                 endDate: "2022-10-22T13:30",
//             },
//             {
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             }
//         );

//         logger.log("CART: ", cartId);
//         logger.log("USER: ", userId);
//         logger.log("STATUS CODE: ", response.data);

//         if (response.status === 200) {
//             return response.data;
//         } else {
//             throw new Error(`RESPONSE STATUS CODE: ${response.status}`);
//         }
//     } catch (error) {
//         logger.error(error);
//         throw error;
//     }
// };

const PackageSelection = () => {
  // @ts-ignore
  let bundleTypeAndQuantities = useSelector(
    (state: { dashboard: { bundleTypeAndQuantities: any } }) =>
      state.dashboard.bundleTypeAndQuantities
  );
  // @ts-ignore
  const { userSessionForBundles: user } = useSelector((state) => state.auth);
  const cartDetails = useSelector(
    (state: { dashboard: any }) => state.dashboard.cartDetails
  );

  const history: any = useHistory();
  const dispatch = useDispatch();

  const [bundlePackages, setBundlePackages] = useState<Array<Package>>(
    [] as Array<Package>
  );
  const [bundles, setBundles] = useState<Array<Bundle>>([] as Array<Bundle>);
  const [updatedBundles, setUpdatedBundles] = useState<Array<UpdatedBundle>>(
    [] as Array<UpdatedBundle>
  );
  const [ownerAccount, setOwnerAccount] = useState<any>(null);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [confirmOrderResponse, setConfirmOrderResponse] = useState(null);

  const [a, setA] = useState([]);
  const organizationSize = useMemo(
    () => parseInt(localStorage.getItem("organizationSize") || "-1"),
    []
  );

  const getPreferredBundleType = (): string => {
    let preferredBundleType = "LOW";

    if (organizationSize >= 20) {
      preferredBundleType = "HIGH";
    } else if (organizationSize > 10 && organizationSize < 20) {
      preferredBundleType = "MEDIUM";
    }

    return preferredBundleType;
  };

  // get all bundles on system
  useEffect(() => {
    apiService.getAllBundles()
      .then((res) => {
        const { data } = res.data;

        if (data && data.length > 0) {
          const preferredBundleType = getPreferredBundleType();

          bundleTypeAndQuantities = data.filter(
            (bundle) => bundle.bundleType.value == preferredBundleType
          );

          dispatch(resetBundleQuantity(bundleTypeAndQuantities));
          setBundles(bundleTypeAndQuantities);
          setTotalOrderAmount(
            (bundleTypeAndQuantities[0]?.pricing?.cost || 0) * organizationSize
          );
        }
      });
  }, []);

  useEffect(() => {
    if (bundles.length > 0) {
      const firstOne = bundles[0];
      const pricing = firstOne?.pricing;
      const currency = pricing?.currency;

      setCurrency(currency);
    }
  }, [bundles]);

  useEffect(() => {
    setOwnerAccount(user);
  }, [user]);

  const isAllOfTheQuantityZero = () => {
    if (
      bundleTypeAndQuantities &&
      Array.isArray(bundleTypeAndQuantities) &&
      bundleTypeAndQuantities.length > 0
    ) {
      return bundleTypeAndQuantities.every((e) => e.quantity === 0);
    }

    return true;
  };

  const reCalculateOrderAmount = () => {
    if (
      bundleTypeAndQuantities &&
      Array.isArray(bundleTypeAndQuantities) &&
      bundleTypeAndQuantities.length > 0
    ) {
      const updated = bundleTypeAndQuantities.map((e) => {
        const findOneBundle = updatedBundles.find(
          (bundle) => bundle.bundleType.value === e.bundleType
        );

        if (findOneBundle) {
          return { ...findOneBundle, quantity: e.quantity };
        }

        return null;
      });

      let sum = 0;
      updated.forEach((e) => {
        if (e) {
          const currentPricing = e.pricing;
          const cost = currentPricing ? currentPricing.cost : 0;

          sum += cost * e.quantity;
        }
      });

      setTotalOrderAmount(sum);
    }
  };

  useEffect(() => {
    reCalculateOrderAmount();
  }, [bundleTypeAndQuantities]);

  const confirmOrder = () => {
    const ownerId = user?.id;

    const bundleRequest = {
      ownerId,
      bundleTypeAndQuantityList: [ {"bundleType": bundleTypeAndQuantities[0].bundleType.value, "quantity": organizationSize} ],
    };

    apiService.getBundlePurchaseOrderSummary(bundleRequest)
      .then((res) => {
        const response = res.data;
        const responseData = response?.data;
        logger.log("confirmOrder RESPONSE!!!!!!!:", response);
        // save in local storage
        SERVICES.setConfirmOrderResponse(responseData);

        // @ts-ignore
        history.push({
          pathname: "/package-order-summary",
          state: {
            currency,
            summary: responseData,
            referer: "package-signup",
          },
        });
        logger.log("confirmOrder RESPONSE:", response);
      })
      .catch((err) => {
        // handle exception
      });
  };

  // const handleConfirmOrder = () => {
  //     const cartId = cartDetails ? cartDetails["cartId"] : 0;
  //     const ownerId = user?.id;

  //     logger.log("CART ID HERE!:", cartId);
  //     logger.log("OWNER ID HERE!:", ownerId);
  //     try {
  //         // createPaymentOrder(cartId, ownerId)
  //         //     .then((data) => {
  //         //         logger.log(
  //         //             "REAL CREATE PAYMENT ORDER RESPONSE!!!: ",
  //         //             data
  //         //         );

  //         //         setConfirmOrderResponse(data);
  //         //     })
  //         //     .catch((error) => logger.error(error));

  //         confirmOrder();
  //     } catch (error) {
  //         logger.error("ERROR handleConfirmOrder: ", error);
  //     }
  // };

  useEffect(() => {
    if (
      bundles &&
      bundles.length > 0 &&
      bundlePackages &&
      bundlePackages.length > 0
    ) {
      const updatedBundles = bundles.map((bundle) => {
        const orig = bundlePackages.find(
          (bundlePackage) => bundlePackage.title === bundle.bundleType.label
        )!;

        return { ...bundle, packageFromInterface: orig };
      });

      setUpdatedBundles(updatedBundles);
    }
  }, [bundles, bundlePackages]);

  // const isLoggedIn = (user: User) => {
  //   return user != null && Object.keys(user).length !== 0;
  // };

  // const redirectBasedOnRole = () => {
  //   const currentUser = SERVICES.getUser() as User;
  //   let destination = "/startBooking";

  //   if (!isLoggedIn(currentUser)) {
  //     destination = "/signin";
  //   } else {
  //     const role = currentUser.role;

  //     switch (role) {
  //       case "ROLE_ADMINISTRATOR":
  //         destination = "/dashboard";
  //         break;

  //       case "ROLE_SUBSCRIBER_OWNER":
  //         destination = "/startBooking";
  //         break;

  //       default:
  //         destination = "/properties";
  //     }
  //   }

  //   return destination;
  // };

  useEffect(() => {
    setBundlePackages(packageSelectDataSignUp.packages);
  }, []);

  return (
    <div id="appCapsule" className="pkg-select">
      <div className={"gradient"} role="presentation" />
      {/*Main Section*/}
      <section className={"main-section py-5"}>
        <header className={"text-center main-section-header pt-3"}>
          <h1 style={{ fontSize: "2rem", fontWeight: 400 }}>
            Plans tailored for you (based on your team size)
          </h1>
          <p style={{ fontSize: "0.85rem" }}>
            Monitor and track your team’s use of remote working spaces.
            Corporate Administrators and onboard team members, approve requests
            and monitor usage through the Spacia Enterprise App.
          </p>
        </header>

        <div className="packages">
          {updatedBundles &&
            updatedBundles.map((bundle) => {
              const bundlePackage = bundle.packageFromInterface;
              const offers = bundlePackage?.bundleOffer;

              return (
                <div
                  key={uuid()}
                  className="position-relative d-flex"
                  style={{ marginTop: "100px" }}
                >
                  <PackageSelectCard
                    bundlePackage={bundle}
                    bundleTypeAndQuantities={bundleTypeAndQuantities}
                  />
                  <PackageSelectOffersCard offers={offers} />
                </div>
              );
            })}
        </div>
        <div className="row" style={{ marginTop: "100px" }}>
          <div className="col-6"></div>
          <div className="col-6 d-flex">
            <div className={"me-5"}>
              <p
                style={{
                  textDecoration: "underline",
                  color: "#262626",
                  fontWeight: 600,
                  fontSize: "1.4rem",
                }}
              >
                Total + VAT
              </p>
              <p
                className={"text-primary"}
                style={{
                  textDecoration: "underline",
                  fontWeight: 700,
                  fontSize: "1.5rem",
                }}
              >
                {currency}.{totalOrderAmount.toString()}
              </p>
            </div>
            <div className={"d-flex align-items-end"}>
              <button
                className="btn btn-primary text-uppercase"
                disabled={isAllOfTheQuantityZero()}
                onClick={confirmOrder}
              >
                confirm order
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className={"gradient"} role="presentation" />
      {/*Footer*/}
      <Footer />
    </div>
  );
};

export default PackageSelection;
