import React, { useEffect, useState } from "react";
import { Container, Table, Dropdown, Toast } from "react-bootstrap";
import demo from "../../assets/img/Web/Spacia/Demo.png";
import TableRow from "../../components/TableRow";
import BookingTableRow from "../../components/BookingTableRow";
import prop2 from "../../assets/img/Web/Spacia/prop2.png";
import prop3 from "../../assets/img/Web/Spacia/prop3.png";
import {FaCaretDown, FaCircle, FaEllipsisV, FaEllipsisH, FaSearch} from "react-icons/fa";
import { Link } from "react-router-dom";
import SERVICES from '../../services'
import { Constants } from '../../utils/constants';
import moment from 'moment-timezone';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid'
import ReactPaginate from "react-paginate";
import {BookingStyles} from "./BookingStyles";
import Pagination from "../../components/Pagination";
import InformationModal from "../../components/informationModal";
import apiService from '../../services/apiService';
import logger from "../../utils/logger";

const Booking = () => {
	const [totalPageSize, setTotalPageSize] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(0);
	const [bookingStatus, setBookingStatus] = useState('ACTIVE');
	const [searchValue, setSearchValue] = useState('');

	const [bookings, setbookings] = useState([]);
	const [selectedReservation, setSelectedReservation] = useState(0);
	const [showCancelReservationModal, setShowCancelReservationModal] = useState(false);
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	const getBookingSuccessful = (res) => {
		logger.log(res.data.data)
		setbookings(res.data.data)
	}

	const displayCancelReservationModal = (bookingId) => {
		setShowCancelReservationModal(true);
		setSelectedReservation(bookingId);
	}

	const cancelBooking = (bookingId) => {
		const currentUser = SERVICES.getUser();
		if (!currentUser) {
			logger.error("No user found");
			return;
		}
		
		apiService.cancelBooking(bookingId, currentUser.id)
			.then(res => {
				if (res.status === 200) {
					logger.log(res.status);
					window.location.reload(false);
				} else {
					logger.log(res.status);
				}
			})
			.catch((err) => logger.log(err));
	}

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		if (!currentUser) {
			logger.error("No user found");
			setCurrentUserRole(null);
			return;
		}

		setCurrentUserRole(currentUser.role || null);
		setBookingStatus('ACTIVE');
	}, []);

	const fetchBookings = () => {
		const currentUser = SERVICES.getUser();
		if (!currentUser) {
			logger.error("No user found");
			return;
		}

		const params = { sortBy: 'updatedOn', status: bookingStatus };

		if (currentUserRole === 'ROLE_ADMINISTRATOR') {
			apiService.getBookings(params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						logger.log('response for all bookings:', res.data.data);
						getBookingSuccessful(res);
					}
				})
				.catch(err => {
					logger.log(err);
				});
		} else {
			apiService.getUserBookings(currentUser.id, params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						getBookingSuccessful(res);
					}
				})
				.catch((err) => logger.log(err));
		}
	}

	useEffect(() => {
		if (currentUserRole) {
			fetchBookings();
		}
	}, [currentUserRole, bookingStatus]);

	const changePage = (selected) => {
		const currentUser = SERVICES.getUser();
		if (!currentUser) {
			logger.error("No user found");
			return;
		}
		
		logger.log('selected page number is', selected);
		setCurrentPage(selected);

		const params = {
			sortBy: 'updatedOn',
			status: bookingStatus,
			page: (selected - 1),
			search: searchValue
		};

		if (currentUserRole === 'ROLE_ADMINISTRATOR') {
			apiService.getBookings(params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						logger.log('response for all bookings:', res.data.data);
						getBookingSuccessful(res);
					}
				})
				.catch(err => {
					logger.log(err);
				});
		} else {
			apiService.getUserBookings(currentUser.id, params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						getBookingSuccessful(res);
					}
				})
				.catch((err) => logger.log(err));
		}
	}
	// Method=PATCH ${Constants.BASE_URL}/booking/api/v1/booking/${bookingId}}/cancel?cancel=true&userId=${userId} 

	const edit = {
		backgroundColor: "transparent",
		border: "none",
	};

	const toastStyle = {
		width: "100%",
		padding: 20,
		minWidth: '100%',
		marginBottom: '-0.4rem',
		overflowX: 'scroll'
	};

	const truncateDescription = (desc) => {
        if (desc.length > 45) {
            return desc.substring(0, 44) + '...';
        } else {
            return desc;
        }
    }

	const handleSelectChange = (e) => {
		const value = e.target.value;
		logger.log(value);

		setBookingStatus(value);
	}

	const performSearch = (e) => {
		e.preventDefault();
		setCurrentPage(1);

		const currentUser = SERVICES.getUser();
		if (!currentUser) {
			logger.error("No user found");
			return;
		}

		const params = {
			sortBy: 'updatedOn',
			status: bookingStatus,
			page: 0,
			search: searchValue
		};

		if (currentUserRole === 'ROLE_ADMINISTRATOR') {
			apiService.getBookings(params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						logger.log('response for all bookings:', res.data.data);
						getBookingSuccessful(res);
					}
				})
				.catch(err => {
					logger.log(err);
				});
		} else {
			apiService.getUserBookings(currentUser.id, params)
				.then(res => {
					if (res.status === 200) {
						setTotalPageSize(res.data.totalCount);
						setItemsPerPage(res.data.pageSize);
						getBookingSuccessful(res);
					}
				})
				.catch((err) => logger.log(err));
		}
	}

	const showBooking = false;
	return (
		<div>


			{/* Table */}
			<BookingStyles>
			<Container style={{position: 'relative', paddingBottom: '6rem'}}>
				<div
					className="header"
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<div>
						<h3 style={{ fontSize: '1.6rem', fontWeight: 'normal' }}>
							<b>Reservations</b>
						</h3>
					</div>

					<form className="form-inline my-2 my-xl-0" style={{width:"35vw"}} onSubmit={performSearch}>
						{/*<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />*/}
						<input style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: '0!important'}} className="form-control mr-sm-2 search" placeholder='Search' type="search" aria-label="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
						<button style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} className="btn btn-outline-dark my-2 my-sm-0" type="submit"><FaSearch /></button>
					</form>

					<div className="dropdown">

						<select className="form-select" aria-label="status" onChange={handleSelectChange}>
							<option value="ACTIVE">Active</option>
							<option value="CANCELLED">Cancelled</option>
							<option value="HONOURED">Honoured</option>
							<option value="NO_SHOW">No Show</option>
						</select>
                    </div>

					{/* <button className="button" style={{ backgroundColor: "transparent" }}>
					Add New Property{" "}
				</button> */}
				</div>
					<Toast style={toastStyle}>
						{/*<Table style={{width: '144%'}}>*/}
						<Table style={{width: '100%'}}>
							<tr>
								<th style={{minWidth: 150}}>Reservation ID</th>
								{/*<th style={{minWidth: 150}}>Type Of Space</th>*/}
								<th style={{width: 250}}>Property Title</th>
								<th style={{width: 250}}>Address</th>
								<th style={{minWidth: 150}}>Start</th>
								<th style={{minWidth: 150}}>End</th>
								<th style={{minWidth: 100}}>Made By</th>
								<th>Status</th>
								<th style={{display: (bookingStatus === 'ACTIVE') ? 'display' : 'none'}}>Action</th>
							</tr>

							{/* <BookingTableRow /> */}

							{
								Array.isArray(bookings) && bookings.length > 0
								&& bookings.map(booking => {
									logger.log('booking:', booking);
									const requestingUser = booking.requestingUser;
									let name = requestingUser && requestingUser.name;
									if (!name) {
										name = `${requestingUser.firstName} ${requestingUser.lastName}`
									}
									return <tr key={uuidv4()} style={{ borderBottom: "1px dashed black" }}>
										<td>
											{
												booking.referenceCode
											}
										</td>
										{/*<td>*/}
										{/*	{booking.listingItem ? booking.listingItem.subCategory : ""}*/}
										{/*</td>*/}
										<td>
											{booking.listingItem ? truncateDescription(booking.listingItem.name) : ""}
										</td>
										<td>
											{booking.listingItem ? booking.listingItem.location.address : ""}
										</td>
										<td>
											{moment(booking.startOn).format('DD-MMM-YYYY HH:mm')}

										</td>
										<td>
											{moment(booking.endOn).format('DD-MMM-YYYY HH:mm')}
										</td>
										<td>
											{ name }

										</td>
										<td style={{
											color: booking.status.value === "active" ? "#02BD04" : "#F99500",
											fontWeight: "bold",
											textTransform: "capitalize",
										}}>
											{booking.status.value}
										</td>
										<td style={{display: (bookingStatus === 'ACTIVE') ? 'display' : 'none'}}>
											<Dropdown>
												<Dropdown.Toggle
													style={edit}
													id="dropdown-basic"
												>
													<FaEllipsisH color="black" />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item href="#/action-2" onClick={() => displayCancelReservationModal(booking.id)}>
														Cancel
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</td>
									</tr>})
							}
						</Table>
					</Toast>
				{
					Array.isArray(bookings) && bookings.length > 0 &&
					<caption style={{position: 'absolute', right: 0}}>
						<Pagination
							className="pagination-bar"
							currentPage={currentPage || 1}
							totalCount={totalPageSize}
							pageSize={10}
							onPageChange={changePage}
						/>
					</caption>
				}
			</Container>
				<InformationModal body="Are you sure you want to cancel reservation?" title="Confirm Cancel Reservation" isOpen={showCancelReservationModal} isClose={() => setShowCancelReservationModal(false)} onSubmit={() => cancelBooking(selectedReservation)} acceptButton="Accept" declineButton="Cancel" >
					<div  className={"text-primary"}
                            style={{ fontSize: "1.2rem", fontWeight: 400 }}>Are you sure you want to cancel reservation?</div>
				</InformationModal>
		</BookingStyles>
		</div>
	);
};

export default Booking;
