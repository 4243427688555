import { CloseCircle, EllipsisVertical } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import HostLandingLayout from "../../../layout/host-landing-layout-mobile/host-landing-layout";
import {toAbsoluteUrl} from "../../../layout/helpers";
import {User} from "../../../utils/interfaces";
import SERVICES from "../../../services";
import { useEffect } from "react";
import {useDispatch} from "react-redux";
import {resetTotalAmount, saveCartDetails, setCartEntries} from "../../../redux/actions/dashboard";
import { PageTitle, LeftComponent } from "../../../layout/PageData";
// import HostLandingLayout from "../../layout/host-landing-layout-mobile/host-landing-layout";

// export type HostLandingProps = {};

// eslint-disable-next-line no-empty-pattern
export default function Landing() {
	const dispatch = useDispatch();
	const history = useHistory();

	const storageKeys = ['companyId', 'currentUser', 'persist:root', 'userCurrency'];

	useEffect(() => {
		// clear user session anytime Home Page loads
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		const storedListingData = SERVICES.getListingData();
		const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

		if (userId && listingOwnerId && (userId === listingOwnerId)) {
			// if current user has unfinished listings, remove items from storage leaving only owner's listing specific data
			storageKeys.forEach(key => localStorage.removeItem(key));
		} else {
			// localStorage.clear();
		}

		// reset cart entries
		dispatch(saveCartDetails([]));
		dispatch(setCartEntries());
		dispatch(resetTotalAmount());
	}, []);

	const isLoggedIn = (user: User) => {
		return user != null && Object.keys(user).length !== 0;
	}

	const redirectBasedOnRole = () => {
		const currentUser = SERVICES.getUser() as User;
		let destination = "/startBooking";

		if (!isLoggedIn(currentUser)) {
			destination = "/signin";
		} else {
			const role = currentUser.role;

			if (role != null) {
				switch (role) {
					case 'ROLE_ADMINISTRATOR':
					case 'ROLE_HOST':
					case 'ROLE_SUBSCRIBER_INDIVIDUAL':
					case 'ROLE_SUBSCRIBER_APPROVER':
					case 'ROLE_SUBSCRIBER_OWNER':
					case 'ROLE_HOST_OPERATIONS':
						destination = 'searchPage'
						break;

					default:
						destination = '/signin';
				}
			}
		}

		history.push(destination);
	};

	const HeroContent = () => (
		<div className={"hero-content mb-3 "}>
			<h2>The Future of Work, Stay & Play</h2>
			<p>
				Spacia gives you the keys to the city's best spaces, whether you're
				looking for the ideal workspace, a place to stay, or your next
				adventure.
			</p>
		</div>
	);
	return (
		<>
			<PageTitle>Home</PageTitle>
			<LeftComponent>
				<div
					role="button"
					data-bs-toggle="modal"
					data-bs-target="#sidebarPanel"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "40px",
						height: "40px",
					}}
				>
					<EllipsisVertical color={"#666"} height="24px" width="24px" />
				</div>
			</LeftComponent>

			<HostLandingLayout alternateColors alternateHeader={<HeroContent />}>
				<h2 className="text-primary my-5 my-lg-5 py-lg-3 my-md-3 text-center text-uppercase"
					style={{
						fontWeight: 500,
						fontSize: "31.25px",
						lineHeight: "47px"
					}}
				>
					Why <span className="fw-bolder">Spacia?</span>
				</h2>
				<section className="my-4 d-flex flex-column justify-content-between align-items-center">
					<div className="text-card d-flex flex-column align-items justify-content-center">
						<img
							className="d-block pt-sm-5"
							alt="logo"
							src={toAbsoluteUrl("/img/instant-booking-desktop.png")}
						/>
						<h2 className="mb-md-2 mx-auto text-center">
							<span data-color="green">Instant</span>&nbsp;Booking
						</h2>
						<p>
							Access the largest catalogue of work and stay locations at the most
							competitive rates. Conveniently pay in-app through a centralised
							billing system.
						</p>
					</div>

				<div className="text-card mt-5 d-flex flex-column align-items justify-content-center">
					<img
						className={"d-block pt-sm-5"}
						src={toAbsoluteUrl("/img/detailed-analysis-pc.png")}
            alt="logo"
					/>
					<h2 className={"mb-md-2 mx-auto text-center"}>
						<span data-color={"blue"}>Detailed</span>&nbsp;Analytics
					</h2>
					<p>
						Manage distributed teams and have real-time visibility of remote
						work check-ins. Utilise data insights to better understand employee
						performance and ensure informed decision-making.
					</p>
				</div>
				<div className="text-card mt-5 d-flex flex-column align-items justify-content-center">
					<img
						className={"d-block pt-sm-5"}
						src={toAbsoluteUrl("/img/surplus-desktop.png")}
            alt="logo"
					/>
					<h2 className={"mb-md-2 mx-auto text-center"}>
						<span data-color={"purple"}> Surplus</span>&nbsp;Space Management{" "}
					</h2>
					<p>
						{" "}
						Repurpose & commercialise your under utilised office space through
						Spacia. Our team of experts will help you to redesign your space to
						maximise its utility.
					</p>
				</div>
			</section>

			<section className="d-flex flex-column align-items-center mobile-column">
				<h2 className={"mb-2"}>Work</h2>
				<img
					style={{
						gridArea: "A",
						width: "312.12px",
						height: "431px",
						borderRadius: "20px",
					}}
          alt="logo"
					className="img-fluid  mb-2"
					src={toAbsoluteUrl("/img/img_8.png")}
				/>
				<p className={"mx-md-auto m"}>
					Find remote work locations to suit the kind of work you want to do,
					whether you need a quiet place to work or a collaborative space.
					Uncover new, creative spaces and streamline your workflow on our fast
					and secure WiFi networks.
				</p>
				<button
					onClick={redirectBasedOnRole}
					className={
						"btn mx-lg-0 mt-md-3 mt-lg-4 btn-md btn-primary mx-md-auto mb-md-4"
					}
				>
					<span className={"text-white"}>Explore More</span>
				</button>
			</section>

			<section className="d-flex flex-column align-items-center mobile-column">
				<h2 className={"mb-2"}>Stay</h2>
				<img
					style={{
						gridArea: "A",
						width: "312.12px",
						height: "431px",
						borderRadius: "20px",
					}}
          alt="logo"
					className="img-fluid  mb-2"
					src={toAbsoluteUrl("/img/img_11.jpeg")}
				/>

				<p className={"mx-md-auto mx-lg-0"}>
					Access world-class corporate travel management with tailor-made
					packages for training, retreats, team building and other business
					travel. Explore hidden gems and unforgettable stays ideal for your
					next staycation or city escape.
				</p>
				<button
					onClick={redirectBasedOnRole}
					className={
						"btn mx-lg-0 mt-md-3 mt-lg-4 btn-md btn-primary mx-md-auto mb-md-4"
					}
				>
					<span className={"text-white"}>Explore More</span>
				</button>
			</section>
			<section className="d-flex flex-column align-items-center mobile-column mb-5">
				<h2 className={"mb-2"}>Play</h2>
				<img
					style={{
						gridArea: "A",
						width: "312.12px",
						height: "431px",
						borderRadius: "20px",
					}}
          alt="logo"
					className="img-fluid  mb-2"
					src={toAbsoluteUrl("/img/img_12.jpeg")}
				/>

				<p className={"mx-md-auto mx-lg-0"}>
					Enhance your work-life balance with our selection of wellness
					activities, local travel packages and exciting after-work events to
					help you play as hard as you work.
				</p>
				<button
					onClick={redirectBasedOnRole}
					className={
						"btn mx-lg-0 mt-md-3 mt-lg-4 btn-md btn-primary mx-md-auto mb-md-4"
					}
				>
					<span className={"text-white"}>Explore More</span>
				</button>
			</section>
			{/*Get in touch*/}

		</HostLandingLayout>
		</>
	);
}
