/**
 * Project: spaces
 * File: Footer
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, { useState } from "react";
import {
    CalendarOutline, CartOutline,
    CheckmarkCircleOutline, CloseCircle,
    CloseCircleOutline, EllipsisVertical, LogOutOutline, PersonOutline,
    SearchOutline, ShareOutline, ChevronDownOutline
} from 'react-ionicons';
import { useHistory } from "react-router-dom";
import SERVICES from "../../services";
import { resetTotalAmount, saveCartDetails, setCartEntries } from "../../redux/actions/dashboard";
import { useDispatch } from "react-redux";
import logo from '../../assets/img/spacia/web/spacia_logo.png';
import { Constants } from "../../utils/constants";
import LogoutModal from "../../components/LogoutModal";
import { User } from '../../utils/interfaces';

interface MobileMenuItem {
    icon?: React.ReactNode;
    name: string;
    route?: string;
    style?: React.CSSProperties;
    restricted_to: string[];
    subMenus?: MobileMenuItem[];
}

export function FooterElements() {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const [expandedMenus, setExpandedMenus] = useState<number[]>([]);

    const dispatch = useDispatch();
    const history = useHistory();
    
    const renderMenusByRole = () => {
        const currentUser = SERVICES.getUser();
        const currentUserRole = currentUser?.role;

        if (!currentUserRole) {
            const menuItems = Constants.getMenusForMobileByRole('NO_AUTH').map((menu: MobileMenuItem, i: number) => {
                return (
                    <li key={i}>
                        <div 
                            role="button"
                            onClick={() => history.push(menu.route || '/')} 
                            style={{
                                ...menu.style,
                                backgroundColor: activeMenu === menu.route ? '#f8f9fa' : 'transparent',
                            }}
                        >
                            {menu.icon}
                            <div className="in">
                                {menu.name}
                            </div>
                        </div>
                    </li>
                );
            });
            return menuItems;
        }

        const menuItems = Constants.getMenusForMobileByRole(currentUserRole).map((menu: MobileMenuItem, i: number) => {
            if (menu.subMenus) {
                const isExpanded = expandedMenus.includes(i);
                return (
                    <li key={i} style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        <div
                            role="button"
                            onClick={(e) => toggleSubmenu(i, e)}
                            style={{
                                ...menu.style,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                backgroundColor: isExpanded ? '#f8f9fa' : 'transparent',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {menu.icon}
                                <div className="in" style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 'calc(100% - 40px)' // Subtracting icon width and padding
                                }}>
                                    {menu.name}
                                </div>
                            </div>
                            <div style={{ 
                                marginLeft: 'auto', 
                                transition: 'transform 0.2s ease',
                                transform: isExpanded ? 'rotate(180deg)' : 'none' 
                            }}>
                                <ChevronDownOutline color={'#666'} />
                            </div>
                        </div>
                        {isExpanded && menu.subMenus && (
                            <div style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                paddingLeft: '32px',
                                marginTop: '4px',
                                gap: '4px'
                            }}>
                                {menu.subMenus.map((submenu, j) => (
                                    <div
                                        key={`${i}-${j}`}
                                        role="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (submenu.route) {
                                                setActiveMenu(submenu.route);
                                                history.push(submenu.route);
                                            }
                                        }}
                                        style={{
                                            ...submenu.style,
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                            width: '100%',
                                            borderRadius: '8px',
                                            backgroundColor: activeMenu === submenu.route ? '#f8f9fa' : 'transparent',
                                            transition: 'background-color 0.2s ease',
                                        }}
                                    >
                                        {submenu.icon}
                                        <div className="in" style={{ marginLeft: submenu.icon ? '12px' : '0' }}>
                                            {submenu.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </li>
                );
            }

            return (
                <li key={i}>
                    <div
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (menu.route) {
                                setActiveMenu(menu.route);
                                history.push(menu.route);
                            }
                        }}
                        style={{
                            ...menu.style,
                            backgroundColor: activeMenu === menu.route ? '#f8f9fa' : 'transparent',
                        }}
                    >
                        {menu.icon}
                        <div className="in">
                            {menu.name}
                        </div>
                    </div>
                </li>
            );
        });

        menuItems.push(
            <li key="logout">
                <div 
                    role="button"
                    onClick={handleLogoutClick} 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px 16px',
                        transition: 'all 0.2s ease',
                        borderRadius: '12px',
                        margin: '4px 8px',
                        fontSize: '15px',
                        fontWeight: 500,
                        color: '#dc3545',
                    }}
                >
                    <div style={{
                        width: '24px',
                        height: '24px',
                        marginRight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <LogOutOutline color={'#dc3545'} />
                    </div>
                    <div className="in">
                        Logout
                    </div>
                </div>
            </li>
        );

        return menuItems;
    };

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };

    const handleLogoutConfirm = () => {
        setShowLogoutModal(false);
        handleLogout();
    };

    const handleLogoutCancel = () => {
        setShowLogoutModal(false);
    };

    const handleLogout = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const storedListingData = SERVICES.getListingData();
        const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

        if (userId && listingOwnerId && (userId === listingOwnerId)) {
            ['companyId', 'currentUser', 'persist:root', 'userCurrency'].forEach(key => localStorage.removeItem(key));
        }

        history.push("/signin");
        SERVICES.clearAllUserData();

        dispatch(saveCartDetails([]));
        dispatch(setCartEntries());
        dispatch(resetTotalAmount());
    };

    const toggleSubmenu = (index: number, event: React.MouseEvent) => {
        event.preventDefault(); // Prevent default behavior
        event.stopPropagation(); // Stop event bubbling
        setExpandedMenus(prev => 
            prev.includes(index) 
                ? prev.filter(i => i !== index)
                : [...prev, index]
        );
    };

    const handleNavigate = (route: string) => {
        if (!route) return;
        setActiveMenu(route);
        history.push(route);
    };

    const handleClose = () => {
        const sidebarPanel = document.getElementById('sidebarPanel');
        if (sidebarPanel) {
            sidebarPanel.classList.remove('show');
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
        }
    };

    return (
        <div>
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <div className="modal-title">
                                <img src={logo} alt="Spacia Logo" style={{ height: '40px' }} />
                            </div>
                            <div role="button" onClick={handleClose} className="close">
                                <CloseCircle
                                    color={'#00000'}
                                    title={'Close'}
                                    height="32px"
                                    width="32px"
                                />
                            </div>
                        </div>
                        <div className="modal-body d-flex flex-column" style={{ height: 'calc(100vh - 60px)' }}>
                            <div style={{ flex: 1, overflowY: 'auto', paddingBottom: '60px' }}>
                                <ul className="listview flush transparent no-line image-listview mt-2">
                                    {renderMenusByRole()}
                                </ul>
                            </div>
                            <div style={{ 
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: '10px 16px',
                                borderTop: '1px solid rgba(0,0,0,0.05)',
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: '12px',
                                color: '#999',
                                textAlign: 'center',
                                background: '#fff'
                            }}>
                                Powered by theSOFTtribe
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LogoutModal
                show={showLogoutModal}
                onClose={handleLogoutCancel}
                onConfirm={handleLogoutConfirm}
            />

            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex={-1}
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <button type="button" className="close-button" data-bs-dismiss="modal">
                                <CloseCircle color={'#00000'} title={'Close'} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={logo} alt="Spacia Logo" className="imaged w90 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Spacia</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ShareOutline color={'#00000'} title={'Share'} /> and Add to home screen.
                                </div>
                                <div className="mt-2">
                                    <button type="button" className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen"
                tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <button type="button" className="close-button" data-bs-dismiss="modal">
                                <CloseCircle color={'#00000'} title={'Close'} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={logo} alt="Spacia Logo" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Spacia</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <EllipsisVertical color={'#00000'} title={'Menu'} /> and Add to home screen.
                                </div>
                                <div className="mt-2">
                                    <button type="button" className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
