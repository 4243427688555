import React, {useState, useEffect} from "react";
import {Container, Modal, Row} from "react-bootstrap";
import Header from "../../components/Header";
import {Switch, Route, Link} from "react-router-dom";
import Interactive from "../../components/Interactive";
import Workspace from "../../components/ListProperties/Workspace";
import TypeOfWorkspace from "../../components/ListProperties/TypeOfWorkspace";
import HotDesk from "../../components/ListProperties/HotDesk";
import Meeting from "../../components/ListProperties/Meetings";
import TypeProps from "../../components/ListProperties/TypeProp";
import TypeOfProps from "../../components/ListProperties/TypeOfProp";
import TypeOfPropertyC from "../../components/ListProperties/TypePropertyC";
import Stay from "../../components/ListProperties/Stay";
import StayProperty from "../../components/ListProperties/Stay/StayProperty";
import Concierge from "../../components/ListProperties/Stay/Concierge";
import { Constants } from '../../utils/constants';
import * as SERVICES from "../../services/localService";
import closeButton from "../../assets/img/svg/blue-close-button.svg";
import listPropertyIllustration from "../../assets/img/png/list-property-illustration.png";
import apiService from '../../services/apiService';
import logger from "../../utils/logger";

const ListProperty = () => {
	const url = "https://60e22fca5a5596001730f32d.mockapi.io/homes";
	const [product, setProduct] = useState([]);
	const [isCompliant, setIsCompliant] = useState(false);
	const [showComplianceModal, setShowComplianceModal] = useState(false);

	/*  useEffect(() => {
        axios.get(url)
            .then(response => {
            setProduct(response.data)
    })
    }, [url])


    const localurl = `${Constants.BASE_URL}/users/api/v1/users'
    useEffect(() => {
        axios.post(localurl,
        {

            "avatar": "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80",
            "companyId": 1,
            "contacts": [
              {
                "isContactable": true,
                "isPrimary": true,
                "type": "mobile",
                "value": "0545977791"
              }
            ],
            "firstName": "Nana Kweku",
            "lastName": "Adumatta",
            "role": "approverSubscriber",
            "username": "nanakweku@gmail.com",
          }
    //    {headers:{ headers: {"Access-Control-Allow-Origin": "*"}}
    // }


        )
        .then(res=>(logger.log(res)))
        .catch(err=>(logger.log(err))
        )
        logger.log(localurl)
        
    }, [localurl])
*/
	const card = {
		width: "100%",
		height: "70vh",
		backgroundColor: "white",
		paddingTop: "10vh",
	};

	const selectCard = {
		backgroundColor: "#F1F1F1",
		padding: 20,
		borderRadius: 5,
		// width:''
	};

	const text = {
		marginLeft: 40,
		marginBottom: 0,
	};

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser != null ? currentUser.id : 0;
		const companyId = currentUser != null ? currentUser.companyId : 0;

		apiService.getRegulatoryDocumentCompliance(companyId.toString(), userId.toString())
			.then((res) => {
				if (res.status === 200) {
					const response = res.data;
					const responseData = response?.data;

					const firstIndex = (responseData != null && responseData.length >= 0) ? responseData[0] : {};
					const isCompliant = firstIndex.isCompliant;

					logger.log('is user compliant?', isCompliant);

					setIsCompliant(isCompliant);
				} else {
					setIsCompliant(false);
				}
			})
			.catch(err => {
				// handle exception
				logger.log("There was a problem checking Compliance");
			});
	}, []);

	useEffect(() => {
		// show modal if it's host's first sign in
		const currentUser = SERVICES.getUser();
		const role = currentUser?.role;

		const isHostAccount = role != null && role === 'ROLE_HOST';
		logger.log('is current user a host account?', isHostAccount);

		if (isHostAccount) {
			const isFirstSignIn = currentUser?.isFirstSignIn;

			if (isCompliant && isFirstSignIn) {
				setShowComplianceModal(true);

				const listPropertyContainer = document.querySelector('.list-property-container');
				if (listPropertyContainer != null) {
					listPropertyContainer.classList.add('blur-background');
				}
			}
		}
	}, [isCompliant]);

	const handleStuffOnModalHide = () => {
		setShowComplianceModal(false);

		const listPropertyContainer = document.querySelector('.list-property-container');
		if (listPropertyContainer != null) {
			listPropertyContainer.classList.remove('blur-background');
		}
	}

	return (
		<div class={'list-property-container'}>
			<Header title="Add a Listing" />
			<Container>
				<Switch>
					<Route path="/listproperty/typeOfProperty">
						<TypeOfPropertyC />
					</Route>
					<Route path="/listproperty/hotdesk">
						<HotDesk />
					</Route>
					<Route path="/listproperty/meeting">
						<Meeting />
					</Route>
					<Route path="/listproperty/typeofprop">
						<TypeOfProps />
					</Route>
					<Route exact path="/listproperty">
						<TypeProps />
					</Route>
					<Route exact path="/listproperty/stay">
						<Stay />
					</Route>
					<Route path="/listproperty/typeofworkspace">
						<TypeOfWorkspace />
					</Route>

					{/* Stay */}

					<Route path="/listproperty/stayproperty">
						<StayProperty />
					</Route>

					{/* Concierge */}
					<Route path="/listproperty/concierge">
						<Concierge />
					</Route>
				</Switch>

				<Modal show={showComplianceModal} onHide={handleStuffOnModalHide} onEnter={e => logger.log(e)}>
					<header className={'position-relative'} style={{zIndex: '3'}}>
						<figure className="text-right" style={{padding: '15px'}}>
							<img onClick={handleStuffOnModalHide} src={closeButton} alt="close button" style={{width: '25px'}}/>
						</figure>
					</header>
					<Modal.Body style={{height: '270px'}}>
						<div className="text-center position-relative" style={{bottom: '55px'}}>
							<figure>
								<img
									className={'position-relative'}
									src={listPropertyIllustration}
									alt="List Property Illustration"
									style={{width: '40%'}}
								/>
							</figure>
							<p className={'text-primary font-weight-bolder mb-2'} style={{fontSize: '1.03rem'}}>
								All Listings Will Remain Private Prior To Host Approval
							</p>
							<p style={{fontSize: '0.89rem'}}>
								We have received your documents and will get back to you within 2-3 business days
							</p>
						</div>
					</Modal.Body>
				</Modal>
			</Container>
		</div>
	);
};

export default ListProperty;
