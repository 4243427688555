import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PageTitle } from '../../../layout/PageData';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../layout/helpers";
// Using absolute path from public folder

import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import successImage from '../../../assets/img/png/success-with-bubbles.png';
import rejectedImage from '../../../assets/img/png/rejected-image.png';
import SERVICES from '../../../services';
import apiService from '../../../services/apiService';
import aesEcb from "aes-ecb";
import { Location } from "react-ionicons";
import 'react-toastify/dist/ReactToastify.css';
import logger from "../../../utils/logger";
import { v4 as uuid } from "uuid";
import { Constants } from '../../../utils/constants';

const logoPath = '/assets/img/new-logo.svg';
function generateKey(keyLength: number) {
    const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

const SSE_STORAGE_KEY = 'activeSSEUrl';

const PackageOrderSummary: React.FC = () => {
    const Logo = () => (
        <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo.svg")}
            className="h-20px"
        />
    );
    const location = useLocation();
    const history = useHistory();

    const [currency, setCurrency] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [orderData, setOrderData] = useState<any>({});
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [sseInstance, setSseInstance] = useState<EventSource | null>(null);

    useEffect(() => {
        logger.log("location:", location);
        const locationState = location.state;

        // Get currency from user in localStorage
        const currentUser = SERVICES.getUser();
        const userCurrency = currentUser?.country?.currency || 'KES';
        setCurrency(userCurrency);

        const orderSummary = locationState?.summary;
        if (!orderSummary) {
            logger.error('Missing order summary');
            toast.error('Missing package information. Please try again.', {
                position: "top-right",
                theme: "colored"
            });
            history.goBack();
            return;
        }

        const order = orderSummary.order;
        const summaryList = orderSummary.summaryList || [];
        setOrderData(order);
        setSummaryList(summaryList);
        setTotal(order?.total || 0);

        logger.log('Package order summary initialized with:', { orderSummary, order, summaryList });
    }, [location]);

    const cleanupSSE = () => {
        if (sseInstance) {
            sseInstance.close();
            setSseInstance(null);
        }
    };

    const makeRequestToProcessPayment = (bit256: string, payload: { key?: any; data: any; }, userId: any, isMultiple: boolean) => {
        try {
            const encrypted = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
            payload.data = encrypted;

            logger.log('Making payment request with payload:', {
                userId,
                isMultiple,
                encryptedDataLength: encrypted.length
            });

            apiService.processPayment(payload, userId)
                .then((res) => {
                    logger.log('Payment API Response:', {
                        status: res.status,
                        data: res.data
                    });

                    if (res.status === 200) {
                        const paymentData = res.data?.data?.[0];
                        
                        if (paymentData?.paymentRequestResponse?.data?.authorizationUrl) {
                            const authUrl = paymentData.paymentRequestResponse.data.authorizationUrl;
                            const callbackUrl = paymentData.subscriptionCallbackUrl;
                            
                            logger.log('Payment URLs:', { authUrl, callbackUrl });

                            // Set all required items for payment redirect handling
                            localStorage.setItem('paymentSource', 'package-signup');
                            localStorage.setItem('paymentType', 'package');
                            localStorage.setItem('paymentReference', paymentData.referenceCode);
                            localStorage.setItem('paymentAmount', paymentData.amount.toString());
                            localStorage.setItem('paymentCurrency', paymentData.invoicedAmounts[0].currency);
                            localStorage.setItem('redirectAfterPayment', '/signin');
                            
                            // Start SSE listener before redirect
                            const invoiceToken = paymentData.referenceCode;
                            observePaymentStatusCallBack(invoiceToken, callbackUrl);

                            // Set success message for after payment
                            localStorage.setItem('paymentSuccessMessage', 'Package purchased successfully! Please sign in to continue.');

                            // Redirect to PayStack
                            window.location.href = authUrl;
                        } else {
                            logger.error('Missing authorization URL in response:', paymentData);
                            setErrorMsg('Payment initialization failed');
                            setShowFailureModal(true);
                        }
                    } else {
                        logger.error('Invalid payment response:', res.data);
                        setErrorMsg(res.data?.message || 'Payment processing failed');
                        setShowFailureModal(true);
                    }
                    setIsProcessing(false);
                })
                .catch((err) => {
                    logger.error('Payment error:', err);
                    const errorMessage = err?.response?.data?.message || 'Payment processing failed. Please try again.';
                    setErrorMsg(errorMessage);
                    setShowFailureModal(true);
                    setIsProcessing(false);
                });
        } catch (error: any) {
            logger.error('Payment encryption error:', error);
            const errorMessage = error?.message || 'Failed to encrypt payment data';
            setErrorMsg(errorMessage);
            setShowFailureModal(true);
            setIsProcessing(false);
        }
    };

    const observePaymentStatusCallBack = (
        invoiceToken: any,
        callBackurl: any
    ) => {
        cleanupSSE();

        logger.log("Setting up SSE with URL:", callBackurl);
        const currentUser = SERVICES.getUser();
        const userId = currentUser?.id || 0;

        try {
            // Create new SSE connection
            const sse = new EventSource(`${callBackurl}?userId=${userId}`);
            setSseInstance(sse);

            let hasProcessedPayment = false;

            sse.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    logger.log('SSE message received:', data);

                    if (!hasProcessedPayment && data?.length > 0) {
                        const paymentInfo = data[0];
                        if (paymentInfo.isPaid === true) {
                            hasProcessedPayment = true;
                            cleanupSSE();
                            setShowSuccessModal(true);
                            history.push('/signin');
                        }
                    }
                } catch (error) {
                    logger.error('Error processing SSE message:', error);
                }
            };

            sse.onerror = (error) => {
                logger.error('SSE connection error:', error);
                if (sse.readyState === EventSource.CLOSED) {
                    cleanupSSE();
                }
            };

            // Store SSE URL for cleanup
            localStorage.setItem(SSE_STORAGE_KEY, callBackurl);

            // Set timeout for payment processing
            const timeout = setTimeout(() => {
                if (!hasProcessedPayment) {
                    logger.log('Payment processing timeout');
                    cleanupSSE();
                    setShowFailureModal(true);
                }
            }, Number(Constants.paymentProcessingTimeout) || 300000);

            return () => {
                clearTimeout(timeout);
                cleanupSSE();
            };
        } catch (error) {
            logger.error('Error setting up SSE:', error);
            setShowFailureModal(true);
        }
    };

    const handleProceedToPayment = () => {
        setShowConfirmModal(true);
    };

    const handlePayment = () => {
        if (isProcessing) return;

        const requestId = uuid();
        const currentUser = SERVICES.getUser();
        const userId = orderData?.billingAccount?.id || currentUser?.id || 0;
        const companyId = orderData?.billingAccount?.company?.id || currentUser?.companyId || 0;

        setIsProcessing(true);

        // Fix currency determination
        let paymentCurrency = orderData?.currency || currency || 'KES';
        logger.log("Using currency:", paymentCurrency);

        var invoiceTokens: any[] = [];
        if (orderData?.referenceCode) {
            invoiceTokens.push(orderData.referenceCode);
        }

        logger.log(
            "[ " + requestId + " ] about to make request to fetch company RSA public key"
        );

        apiService.getCompanyRsaKey(companyId, userId)
            .then((res) => {
                if (res.status === 200) {
                    let responseData = res.data.data[0];
                    const publicKey = responseData.rsaPublicKey;

                    // generate 256 bit key
                    const bit256 = generateKey(32);

                    // encrypt 256 bit key with RSA Public Key
                    apiService.generateEncryptedKey({
                        bitKey: bit256,
                        rsaPublicKey: publicKey,
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const requestKey = res.data;

                                const payload = {
                                    key: requestKey,
                                    data: {
                                        requestId,
                                        invoiceIds: [],
                                        invoiceTokens,
                                        currency: paymentCurrency,
                                        paymentMethodCode: null,
                                        userId,
                                        paymentMethod: "webCheckout",
                                        amount: total,
                                        mobilewallet: null,
                                        electronicCardRequest: null,
                                        bankTransferRequest: null,
                                        isCashOnDelivery: false,
                                    },
                                };

                                makeRequestToProcessPayment(
                                    bit256,
                                    payload,
                                    userId,
                                    false
                                );
                            } else {
                                setIsProcessing(false);
                            }
                        })
                        .catch((err) => {
                            logger.log({ err });
                            const errorMsg = err?.response?.data?.message ||
                                err?.message ||
                                'An error occurred while processing the payment';

                            setErrorModal(true);
                            setErrorMsg(errorMsg);
                            setShowFailureModal(true);
                        });
                } else {
                    setIsProcessing(false);
                }
            })
            .catch((err) => {
                logger.log(err.response);
                const errorMsg = err?.response?.data?.message || "Error processing payment";
                setErrorModal(true);
                setErrorMsg(errorMsg);
                setShowFailureModal(true);
            });
    };

    const handleSuccessModalClose = () => {
        setShowSuccessModal(false);
        const redirectPath = localStorage.getItem('redirectAfterPayment') || '/signin';
        history.push(redirectPath);
        
        // Clear payment-related items from localStorage
        localStorage.removeItem('paymentSource');
        localStorage.removeItem('paymentType');
        localStorage.removeItem('paymentReference');
        localStorage.removeItem('paymentAmount');
        localStorage.removeItem('paymentCurrency');
        localStorage.removeItem('redirectAfterPayment');
        localStorage.removeItem('paymentSuccessMessage');
    };

    const handleFailureModalClose = () => {
        setShowFailureModal(false);
    };

    return (
        <div className="pkg-order-summary">
            <div className="py-5 px-4">
                <div className="text-center mb-8">
                    <img
                        alt="Logo"
                        src={logoPath}
                        className="h-16px"
                    />
                </div>
                <div className="card shadow-sm rounded-3 border-0">
                    <div className="card-body p-8">

                        <h2 className="mt-2">Order Summary</h2>

                        {summaryList.map((item, index) => (
                            <div key={index} className="package-details mt-4 pb-3" style={{borderBottom: index < summaryList.length - 1 ? '1px solid #eee' : 'none'}}>
                                <h4 className="text-primary">{item.bundle.bundleType.label}</h4>
                                
                                <div className="details-list mt-3">
                                    <div className="d-flex justify-content-between mb-2">
                                        <span>Cost per User</span>
                                        <span>{currency} {item.bundle.pricing.cost.toLocaleString()}</span>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <span>Number of Users</span>
                                        <span>{item.quantity}</span>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <span>Subtotal</span>
                                        <span>{currency} {item.subTotal.toLocaleString()}</span>
                                    </div>
                                </div>

                                <div className="features mt-3">
                                    <h5 className="mb-2">Package Features:</h5>
                                    {item.bundle.offers.map((offer: any, offerIndex: number) => (
                                        <div key={offerIndex} className="d-flex align-items-start mb-2">
                                            <i className="fas fa-check text-success me-2 mt-1"></i>
                                            <span>
                                                {offer.propertyType.label}: {offer.accessDuration.duration} {offer.accessDuration.periodType.value}
                                                {offer.allowedNumberOfCheckIns === -1 ? ' (Unlimited)' : ` (${offer.allowedNumberOfCheckIns} check-ins)`}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}

                        <div className="order-total mt-4">
                            <div className="d-flex justify-content-between mb-2">
                                <span>Reference Code</span>
                                <span className="text-muted">{orderData.referenceCode}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <strong>Total Amount</strong>
                                <strong className="text-primary">{currency} {total.toLocaleString()}</strong>
                            </div>
                        </div>

                        <div className="mt-4 d-flex gap-3">
                            <button
                                onClick={() => history.goBack()}
                                className="btn btn-outline-primary flex-grow-1"
                                style={{ borderRadius: '8px' }}
                            >
                                Back
                            </button>
                            <button
                                onClick={handleProceedToPayment}
                                disabled={isProcessing}
                                className="btn btn-primary flex-grow-1"
                                style={{ borderRadius: '8px' }}
                            >
                                {isProcessing ? 'Processing...' : 'Pay'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            {/* Confirm Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
                style={{
                    borderRadius: "12px"
                }}
            >
                <Modal.Header
                    style={{
                        border: 'none',
                        padding: '15px 15px 0 15px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        minHeight: '40px'
                    }}
                >
                    <button
                        onClick={() => setShowConfirmModal(false)}
                        style={{
                            background: 'none',
                            border: 'none',
                            fontSize: '20px',
                            color: '#000',
                            padding: '0',
                            margin: '0',
                            opacity: 0.5,
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '15px',
                            top: '10px'
                        }}
                        aria-label="Close"
                    >
                        ×
                    </button>
                </Modal.Header>
                <Modal.Body
                    className="text-center"
                    style={{
                        padding: "5px 20px 24px 20px"
                    }}
                >
                    <h5
                        style={{
                            fontSize: "18px",
                            color: "#0D6EFD",
                            marginTop: "10px",
                            marginBottom: "24px",
                            fontWeight: "normal"
                        }}
                    >
                        Are you sure you want to proceed with payment?
                    </h5>
                    <div className="d-flex justify-content-center gap-3">
                        <button
                            className="btn"
                            style={{
                                borderRadius: "100px",
                                width: "120px",
                                border: "1px solid #0D6EFD",
                                color: "#0D6EFD",
                                background: "white"
                            }}
                            onClick={() => setShowConfirmModal(false)}
                            disabled={isProcessing}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn"
                            style={{
                                borderRadius: "100px",
                                width: "120px",
                                border: "1px solid #0D6EFD",
                                color: "#0D6EFD",
                                background: "white"
                            }}
                            onClick={handlePayment}
                            disabled={isProcessing}
                        >
                            {isProcessing ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Processing...</span>
                                </div>
                            ) : 'Confirm'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
                <Modal.Header>
                    <button type="button" className="close" onClick={handleSuccessModalClose}>
                        <img src={closeButton} alt="close" />
                    </button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={successImage} alt="Success" className="mb-4" />
                    <h3>Payment Successful!</h3>
                    <p>Your package has been successfully purchased.</p>
                    <button
                        onClick={handleSuccessModalClose}
                        className="btn btn-primary btn-block"
                    >
                        Continue
                    </button>
                </Modal.Body>
            </Modal>

            {/* Failure Modal */}
            <Modal show={showFailureModal} onHide={handleFailureModalClose} centered>
                <Modal.Header>
                    <button type="button" className="close" onClick={handleFailureModalClose}>
                        <img src={closeButton} alt="close" />
                    </button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={rejectedImage} alt="Failed" className="mb-4" />
                    <h3>Payment Failed</h3>
                    <p>Your payment could not be processed. Please try again.</p>
                    <button
                        onClick={handleFailureModalClose}
                        className="btn btn-primary btn-block"
                    >
                        Try Again
                    </button>
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default PackageOrderSummary;
