// @ts-nocheck

/**
 * Project: spaces
 * File: PageData
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */

import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import * as bootstrap from 'bootstrap';

export interface PageDataContextModel {
    pageTitle?: string;
    setPageTitle: (_title: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void;
    leftComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean;
    setLeftComponent: (_left: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void;
    rightComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean;
    setRightComponent: (_right: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: any) => { },
    setLeftComponent: (_left: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => { },
    setRightComponent: (_right: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean) => { },
});

const PageDataProvider: React.FC = ({ children }) => {
    const [pageTitle, setPageTitle] = useState<any>('');
    const [leftComponent, setLeftComponent] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean>();
    const [rightComponent, setRightComponent] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean>();

    useEffect(() => {
        // Close sidebar when component mounts/updates
        const sidebarPanel = document.getElementById('sidebarPanel');
        if (sidebarPanel) {
            const modal = bootstrap.Modal.getInstance(sidebarPanel);
            if (modal) {
                modal.hide();
            }
        }
    }, [pageTitle]); // This will trigger when page title changes (navigation)

    const value: PageDataContextModel = {
        pageTitle,
        setPageTitle,
        leftComponent,
        setLeftComponent,
        rightComponent,
        setRightComponent,
    };
    return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
    return useContext(PageDataContext);
}

type Props = {
    description?: string;
};

const PageTitle: React.FC<{ children: React.ReactNode; }> = ({ children }) => {
    const { setPageTitle } = usePageData();
    useEffect(() => {
        if (children) {
            setPageTitle(children);
        }
        return () => {
            setPageTitle('');
        };
    }, [children]);

    return <></>;
};

const LeftComponent: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { setLeftComponent } = usePageData();
    useEffect(() => {
        if (children) {
            setLeftComponent(children);
        }
        return () => {
            const sidebarPanel = document.getElementById('sidebarPanel');
            if (sidebarPanel) {
                sidebarPanel.addEventListener('hidden.bs.modal', function () {
                    sidebarPanel.setAttribute('data-bs-dismiss', 'modal');
                });
            }
        };
    }, [children]);
    return <></>;
};

const RightComponent: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { setRightComponent } = usePageData();
    useEffect(() => {
        if (children) {
            setRightComponent(children);
        }
        return () => {
            setRightComponent('');
        };
    }, [children]);
    return <></>;
};

export { LeftComponent, RightComponent, PageTitle, PageDataProvider, usePageData };
