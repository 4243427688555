import React, { useEffect, useState } from "react";
import { BundleMetricData, Package, packageSelectData } from "../../../../data/package-select-data";
import MembershipPlanCard from "../../../../components/bundles/membership-plan-card";
import SERVICES from '../../../../services';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetBundleQuantity } from "../../../../redux/actions/dashboard";
import { toast } from 'react-toastify';
import apiService from '../../../../services/apiService';
import logger from "../../../../utils/logger";

enum BUNDLE_TYPE {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH"
}

const BUNDLE_QUANTITY_DEFAULTS = {
    [BUNDLE_TYPE.LOW]: {
        minUsers: 5,
        maxUsers: 10
    },
    [BUNDLE_TYPE.MEDIUM]: {
        minUsers: 11,
        maxUsers: 19
    },
    [BUNDLE_TYPE.HIGH]: {
        minUsers: 20,
        maxUsers: Number.MAX_SAFE_INTEGER
    },
};

interface BundleTypeAndQuantity {
    bundleType: string;
    quantity: number;
    minUsers?: number;
    maxUsers?: number;
}

const MembershipPlan = () => {
    // @ts-ignore
    const bundleTypeAndQuantities = useSelector((state) => state.dashboard.bundleTypeAndQuantities);

    const history: any = useHistory();
    const dispatch = useDispatch();

    const [bundlePackages, setBundlePackages] = useState<Array<Package>>();
    const [bundleMetrics, setBundleMetrics] = useState<Array<BundleMetricData>>([]);
    const [totalPurchased, setTotalPurchased] = useState(0);
    const [currency, setCurrency] = useState('');
    const [ranFirstTime, setRanFirstTime] = useState(false);
    const [selectedBundleType, setSelectedBundleType] = useState("");
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    useEffect(() => {
        setBundlePackages(packageSelectData.packages);

        const currency = SERVICES.getCurrency();
        setCurrency(currency || '');

        const currentUser = SERVICES.getUser();
        const ownerId = currentUser ? currentUser.id : 0;

        apiService.getBundleMetrics(ownerId)
            .then(res => {
                const responseData = res.data.data;

                if (responseData && Array.isArray(responseData) && responseData.length > 0) {
                    setBundleMetrics(responseData as unknown as BundleMetricData[]);
                }
            }).catch(err => {
                // todo: handle exception
            });
    }, []);

    function resetBundleQuantities() {
        const bundleTypeAndQuantities = bundleMetrics.map(e => {
            const bundle = e.bundle;
            const bundleType = bundle?.bundleType;
            const bundleTypeValue = bundleType?.value;

            const bundleMinUsers = bundle?.minUsers || BUNDLE_QUANTITY_DEFAULTS[bundleTypeValue as BUNDLE_TYPE]?.minUsers || 0;
            const bundleMaxUsers = bundle?.maxUsers || BUNDLE_QUANTITY_DEFAULTS[bundleTypeValue as BUNDLE_TYPE]?.maxUsers || 0;
            return { bundleType: bundleTypeValue, quantity: bundleMinUsers, minUsers: bundleMinUsers, maxUsers: bundleMaxUsers };
        });

        dispatch(resetBundleQuantity(bundleTypeAndQuantities));
    }

    useEffect(() => {
        resetBundleQuantities();
    }, [bundleMetrics]);

    useEffect(() => {
        resetBundleQuantities();
    }, [selectedBundleType]);

    const reCalculateOrderAmount = () => {
        if (bundleTypeAndQuantities && Array.isArray(bundleTypeAndQuantities) && bundleTypeAndQuantities.length > 0) {
            const bundleTypeAndQuantity = bundleTypeAndQuantities.find(bundleTypeAndQuantity => bundleTypeAndQuantity.bundleType === selectedBundleType);
            const bundle = bundleMetrics.find(bundleMetric => bundleMetric.bundle.bundleType.value === selectedBundleType)?.bundle;
            const bundleWithQuantity = {
                ...bundle,
                quantity: bundleTypeAndQuantity?.quantity || 0
            };

            let sum = 0;
            if (bundleWithQuantity) {
                const currentPricing = bundleWithQuantity.pricing;
                const cost = currentPricing ? currentPricing.cost : 0;

                sum += (cost * bundleWithQuantity.quantity);
            }
            setTotalPurchased(sum);
        }
    };

    useEffect(() => {
        reCalculateOrderAmount();
    }, [bundleTypeAndQuantities]);

    const isAllOfTheQuantityZero = () => {
        return selectedBundleType === "";
    };

    const purchaseBundle = async () => {
        if (isProcessingPayment) return;
        setIsProcessingPayment(true);

        const currentUser = SERVICES.getUser();
        const ownerId = currentUser?.id;

        // Find the selected bundle and quantity
        const selectedBundle = bundleTypeAndQuantities.find(
            (typeAndQuantity: BundleTypeAndQuantity) => typeAndQuantity.bundleType === selectedBundleType
        );

        if (!selectedBundle) {
            toast.error("Please select a bundle plan", {
                position: "top-center",
                autoClose: 5000
            });
            setIsProcessingPayment(false);
            return;
        }

        const bundleRequest = {
            ownerId,
            bundleTypeAndQuantityList: [{
                bundleType: selectedBundle.bundleType,
                quantity: selectedBundle.quantity
            }]
        };

        try {
            const res = await apiService.createBundlePurchaseOrder(bundleRequest);
            const responseData = res.data?.data;

            // Store both payment type and source page
            localStorage.setItem('paymentType', 'package');
            localStorage.setItem('paymentSource', 'membership-plan');

            history.push({
                pathname: '/package-order-summary',
                state: {
                    currency,
                    summary: responseData,
                    referer: 'membership-plan'
                }
            });
        } catch (err: any) {
            logger.error("Error creating bundle order:", err);
            toast.error(
                err.response?.data?.message || "Failed to process bundle purchase",
                {
                    position: "top-center",
                    autoClose: 5000
                }
            );
        } finally {
            setIsProcessingPayment(false);
        }
    };

    useEffect(() => {
        localStorage.setItem("paymentSource", "membership-plan");
    }, []);

    return (
        <section className={'membership-plan py-5'}>
            <h3 style={{ fontSize: '1.6rem' }}>Select a plan and the number of seats you require</h3>
            <div className="position-relative d-flex justify-content-between" style={{ marginTop: '50px' }}>
                {
                    bundleMetrics && Array.isArray(bundleMetrics) && bundleMetrics.map((bundleMetric, index) => {
                        return (
                            <MembershipPlanCard
                                selected={selectedBundleType === bundleMetric.bundle.bundleType.value}
                                key={index}
                                bundleMetric={bundleMetric}
                                bundleTypeAndQuantities={bundleTypeAndQuantities}
                                onSelected={() => setSelectedBundleType(bundleMetric.bundle.bundleType.value)}
                            />
                        );
                    })
                }
            </div>
            <div className="row" style={{ marginTop: '100px', display: (bundleMetrics.length > 0) ? 'flex' : 'none' }}>
                <div className="col-6">

                </div>
                <div className="col-6 d-flex justify-content-end">
                    <div className={'me-5'}>
                        <p style={{
                            textDecoration: 'underline',
                            color: '#262626',
                            fontWeight: 600,
                            fontSize: '1.3rem'
                        }}>Total + VAT</p>
                        <p className={'text-primary'}
                            style={{ textDecoration: 'underline', fontWeight: 700, fontSize: '1.4rem' }}>
                            <span>{currency}</span>.<span>{totalPurchased.toLocaleString()}</span>
                        </p>
                    </div>
                    <div className={'d-flex align-items-end'}>
                        <button
                            className="btn btn-primary text-uppercase confirm-order"
                            disabled={isAllOfTheQuantityZero() || isProcessingPayment}
                            onClick={purchaseBundle}
                        >
                            {isProcessingPayment ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Processing...
                                </>
                            ) : (
                                'confirm order'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MembershipPlan;
