import actionTypes from "../actionTypes";
import logger from "../../../utils/logger";

const dispatchUser = (user) => (dispatch) => {
	logger.log("dispatching user", user);
	dispatch({
		type: actionTypes.DISPATCH_USER,
		payload: user,
	});
};

export const user = (user) => (dispatch) => {
	dispatch({
		type: actionTypes.LOGIN_USER,
		payload: user,
	});
};

export const persistUserForBundles = (user) => ({
	type: actionTypes.USER_SESSION_FOR_BUNDLES,
	payload: user
})

export {dispatchUser};
