import { BehaviorSubject } from 'rxjs';
import apiService from '../../../services/apiService';
import logger from "../../../utils/logger";

const userDetailsSubject = new BehaviorSubject({});
const updateUserDetailsSubject = new BehaviorSubject({});

export const userServiceRxJS = {
    userDetailsSubject: userDetailsSubject.asObservable(),
    getUserDetails,
    updateUserDetailsSubject: updateUserDetailsSubject.asObservable(),
    updateUserDetails,
    resetUpdateUserDetailsSubject,
    resetUserDetailsSubject
};

function getUserDetails(id) {
    logger.log("Fetching user details for ID:", id);
    apiService.getUserDetails(id)
        .then(response => {
            logger.log("User details response:", response.data);
            userDetailsSubject.next(response.data);
        })
        .catch(err => {
            logger.error("Error fetching user details:", err);
            const error = {
                status: err.response?.status || 500,
                message: err.response?.data?.message || "Failed to fetch user details"
            };
            userDetailsSubject.next(error);
        });
}

function updateUserDetails(id, payload) {
    logger.log("Updating user details for ID:", id);
    logger.log("Update payload:", payload);

    return apiService.updateUserDetails(id, payload)
        .then(response => {
            logger.log("Update response:", response.data);
            updateUserDetailsSubject.next(response.data);
            return response.data;
        })
        .catch(err => {
            logger.error("Error updating user details:", err);
            const error = {
                status: err.response?.status || 500,
                message: err.response?.data?.message || "Failed to update user details"
            };
            updateUserDetailsSubject.next(error);
            return Promise.reject(error);
        });
}

function resetUpdateUserDetailsSubject() {
    logger.log("Resetting update subject");
    updateUserDetailsSubject.next({});
}

function resetUserDetailsSubject() {
    logger.log("Resetting user details subject");
    userDetailsSubject.next({});
}