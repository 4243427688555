import { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsFillPlayFill, BsCheck, BsChevronDown } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SERVICES from "../../../../services";
import apiService from "../../../../services/apiService";
import logger from "../../../../utils/logger";

interface BundleType {
	ordinal: number;
	value: "LOW" | "MEDIUM" | "HIGH";
	label: string;
}

interface Bundle {
	summary: string;
	id: number;
	bundleType: BundleType;
}

interface Package {
	id: number;
	cost: number;
	currency: string;
	billingPeriod: string;
	bundle: Bundle;
	createdOn: string | null;
	updatedOn: string;
}

const CorporatePage = () => {
	const [view, setView] = useState("Enterprise");
	const [packages, setPackages] = useState<Package[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showVideoModal, setShowVideoModal] = useState(false);

	// Fetch packages on component mount
	useEffect(() => {
		fetchPackages();
	}, []);

	const fetchPackages = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const currentUser = SERVICES.getUser();
			const userId = currentUser ? currentUser.id : 0;
			const response = await apiService.getBundlePricings(userId);

			if (response.data && Array.isArray(response.data.data)) {
				setPackages(response.data.data);
			} else {
				throw new Error("Invalid response format");
			}
		} catch (error) {
			logger.error("Error fetching packages:", error);
			setError("Failed to fetch packages");
		} finally {
			setIsLoading(false);
		}
	};

	// Helper function to get package price by bundle type value
	const getPackagePrice = (type: "LOW" | "MEDIUM" | "HIGH"): number | null => {
		const pkg = packages.find(p => p.bundle.bundleType.value === type);
		return pkg ? pkg.cost : null;
	};

	// Helper function to get package currency by bundle type value
	const getPackageCurrency = (type: "LOW" | "MEDIUM" | "HIGH"): string => {
		const pkg = packages.find(p => p.bundle.bundleType.value === type);
		return pkg ? pkg.currency : 'KES';
	};

	// Helper function to get package summary by bundle type value
	const getPackageSummary = (type: "LOW" | "MEDIUM" | "HIGH"): string => {
		const pkg = packages.find(p => p.bundle.bundleType.value === type);
		return pkg?.bundle.summary || '';
	};

	// Get both prices and summaries
	const enterprisePrice = getPackagePrice('LOW') || 29000;
	const corporatePrice = getPackagePrice('MEDIUM') || 25000;
	const corporatePlusPrice = getPackagePrice('HIGH') || 23000;

	const enterpriseCurrency = getPackageCurrency('LOW') || 'KES';
	const corporateCurrency = getPackageCurrency('MEDIUM') || 'KES';
	const corporatePlusCurrency = getPackageCurrency('HIGH') || 'KES';

	const enterpriseSummary = getPackageSummary('LOW') || 'Tailored for teams of 5-10.';
	const corporateSummary = getPackageSummary('MEDIUM') || 'Tailored for teams of 11-19.';
	const corporatePlusSummary = getPackageSummary('HIGH') || 'Tailored for teams of 20 and above.';

	// const location = useLocation();
	// const queryParams = new URLSearchParams(location.search);
	// const scrollTo = queryParams.get("#faqSection");
	// const targetContainerRef = useRef(null);

	// useEffect(() => {
	// 	if (scrollTo) {
	// 		// Scroll to the specific container
	// 		const container = document.getElementById(scrollTo);
	// 		if (container) {
	// 			container.scrollIntoView({ behavior: 'smooth' });
	// 		}
	// 	}
	// }, [scrollTo]);

	const handleCloseVideoModal = () => {
		setShowVideoModal(false);
	};

	return (
		<div>
			{/* Hero Section */}
			<div
				style={{
					background:
						"linear-gradient(180deg, #C8DBE8 0%, rgba(229, 235, 239, 0) 100%)",
					height: "100%",
					position: "relative",
				}}
			>
				{/* Header Area */}
				<div className="landingpage_header">
					<img
						src="/assets/img/new-logo.svg"
						alt="Spacia"
						width={150}
						onClick={() => window.location.replace("/home")}
						className="header_icon_route"
					/>
					<div style={{ fontSize: "16px", marginLeft: "270px", gap: "20px" }}>
						<NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
						<NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
						<NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
						<a href="/corporate-page#faqSection" style={{ color: "black" }} >FAQ</a>

					</div>
				</div>
				<div
					style={{
						textAlign: "center",
						color: "black",
						lineHeight: "1",
						fontWeight: "800",
						fontFamily: "Montserrat",
						marginTop: "10px",
					}}
				>
					<h1 style={{ fontWeight: "600" }}>INTRODUCING</h1>
					<h1 style={{ fontWeight: "600" }}>SPACIA ENTERPRISE</h1>
				</div>
				<div
					style={{
						marginTop: "30px",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<button
						onClick={() => setShowVideoModal(true)}
						style={{
							display: "flex",
							alignItems: "center",
							padding: "15px 30px",
							border: "none",
							borderRadius: "100px",
							backgroundColor: "#00000033",
							cursor: "pointer",
							color: "#FFFFFF",
							boxShadow: "0px 20px 45px rgba(110, 146, 170, 0.2)",
						}}
					>
						<BsFillPlayFill
							style={{
								color: "#FFFFFF",
								backgroundColor: "#FFFFFF1A",
								padding: "5px",
								borderRadius: "100px",
								fontSize: "30px",
								marginRight: "10px",
							}}
						/>
						<p>Watch Demo</p>
					</button>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						margin: "30px 0px",
					}}
				>
					<img
						src="/assets/img/backgrounds/men-with-laptops-only.svg"
						alt="men with laptops"
					// width={"900px"}
					/>
				</div>
				<img
					src="/assets/img/enterprise/telegram-kite.svg"
					alt="telegram kite"
					style={{ position: "absolute", bottom: "15rem", left: "0" }}
				/>
				<img
					src="/assets/img/enterprise/dollar-kite.svg"
					alt="telegram kite"
					style={{ position: "absolute", bottom: "20rem", right: "0" }}
				/>
			</div>
			{/* Spacia plans */}
			<div style={{ padding: "40px 40px 0px 40px" }}>
				<div
					style={{ height: "400px", padding: "40px" }}
					className="enterprise_your_business"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							fontFamily: "Poppins",
						}}
					>
						<h6
							style={{
								backgroundColor: "#fff",
								borderRadius: "43px",
								padding: "5px 16px",
								fontWeight: "500",
							}}
						>
							Plans Tailored for your business
						</h6>
						<div>
							<button
								style={{
									backgroundColor: view === "Enterprise" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
									marginRight: "20px",
								}}
								onClick={() => {
									setView("Enterprise");
								}}
							>
								Enterprise
							</button>
							<button
								style={{
									backgroundColor: view === "Corporate" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
									marginRight: "20px",
								}}
								onClick={() => {
									setView("Corporate");
								}}
							>
								Corporate
							</button>
							<button
								style={{
									backgroundColor:
										view === "Corporate Plus" ? "#00B6F0" : "#fff",
									borderRadius: "43px",
									padding: "5px 16px",
								}}
								onClick={() => {
									setView("Corporate Plus");
								}}
							>
								Corporate Plus
							</button>
						</div>
					</div>
					<div
						style={{
							color: "white",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							width: "100%",
							marginTop: "10rem",
							fontFamily: "Montserrat",
						}}
					>
						<h1>Multiple work locations</h1>
						<h1>One membership</h1>
					</div>
				</div>
				<div
					style={{
						height: "400px",
						backgroundColor: "black",
						color: "white",
						fontFamily: "Montserrat",
						padding: "50px",
						borderRadius: "0px 0px 20px 20px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					{/* Enterprise */}
					{view === "Enterprise" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Enterprise
								</h1>
								<h6>{enterpriseSummary}</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									{enterpriseCurrency} {enterprisePrice}
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 14% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>10 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
							</div>
						</div>
					)}
					{/* Corporate */}
					{view === "Corporate" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Corporate
								</h1>
								<h6>{corporateSummary}</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									{corporateCurrency} {corporatePrice}
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 21% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>15 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Dedicated High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Business Address</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Mail Handling Services</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Free Printing & Scanning</p>
								</div>
							</div>
						</div>
					)}
					{/* Corporate Plus */}
					{view === "Corporate Plus" && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<div>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									Corporate Plus
								</h1>
								<h6>{corporatePlusSummary}</h6>
								<h1 style={{ fontWeight: "900", margin: "20px 0px", fontSize: "55px" }}>
									{corporatePlusCurrency} {corporatePlusPrice}
									<span style={{ fontSize: "16px", fontWeight: "500" }}>
										/month per user{" "}
									</span>
								</h1>
								<h3
									style={{
										color: "#00B6F0",
										marginBottom: "20px",
										fontSize: "27px",
									}}
								>
									Up to 27% savings
								</h3>
								<button
									style={{
										backgroundColor: "#00B6F0",
										color: "white",
										// boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
										borderRadius: "50px",
										padding: "15px 48px",
										lineHeight: "16px",
										fontSize: "16px",
									}}
									onClick={() => window.location.assign("/package-signup")}
								>
									Get Started
								</button>
							</div>
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>
										Unlimited{" "}
										<span style={{ color: "#00B6F0" }}>
											Dedicated Co-Working Space
										</span>
									</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>

									<p>Private Office Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>20 Hour Meeting Room Access</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Food & Beverage Discounts</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Dedicated High speed Wi-Fi</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Networking Events</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Business Address</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "15px",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Mail Handling Services</p>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<BsCheck
										style={{
											backgroundColor: "#00B6F0",
											padding: "4px",
											fontSize: "25px",
											borderRadius: "100px",
											marginRight: "10px",
										}}
									/>
									<p>Free Printing & Scanning</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* Sign up today */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "70px 80px",
					fontFamily: "Montserrat",
				}}
			>
				<h2
					style={{
						marginBottom: "30px",
						fontWeight: "500",
						color: "#011A49",
						fontSize: "37px",
					}}
				>
					Sign up today and find a{" "}
					<span style={{ color: "#3078FF" }}>space</span> to call home
				</h2>
				<p style={{ fontSize: "14px", color: "#011A49" }}>
					Buy a pass to work from hotels, cafes or co-working spaces OR book
					private meeting rooms,
				</p>
				<p style={{ fontSize: "14px", color: "#011A49" }}>
					offices or creative spaces across our cities.
				</p>
				<div style={{ margin: "40px 0px" }}>
					<button
						style={{
							backgroundColor: "#3078FF",
							color: "white",
							boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.15)",
							borderRadius: "50px",
							padding: "10px 30px",
							fontSize: "14px",
							fontWeight: "500",
						}}
						onClick={() => window.location.assign("/package-signup")}
					>
						Get Started
					</button>
				</div>
			</div>
			{/* FAQ */}
			<section
				id="faqSection"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "70px 80px",
					fontFamily: "Montserrat",
					padding: "0px 100px 100px 100px",
				}}
			>
				<p style={{ fontWeight: "500", color: "#3078FF" }}>
					HAVE ANYTHING TO ASK
				</p>
				<h1
					style={{ color: "#011A49", fontSize: "40px", marginBottom: "40px" }}
				>
					Frequently asked questions
				</h1>

				{/* SPACIA HOST FAQs */}
				<div style={{ width: "100%", marginBottom: "40px" }}>
					<h2 style={{ color: "#011A49", fontSize: "24px", marginBottom: "20px" }}>Spacia Host FAQs</h2>
					
					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>How do I get the payment?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>All payouts will be made within 48hrs of guest check-in unless otherwise agreed.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>What is the cancellation policy?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>In general, if a client cancels a reservation, the amount paid to Host is determined by the cancellation policy that applies to that reservation.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>How often will the availability schedules be updated? Can we do the updates ourselves?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>The host is in control of listing and can set prices, availability, and terms of use for their space.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>What is the cost of listing on Spacia?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Listing on Spacia is free for the first year of use. Spacia retains a 15% commission on the amount paid for reservation. (variable commission structure, check with..)</p>
							</AccordionDetails>
						</Accordion>
					</div>
				</div>

				{/* SPACIA USERS FAQs */}
				<div style={{ width: "100%", marginBottom: "40px" }}>
					<h2 style={{ color: "#011A49", fontSize: "24px", marginBottom: "20px" }}>Spacia Users FAQs</h2>
					
					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Do I have to pay to use the site?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia Africa has different categories and packages catered to enterprise users as well as all who would want to have access to Spacia spaces, with plans based on usage from basic, to plus to premium.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Can I use Spacia as an individual away from my organisation's spaces?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia's message of work anywhere anytime is fundamental to our business model, and as such we cater for individual work wherever they feel comfortable across all our spaces.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Am I limited in terms of how far I can work or how many times I can access a space?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia provides access to all our reserved as well as all spaces that our hosts have made open or available, and any amenity on the platform can be utilised.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Will I have access to Spacia events and all such promotions?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia events are accessible to all members of our platform, enterprise or otherwise, and the only requirement to fulfil is be under the Spacia package that caters to the aforementioned.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Can I upgrade or downgrade my membership package?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia offers the flexibility to not only improve your membership situation but also to switch to a package you feel comfortable spending on, depending on Spacia's terms and conditions of service with regards to cancellation.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>How is my data used by Spacia Africa?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Spacia takes usage of your data very seriously, and the collected information has the benefit of helping Spacia know where and when the majority of our efforts towards producing a better product should be focused.</p>
							</AccordionDetails>
						</Accordion>
					</div>
				</div>

				{/* SPACIA PRICING PACKAGES FAQs */}
				<div style={{ width: "100%" }}>
					<h2 style={{ color: "#011A49", fontSize: "24px", marginBottom: "20px" }}>Spacia Pricing Packages FAQs</h2>
					
					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>What are the different pricing packages offered to clients?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>The three pricing packages offered are Enterprise, Corporate, and Corporate Plus. Each package has different levels of offerings as well as different quantities of people it can serve.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Can clients book multiple locations in a month?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>For the Enterprise package, clients are expected to pick a primary location and work from it for the duration of the month. However, for Corporate and Corporate Plus, these packages have larger numbers of users who may need more than one location to work at during the month. Corporate allows for 1 primary location and 1 secondary location, while Corporate Plus allows for 1 primary location and 3 secondary locations, with an extra secondary location if the number of users exceeds 20.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>What is the definition of a primary location?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>A primary location is where the user/client envisions themselves working, for at least 60% of the working days and is where they would be for the majority of that month.</p>
							</AccordionDetails>
						</Accordion>
					</div>

					<div style={{ width: "100%", marginBottom: "15px" }}>
						<Accordion>
							<AccordionSummary expandIcon={<BsChevronDown />}>
								<p>Can I upgrade to a higher package once my team size increases?</p>
							</AccordionSummary>
							<AccordionDetails>
								<p style={{ color: "gray" }}>Yes, movement between packages will be seamless to accommodate the clients growing needs for space, as well as adjust to smaller team sizes when necessary by the provision of multiple packages.</p>
							</AccordionDetails>
						</Accordion>
					</div>
				</div>
			</section>
			{showVideoModal && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 1000,
					}}
					onClick={handleCloseVideoModal}
				>
					<div
						style={{
							position: "relative",
							width: "80%",
							maxWidth: "800px",
							aspectRatio: "16/9",
							backgroundColor: "#000",
						}}
						onClick={(e) => e.stopPropagation()}
					>
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube.com/embed/PR4YWa3SRQ4?autoplay=1"
							title="Spacia Enterprise Demo"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
						<button
							onClick={handleCloseVideoModal}
							style={{
								position: "absolute",
								top: "-40px",
								right: 0,
								backgroundColor: "transparent",
								border: "none",
								color: "#fff",
								fontSize: "24px",
								cursor: "pointer",
							}}
						>
							✕
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default CorporatePage;
