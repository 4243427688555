import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toAbsoluteUrl } from "../helpers";
import {
  CloseCircle,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  MenuOutline,
} from "react-ionicons";
import { Link, useHistory, NavLink } from "react-router-dom";
import SERVICES from "../../services";
import apiService from "../../services/apiService";
import { User } from "../../utils/interfaces";
import { Enquiry } from "../../types";
import { Constants } from "../../utils/constants";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../footer/footer";

export type HostLandingLayoutProps = {
  children?: React.ReactNode;
  alternateHeader?: React.ReactNode;
  alternateColors?: boolean;
};

export default function HostLandingLayout({
  children, alternateColors, alternateHeader
}: HostLandingLayoutProps) {
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
  const [isEmptyLastname, setIsEmptyLastname] = useState(false);
  const [isEmptyPhoneNumber, setIsEmptyPhoneNumber] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyMessage, setIsEmptyMessage] = useState(false);

  const isLoggedIn = (user: User) => {
    return user != null && Object.keys(user).length !== 0;
  };

  const redirectBasedOnRole = () => {
    const currentUser = SERVICES.getUser() as User;
    let destination = "/startBooking";

    if (!isLoggedIn(currentUser)) {
      destination = "/signin";
    } else {
      const role = currentUser.role;

      switch (role) {
        case 'ROLE_ADMINISTRATOR':
          destination = "/dashboard";
          break;

        case 'ROLE_SUBSCRIBER_OWNER':
          destination = "/startBooking";
          break;

        default:
          destination = "/properties";
      }
    }

    return destination;
  };

  const goToLogin = () => {
    history.push('/signin');
  };

  const goToSignUp = () => {
    history.push('/signup');
  };

  const goToAboutPage = () => {
    history.push('/about');
  };

  // const goToHome = () => {
  //   history.push('/home');
  // };

  function hasText(text: string) {
    return text != null && text !== '';
  }

  useEffect(() => {
    if (hasText(firstName)) {
      setIsEmptyFirstname(false);
    }
    if (hasText(lastName)) {
      setIsEmptyLastname(false);
    }
    if (hasText(email)) {
      setIsEmptyEmail(false);
    }
    if (hasText(msisdn)) {
      setIsEmptyPhoneNumber(false);
    }
    if (hasText(message)) {
      setIsEmptyMessage(false);
    }
  }, [firstName, lastName, email, msisdn, message]);

  const areFieldsOkay = () => {
    const requiredFields = [firstName, lastName, email, msisdn, message];
    // is any of the fields empty
    const anyEmpty = requiredFields.some(field => !field);
    if (anyEmpty) {
      if (!hasText(firstName)) {
        setIsEmptyFirstname(true);
      }
      if (!hasText(lastName)) {
        setIsEmptyLastname(true);
      }
      if (!hasText(email)) {
        setIsEmptyEmail(true);
      }
      if (!hasText(msisdn)) {
        setIsEmptyPhoneNumber(true);
      }
      if (!hasText(message)) {
        setIsEmptyMessage(true);
      }

      return false;
    } else {
      return true;
    }
  };

  const submitEnquiry = (event: any): void => {
    event.preventDefault();

    if (!areFieldsOkay()) return;

    const fullName = `${firstName} ${lastName}`;

    const enquiry: Enquiry = {
      name: fullName,
      email,
      phoneNumber: msisdn,
      message
    };

    apiService.submitEnquiry(enquiry)
      .then(res => {
        if (res.status === 200) {
          toast.success('We have received your submission',
            { theme: 'colored' });

          setFirstName('');
          setLastName('');
          setEmail('');
          setMsisdn('');
          setMessage('');
        }
      })
      .catch(err => {
        toast.error('Sorry, error submitting your information', { theme: 'colored' });
      });
  };

  const textColor = alternateColors && alternateColors ? "text-white" : "text-black";

  useEffect(() => {
    const capsule = document.querySelector("#appCapsule");
    if (alternateColors) {
      capsule && capsule.classList.remove("add-safe-space");
    }
  }, []);
  return (
    <div id="appCapsule">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ minWidth: '350px' }}
      />
      <div className={"d-flex flex-column"}>
        <div className={"d-flex flex-column  " + `${alternateColors ? "responsive-banner" : ""}`}
          style={alternateColors ? {
            background: `url("${toAbsoluteUrl("/img/landing-desktop-background.jpeg")}")`,
          } : {}}>
          <header
            className={
              "d-flex flex-row justify-content-between align-items-center px-3  px-md-5 px-lg-5"
            }
          >
            <Link to="/home">
              <img
                src="/assets/img/new-logo.svg"
                alt="Spacia"
                width={150}
              // onClick={() => window.location.replace("/home")}
              />
            </Link>

            <div
              className={
                "desktop-show d-flex flex-row flex-fill justify-content-between align-items-center ms-5 ps-5"
              }
            >
              <nav style={{ fontSize: "16px", display: "flex", gap: "30px", color: "black", fontFamily: "Montserrat", }}>
                <NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
                <NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
                <NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
                <a href="/corporate-page#faqSection" style={{ color: "black" }} >FAQ</a>

              </nav>
              <div>
                {alternateHeader ? <></> : <>
                  <Link
                    to={"/signin"}
                    className={"ms-2 me-4 link"}
                    style={{ fontSize: "clamp(14px,1.7vw,15px)" }}
                  >
                    <span className={" text-uppercase " + textColor}>Login</span>
                  </Link>
                  <Link
                    className={"active btn btn-primary me-4 button text-uppercase "}
                    style={{ borderRadius: "100rem", fontSize: "clamp(14px,1.7vw,15px)" }}
                    // style={{ }}
                    to={"/signup"}
                  >
                    Sign Up
                  </Link></>}
              </div>
            </div>
            <a
              href="/#"
              className="headerButton ms-auto me-1 mobile-show"
              data-bs-toggle="modal"
              data-bs-target="#sidebarPanel"
            >
              <MenuOutline color={"#000000"} title={""} />
            </a>
          </header>
          {alternateHeader}

        </div>
        {children}

        <section
          className={"section px-lg-5 px-md-5 px-sm-3 py-5 row position-relative overflow-hidden"}
          style={{
            background: "linear-gradient(135deg, #469FF3 0%, #2E8BE0 100%)",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center"
          }}
        >
          {/* Decorative background circles */}
          <div className="position-absolute" style={{
            width: "400px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            top: "-100px",
            right: "-100px",
            zIndex: 0
          }} />
          <div className="position-absolute" style={{
            width: "300px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "50%",
            bottom: "-50px",
            left: "-50px",
            zIndex: 0
          }} />

          <motion.div
            className={"col-lg-6 position-relative"}
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <h2
              className={"mt-4 pb-2 mb-4"}
              style={{
                fontSize: "clamp(36px, 5vw, 56px)",
                fontWeight: 700,
                color: 'black',
                lineHeight: 1.2
              }}
            >
              Available <span style={{ color: "white" }}>Now</span>
            </h2>

            <p
              className={"pb-5 mb-5"}
              style={{
                fontSize: "clamp(16px, 2vw, 20px)",
                lineHeight: 1.6,
                color: "#18191F",
                maxWidth: "600px"
              }}
            >
              Search, compare & book flexible workspaces, accommodation and other
              lifestyle services from the convenience of the Spacia app
            </p>

            <div className={"me-auto"}>
              <div className={"px-2"}>
                <h3 className={"fontsize-sub-footer mb-3 mobile-show"} style={{ fontWeight: 500 }}>
                  Get the App
                </h3>
                <div className={"d-flex flex-row gap-3 pb-5"}>
                  <motion.a
                    href="https://play.google.com/store/apps/details?id=com.lucid_array.spacia.individual&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"btn py-2 px-3"}
                    style={{
                      backgroundColor: "#000000",
                      minWidth: "160px",
                      transition: "transform 0.2s ease"
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className={"d-flex flex-row align-items-center"}>
                      <img
                        decoding={"async"}
                        className={"me-2"}
                        alt={"g-play logo"}
                        src={toAbsoluteUrl("/img/google-play.png")}
                        style={{ width: "24px", height: "24px" }}
                      />
                      <div className={"d-flex flex-column justify-content-between"}>
                        <span className={"text-uppercase fs-10 fw-bold text-white"}>
                          Get it on
                        </span>
                        <span className={"text-capitalize fw-bold text-white"} style={{ fontSize: "16px" }}>
                          Google Play
                        </span>
                      </div>
                    </div>
                  </motion.a>

                  <motion.a
                    href="https://apps.apple.com/gh/app/spacia-work-stay-play/id6739746297"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"btn py-2 px-3"}
                    style={{
                      backgroundColor: "#000000",
                      minWidth: "160px",
                      transition: "transform 0.2s ease"
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className={"d-flex flex-row align-items-center"}>
                      <img
                        decoding={"async"}
                        className={"me-2"}
                        alt={"app-store logo"}
                        src={toAbsoluteUrl("/img/apple-logo.png")}
                        style={{ width: "24px", height: "24px" }}
                      />
                      <div className={"d-flex flex-column justify-content-between"}>
                        <span className={"text-white fs-10 fw-bold"}>
                          Download on the
                        </span>
                        <span className={"text-capitalize fw-bold text-white"} style={{ fontSize: "16px" }}>
                          App Store
                        </span>
                      </div>
                    </div>
                  </motion.a>
                </div>
              </div>
            </div>
          </motion.div>

          <div className="col-lg-6 position-relative" style={{ height: "100vh" }}>
            <motion.div
              className="sticky-top"
              style={{
                top: 0,
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden"
              }}
            >
              <div className="position-relative" style={{
                width: "fit-content",
                height: "fit-content",
                transform: "scale(1.2)",
                marginLeft: "80px"
              }}>
                <motion.div
                  style={{
                    position: "absolute",
                    left: "-40px",
                    top: "40px",
                    perspective: "1000px",
                    zIndex: 1
                  }}
                  initial={{ opacity: 0, rotateY: -20 }}
                  whileInView={{
                    opacity: 1,
                    rotateY: 0,
                    transition: { duration: 0.8, ease: "easeOut" }
                  }}
                  viewport={{ once: true, margin: "-100px" }}
                >
                  <img
                    src={toAbsoluteUrl("/img/phone-mockup-1.png")}
                    alt="Spacia App Screenshot 1"
                    style={{
                      width: "500px",
                      height: "auto",
                      filter: "drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2))",
                      transform: "perspective(1000px) rotateY(10deg)"
                    }}
                  />
                </motion.div>

                <motion.div
                  style={{
                    position: "relative",
                    marginLeft: "160px",
                    perspective: "1000px",
                    zIndex: 2
                  }}
                  initial={{ opacity: 0, rotateY: 20 }}
                  whileInView={{
                    opacity: 1,
                    rotateY: 0,
                    transition: { duration: 0.8, ease: "easeOut", delay: 0.2 }
                  }}
                  viewport={{ once: true, margin: "-100px" }}
                >
                  <img
                    src={toAbsoluteUrl("/img/phone-mockup-2.png")}
                    alt="Spacia App Screenshot 2"
                    style={{
                      width: "500px",
                      height: "auto",
                      filter: "drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2))",
                      transform: "perspective(1000px) rotateY(-10deg)"
                    }}
                  />
                </motion.div>
              </div>
            </motion.div>
          </div>

        </section>
        <section
          className={"section px-lg-5 mb-lg-5  px-md-5 pt-3 background-size-position row"}
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/img/diverse-women-pc.jpeg"
            )}),linear-gradient(89.83deg, rgb(255, 255, 255) 10.24%, rgb(255, 255, 255) 10.25%, rgba(255, 255, 255, 0) 99.55%)`,
            height: "min(100vh,901px)"
          }}
        >
          <div
            className={"col-lg-6 py-lg-5"}
          // style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
          >
            <h1
              className={"text-start me-auto mb-lg-4"}
              style={{
                fontSize: "25px",
                lineHeight: "53px",
                // fontFamily: "Monserrat",
                fontWeight: 500,
              }}
            >
              Get in touch with our Sales Team
            </h1>
            <div className={"d-flex flex-row w-100 justify-content-between"}>
              <div className="form-group basic  me-lg-3">
                <div className="input-wrapper">
                  <input
                    type="text"
                    // className="form-control floating-input form-label-contact"
                    className={isEmptyFirstname ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                    id="firstName"
                    placeholder=" "
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    style={{
                      background: "#F9F9F9",
                      border: "1px solid #E6E6E6",
                    }}
                  />
                  <label style={{ color: "#737373", background: "rgba(0,0,0,0)" }} className="floating-label  form-label-contact" htmlFor="firstName">
                    First Name
                  </label>
                  <i className="clear-input">
                    <CloseCircle color={"#00000"} title={""} />
                  </i>
                  <div className="invalid-feedback">This field can't be empty</div>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <input
                    type="text"
                    className={isEmptyLastname ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                    id="lastName"
                    placeholder=" "
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    style={{
                      background: "#F9F9F9",
                      border: "1px solid #E6E6E6",
                    }}
                  />
                  <label style={{ color: "#737373", background: "rgba(0,0,0,0)" }} className="floating-label  form-label-contact" htmlFor="lastName">
                    Last Name
                  </label>
                  <i className="clear-input">
                    <CloseCircle color={"#00000"} title={""} />
                  </i>
                  <div className="invalid-feedback">This field can't be empty</div>
                </div>
              </div>
            </div>
            <div className={"d-flex flex-row w-100 justify-content-between"}>
              <div className="form-group basic  me-lg-3 ">
                <div className="input-wrapper">
                  {/*<span className="did-input-group-prepend">*/}
                  {/*	<div className="did-input-group-text">*/}
                  {/*		<img*/}
                  {/*			src={toAbsoluteUrl("/img/ghana.svg")}*/}
                  {/*			alt={"gh"}*/}
                  {/*			width={15}*/}
                  {/*		/>{" "}*/}
                  {/*		&nbsp; +233*/}
                  {/*	</div>*/}
                  {/*</span>*/}
                  <input
                    type="number"
                    min={0}
                    minLength={9}
                    maxLength={10}
                    className={isEmptyPhoneNumber ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                    id="phone"
                    placeholder=" "
                    value={msisdn}
                    onChange={e => setMsisdn(e.target.value)}
                    style={{
                      background: "#F9F9F9",
                      border: "1px solid #E6E6E6",
                    }}

                  />
                  <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact" htmlFor="phone">
                    Phone Number
                  </label>
                  <i className="clear-input">
                    <CloseCircle color={"#00000"} title={""} />
                  </i>
                  <div className="invalid-feedback">Kindly enter your phone number</div>
                </div>
                {/*<div className="input-info">e.g 555xxxxxx</div>*/}
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <input
                    type="email"
                    className={isEmptyEmail ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                    id="email1"
                    placeholder=" "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{
                      background: "#F9F9F9",
                      border: "1px solid #E6E6E6",
                    }}
                  />
                  <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact form-label-email" htmlFor="email1">
                    E-mail
                  </label>
                  <i className="clear-input">
                    <CloseCircle color={"#00000"} title={""} />
                  </i>
                  <div className="invalid-feedback">Please enter your email address</div>
                </div>
              </div>
              {/*<div className="form-group basic">*/}
              {/*  <div className="input-wrapper">*/}
              {/*    <select*/}
              {/*        className="form-label-contact form-label form-control floating-select"*/}
              {/*        id={"availability"}*/}
              {/*        style={{background: "#F9F9F9",*/}
              {/*          border:"1px solid #E6E6E6",color: "#737373"}}*/}
              {/*    >*/}
              {/*      <option value="4:00 Available">4:00 Available</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="form-group basic">
              <div className="input-wrapper">
                <textarea
                  // className=" ps-1 pt-2 form-control floating-input form-label-contact"
                  className={isEmptyMessage ? "ps-1 pt-2 form-control is-invalid floating-input form-label-contact" : "ps-1 pt-2 form-control floating-input form-label-contact"}
                  id="message"
                  placeholder=" "
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  style={{
                    background: "#F9F9F9", height: 140,
                    border: "1px solid #E6E6E6",
                  }}
                />
                <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact" htmlFor="message">
                  Message
                </label>
                <i className="clear-input">
                  <CloseCircle color={"#00000"} title={""} />
                </i>
                <div className="invalid-feedback">Message field cannot be empty</div>
              </div>
            </div>

            <div className="mt-4 w-75 me-auto">
              <button
                type="button"
                className={
                  "btn btn-primary btn-block btn-lg active mb-4 w-50  me-auto"
                }
                style={{ borderRadius: "50rem" }}
                onClick={submitEnquiry}
              >
                Book Appointment
              </button>
            </div>
          </div>
          <div className={"col-lg-6"} />
        </section>
        {/*Footer*/}
        <Footer />
      </div>
    </div>
  );
}
