import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { BsFillPlayFill, BsCheck, BsChevronDown } from "react-icons/bs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SERVICES from "../../../services";
import apiService from "../../../services/apiService";
import logger from "../../../utils/logger";
import { toAbsoluteUrl } from "../../../layout/helpers";
import { PageTitle, LeftComponent } from "../../../layout/PageData";
import { EllipsisVertical } from "react-ionicons";
import "./styles.scss";

interface BundleType {
  ordinal: number;
  value: "LOW" | "MEDIUM" | "HIGH";
  label: string;
}

interface Bundle {
  summary: string;
  id: number;
  bundleType: BundleType;
}

interface Package {
  id: number;
  cost: number;
  currency: string;
  billingPeriod: string;
  bundle: Bundle;
  createdOn: string | null;
  updatedOn: string;
}

const CorporatePageMobile = () => {
  const [view, setView] = useState("Enterprise");
  const [packages, setPackages] = useState<Package[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const currentUser = SERVICES.getUser();
      const userId = currentUser?.id || 0;
      const response = await apiService.getBundlePricings(userId);
      if (response.data?.data) {
        setPackages(response.data.data);
      }
    } catch (error) {
      logger.error("Error fetching packages:", error);
      setError("Failed to load packages. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const renderFeatures = (packageType: string) => {
    const features = {
      'LOW': [
        'Unlimited Dedicated Co-Working Space',
        '10 Hour Meeting Room Access',
        'High speed Wi-Fi',
        'Food & Beverage Discounts',
        'Networking Events'
      ],
      'MEDIUM': [
        'Unlimited Dedicated Co-Working Space',
        '20 Hour Meeting Room Access',
        'High speed Wi-Fi',
        'Food & Beverage Discounts',
        'Networking Events',
        'Access to Business Center',
        'Printing Credits'
      ],
      'HIGH': [
        'Private Office Space',
        'Unlimited Meeting Room Access',
        'High speed Wi-Fi',
        'Food & Beverage Discounts',
        'Networking Events',
        'Access to Business Center',
        'Unlimited Printing',
        'Dedicated Account Manager',
        'Custom Branding Options'
      ]
    };

    const packageFeatures = features[packageType as keyof typeof features] || features['LOW'];

    return (
      <div className="features-container">
        {packageFeatures.map((feature, index) => (
          <div key={index} className="feature-item">
            <BsCheck className="check-icon" />
            <span>{feature}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderPackages = () => {
    if (isLoading) return <div className="loading">Loading packages...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
      <div className="packages-container">
        {packages.map((pkg) => (
          <div key={pkg.id} className="package-card">
            <div className="package-header">
              <h3>{pkg.bundle.bundleType.label} Package</h3>
              <div className="price">
                <span className="currency">{pkg.currency}</span>
                <span className="amount">{pkg.cost}</span>
                <span className="period">/{pkg.billingPeriod}</span>
              </div>
            </div>
            <div className="package-content">
              <p>{pkg.bundle.summary}</p>
              {renderFeatures(pkg.bundle.bundleType.value)}
              <Link
                to="/signup"
                className="get-started-btn"
              >
                Get Started
              </Link>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderFAQs = () => (
    <div className="faqs-container">
      <Accordion
        expanded={expandedAccordion === 'panel1'}
        onChange={handleAccordionChange('panel1')}
      >
        <AccordionSummary expandIcon={<BsChevronDown />}>
          What is Spacia for Business?
        </AccordionSummary>
        <AccordionDetails>
          Spacia for Business is our corporate solution that provides flexible workspace access for teams of all sizes.
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedAccordion === 'panel2'}
        onChange={handleAccordionChange('panel2')}
      >
        <AccordionSummary expandIcon={<BsChevronDown />}>
          How does billing work?
        </AccordionSummary>
        <AccordionDetails>
          We offer flexible billing options including monthly and annual plans. All plans are billed in advance.
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedAccordion === 'panel3'}
        onChange={handleAccordionChange('panel3')}
      >
        <AccordionSummary expandIcon={<BsChevronDown />}>
          Can I customize my plan?
        </AccordionSummary>
        <AccordionDetails>
          Yes, we offer customizable plans to meet your specific needs. Contact our sales team for more information.
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <div className="corporate-page-mobile">
      <PageTitle>Corporate</PageTitle>
      <LeftComponent>
        <div
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#sidebarPanel"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <EllipsisVertical color={"#666"} height="24px" width="24px" />
        </div>
      </LeftComponent>

      <section className="hero-section">
        <img
          src={toAbsoluteUrl("/assets/img/spacia/web/spacia_logo.png")}
          alt="Spacia Logo"
          className="logo"
        />
        <h1>Spacia for Business</h1>
        <p>Flexible workspace solutions for your entire team</p>
        <button className="watch-video-btn" onClick={() => setShowVideoModal(true)}>
          <BsFillPlayFill /> Watch Video
        </button>
      </section>

      <section className="packages-section">
        {/* <div className="view-toggle">
          <button
            className={view === "Enterprise" ? "active" : ""}
            onClick={() => setView("Enterprise")}
          >
            Enterprise
          </button>
          <button
            className={view === "Teams" ? "active" : ""}
            onClick={() => setView("Teams")}
          >
            Teams
          </button>
        </div> */}
        {renderPackages()}
      </section>

      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        {renderFAQs()}
      </section>

      {showVideoModal && (
        <div className="video-modal" onClick={() => setShowVideoModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-btn" onClick={() => setShowVideoModal(false)}>×</button>
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/watch?v=PR4YWa3SRQ4"
                title="Spacia for Business"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorporatePageMobile;
