import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PageTitle } from '../../../layout/PageData';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../layout/helpers";
import orderSummaryImage from '../../../assets/img/png/order-summary-image.png';
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import successImage from '../../../assets/img/png/success-with-bubbles.png';
import rejectedImage from '../../../assets/img/png/rejected-image.png';
import SERVICES from '../../../services';
import apiService from '../../../services/apiService';
import aesEcb from "aes-ecb";
import { Location } from "react-ionicons";
import 'react-toastify/dist/ReactToastify.css';
import logger from "../../../utils/logger";

function generateKey(keyLength: number) {
    const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

const BookingOrderSummary: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const { listing, order, user, startDate, endDate } = location.state || {};

    const [currency, setCurrency] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorizationUrl, setAuthorizationUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [spaceType, setSpaceType] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [total, setTotal] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [sseInstance, setSseInstance] = useState<EventSource | null>(null);

    useEffect(() => {
        // Validate required data from location state
        if (!listing || !order || !startDate || !endDate) {
            logger.error('Missing required data in location state:', { listing, order, startDate, endDate });
            toast.error('Missing booking information. Please try again.', {
                position: "top-right",
                theme: "colored"
            });
            history.goBack();
            return;
        }

        // Get currency from user object passed in location state
        const userCurrency = user?.country?.currency || 'KES';
        setCurrency(userCurrency);

        const total = order ? order.total : 0;
        const subCategory = listing ? listing.subCategory : '';
        const packagePlan = user?.packagePlan;
        const packageUsages = packagePlan?.packageUsages;
        const firstIndex = (packageUsages && packageUsages.length > 0) ? packageUsages[0] : null;
        const accessDuration = firstIndex?.accessDuration;
        const duration = accessDuration ? accessDuration.duration : 0;

        setTotal(total);
        setSpaceType(subCategory);
        setQuantity(duration);

        logger.log('Booking order summary initialized with:', {
            startDate,
            endDate,
            total,
            subCategory,
            currency: userCurrency
        });
    }, [listing, order, user, startDate, endDate]);

    useEffect(() => {
        return () => {
            cleanupSSE();
        };
    }, []);

    const cleanupSSE = () => {
        if (sseInstance) {
            logger.log('Closing SSE connection');
            sseInstance.close();
            setSseInstance(null);
        }
    };

    const observePaymentStatusCallBack = (invoiceToken: any, callBackurl: any) => {
        cleanupSSE();

        logger.log("callBackurl from paystack is:", callBackurl);
        const userId = user ? user.id : 0;
        const modifiedCallBackUrl = `${callBackurl}%2B&userId=${userId}`;

        const sse = new EventSource(modifiedCallBackUrl);
        setSseInstance(sse);

        // Connection timeout
        const timeoutId = setTimeout(() => {
            logger.log('Payment timeout - closing SSE');
            cleanupSSE();
            setErrorMsg("Payment session expired. Please try again.");
            setShowFailureModal(true);
        }, 300000); // 5 minutes timeout

        // Track connection state
        let isConnected = false;

        sse.addEventListener("open", () => {
            logger.log("SSE connection opened");
            isConnected = true;
        });

        sse.addEventListener("error", (e: any) => {
            logger.log("SSE error:", e);

            // Check if the connection was established and then lost
            if (isConnected && e.target.readyState === EventSource.CLOSED) {
                clearTimeout(timeoutId);
                cleanupSSE();
                setErrorMsg("Connection lost. Please check your internet connection and try again.");
                setShowFailureModal(true);
            }

            // Check if connection failed to establish
            if (!isConnected && e.target.readyState === EventSource.CLOSED) {
                clearTimeout(timeoutId);
                cleanupSSE();
                setErrorMsg("Unable to establish connection. Please try again.");
                setShowFailureModal(true);
            }
        });

        sse.addEventListener("event", (e: MessageEvent) => {
            let data;
            try {
                data = JSON.parse(e.data);
                if (data && data.data && Array.isArray(data.data) && data.data.length > 0) {
                    const paymentInfo = data.data[0];
                    logger.log('Payment info received:', paymentInfo);
                    if (paymentInfo.isPaid === true) {
                        clearTimeout(timeoutId);
                        proceedToCreateBookingFromOrder();
                        setShowSuccessModal(true);
                        cleanupSSE();
                    }
                } else if (Array.isArray(data) && data.length > 0) {
                    const paymentInfo = data[0];
                    logger.log('Direct payment info received:', paymentInfo);
                    if (paymentInfo.isPaid === true) {
                        clearTimeout(timeoutId);
                        proceedToCreateBookingFromOrder();
                        setShowSuccessModal(true);
                        cleanupSSE();
                    }
                }
            } catch (ex) {
                logger.log("Error parsing event data:", ex);
            }
        });

        // Add reconnection logic
        let reconnectAttempts = 0;
        const maxReconnectAttempts = 3;

        const attemptReconnect = () => {
            if (reconnectAttempts < maxReconnectAttempts) {
                reconnectAttempts++;
                logger.log(`Attempting to reconnect (${reconnectAttempts}/${maxReconnectAttempts})`);
                cleanupSSE();
                const newSSE = new EventSource(modifiedCallBackUrl);
                setSseInstance(newSSE);
            } else {
                setErrorMsg("Unable to maintain connection. Please try again.");
                setShowFailureModal(true);
            }
        };

        sse.onerror = () => {
            if (sse.readyState === EventSource.CLOSED) {
                attemptReconnect();
            }
        };
    };

    const proceedToCreateBookingFromOrder = async () => {
        logger.log('Creating booking from order');
        
        if (!user || !listing || !startDate || !endDate || !order) {
            logger.error('Missing required booking parameters:', {
                user,
                listing,
                startDate,
                endDate,
                order
            });
            setErrorMsg('Missing booking information. Please try again.');
            setShowFailureModal(true);
            return;
        }

        const userId = user.id;
        const listingId = listing.id;
        const orderId = order.id;

        const payload = {
            userId,
            listingId,
            startOn: startDate,
            endOn: endDate,
            orderId
        };

        logger.log('Sending booking payload:', payload);

        try {
            const res = await apiService.makeImmediatePaymentBooking(payload);
            logger.log('Booking API Response:', res);
            
            if (res.status === 200) {
                const response = res.data;
                const bookingResponse = response?.data;
                const bookingIdsArray: number[] = [];

                bookingResponse && bookingResponse.forEach((booking: any) => {
                    bookingIdsArray.push(booking.id);
                });

                if (bookingIdsArray.length > 0) {
                    try {
                        logger.log('Sending email confirmation for bookings:', bookingIdsArray);
                        await apiService.sendBookingConfirmationEmail(bookingIdsArray.join(','));
                        logger.log('Email confirmation sent successfully');
                        setShowSuccessModal(true);
                    } catch (err) {
                        logger.error('Error sending confirmation email:', err);
                        // Still show success even if email fails
                        setShowSuccessModal(true);
                    }
                }
            }
        } catch (err) {
            logger.error('Error creating booking:', err);
            const error = err as { response?: { data?: { message?: string } } };
            const errorMessage = error.response?.data?.message || 'Error creating booking';
            setErrorMsg(errorMessage);
            setShowFailureModal(true);
        }
    };

    const handleStuffOnModalHide = (type: string) => {
        switch (type) {
            case 'success':
                setShowSuccessModal(false);
                cleanupSSE();

                // Check if user is still logged in before redirecting
                if (!user) {
                    // If user session is lost, redirect to mobile login
                    history.push('/signin');
                    return;
                }

                // Add a small delay to ensure booking is properly created
                setTimeout(() => {
                    // Redirect to mobile reservations and force reload
                    history.push('/reservation');
                    window.location.reload();
                }, 2000);
                break;

            case 'failure':
                setShowFailureModal(false);
                break;
        }
    };

    const processPayment = async () => {
        setIsProcessing(true);
        setErrorMsg('');
        setShowConfirmModal(false);

        try {
            const requestId = Math.random().toString(36).substring(7);
            const userId = user?.id || order?.billingAccount.id;
            const companyId = user?.companyId || order?.billingAccount.company.id;

            if (!userId || !companyId) {
                throw new Error('Missing user or company information');
            }

            // Get currency from user object
            const paymentCurrency = user?.country?.currency || 'KES';
            logger.log("Using currency from user object:", paymentCurrency);

            // Get RSA Public Key
            const publicKeyResponse = await apiService.getCompanyRsaKey(companyId, userId);

            if (publicKeyResponse.status !== 200) {
                throw new Error('Failed to get encryption key');
            }

            const publicKey = publicKeyResponse.data.data[0].rsaPublicKey;
            const bit256 = generateKey(32);

            // Generate encrypted key
            const encryptedKeyResponse = await apiService.generateEncryptedKey({
                bitKey: bit256,
                rsaPublicKey: publicKey,
            });

            if (encryptedKeyResponse.status !== 200) {
                throw new Error('Failed to generate encrypted key');
            }

            const requestKey = encryptedKeyResponse.data;
            // const processPaymentUrl = '';

            const payload = {
                key: requestKey,
                data: {
                    requestId,
                    invoiceTokens: [order.referenceCode],
                    currency: paymentCurrency,
                    paymentMethodCode: null,
                    userId,
                    paymentMethod: "webCheckout",
                    amount: order.total,
                    mobilewallet: null,
                    electronicCardRequest: null,
                    bankTransferRequest: null,
                    isCashOnDelivery: false,
                }
            };

            try {
                const encrypted = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
                payload.data = encrypted;

                const paymentResponse = await apiService.processPayment(payload, userId);

                if (paymentResponse.status === 200) {
                    const authUrl = paymentResponse.data.data[0].paymentRequestResponse.data.authorizationUrl;
                    const callbackUrl = paymentResponse.data.data[0].subscriptionCallbackUrl;

                    // Open Paystack in new window
                    const paystackWindow = window.open(authUrl, '_blank');

                    // Start observing payment status
                    observePaymentStatusCallBack(order.referenceCode, callbackUrl);

                    // Check if window was closed
                    let checkCount = 0;
                    const maxChecks = 60; // Check for 1 minute max

                    const checkWindow = setInterval(() => {
                        checkCount++;
                        if (paystackWindow?.closed || checkCount >= maxChecks) {
                            clearInterval(checkWindow);
                            checkFinalPaymentStatus(callbackUrl);
                        }
                    }, 1000);
                }
            } catch (error) {
                handlePaymentError(error);
            }
        } catch (error) {
            handlePaymentError(error);
        } finally {
            setIsProcessing(false);
            setShowAuthModal(false);
        }
    };

    const handlePaymentError = (error: any) => {
        setIsProcessing(false);

        // Default user-friendly message
        let userMessage = "Failed to establish connection with server. Please try again later.";

        // Log the actual error for debugging
        logger.error('Payment processing error:', error?.response?.data || error);

        // Only show technical error details in development
        if (process.env.NODE_ENV === 'development') {
            logger.log('Original error message:', error?.response?.data?.message);
        }

        // Show error toast
        toast.error(userMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setErrorMsg(userMessage);
        setShowFailureModal(true);
    };

    const checkFinalPaymentStatus = async (callbackUrl: string) => {
        try {
            const userId = user ? user.id : 0;
            const modifiedCallBackUrl = `${callbackUrl}%2B&userId=${userId}`;

            const response = await fetch(modifiedCallBackUrl);
            const rawText = await response.text();

            // Split into individual SSE messages
            const messages = rawText.split('\n\n').filter(msg => msg.trim());

            for (const message of messages) {
                // Only process 'event' data lines
                const dataLine = message
                    .split('\n')
                    .find(line => line.startsWith('data:'));

                if (dataLine) {
                    try {
                        // Extract the JSON part after 'data:'
                        const jsonStr = dataLine.slice(5).trim();
                        const data = JSON.parse(jsonStr);

                        // Handle direct array response
                        const paymentInfo = Array.isArray(data) ? data[0] :
                            (data?.data && Array.isArray(data.data) ? data.data[0] : null);

                        if (paymentInfo) {
                            logger.log('Payment status:', paymentInfo.isPaid);
                            if (paymentInfo.isPaid === true) {  // explicitly check for true
                                proceedToCreateBookingFromOrder();
                                setShowSuccessModal(true);
                                return;
                            }
                        }
                    } catch (parseError) {
                        logger.warn("Error parsing SSE data:", parseError);
                        continue;
                    }
                }
            }

            // If we get here, payment is still pending
            logger.log("Payment is still pending");
            return;

        } catch (error) {
            logger.error("Error checking payment status:", error);
            setErrorMsg("Could not verify payment status");
            setShowFailureModal(true);
        }
    };

    return (
        <div className='mobile-view' style={{ background: "#FCFCFC" }}>
            <PageTitle>Order Summary</PageTitle>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <div className="section mt-3 px-3">
                <figure style={{ width: '100px', margin: '0' }}>
                    <img
                        alt="logo"
                        decoding="async"
                        className="w100 img-fluid"
                        src={toAbsoluteUrl("/img/logo-black.png")}
                    />
                </figure>
            </div>

            <div className="section p-4">
                <div className="card mt-4" style={{ background: '#FFFFFF', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                    <div className="card-body p-4">
                        <h5 style={{ color: '#000000' }}>Order No: <span style={{ fontWeight: 'normal' }}>{order?.id}</span></h5>

                        <h5 className="mt-4" style={{ color: '#000000' }}>{listing?.name}</h5>
                        <div className="d-flex align-items-center">
                            <Location
                                color={'#6E7070'}
                                height="16px"
                                width="16px"
                            />
                            <span className="ms-2" style={{ color: '#6E7070' }}>{listing?.location?.address}</span>
                        </div>

                        <div className="mt-4">
                            <div className="d-flex justify-content-between mb-2">
                                <span style={{ color: '#6E7070' }}>Start Date:</span>
                                <span style={{ color: '#000000' }}>{new Date(startDate).toLocaleDateString()}</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span style={{ color: '#6E7070' }}>End Date:</span>
                                <span style={{ color: '#000000' }}>{new Date(endDate).toLocaleDateString()}</span>
                            </div>
                        </div>

                        <div className="mt-4">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ color: '#6E7070' }}>Item</th>
                                        <th style={{ color: '#6E7070', textAlign: 'center' }}>Qty</th>
                                        <th style={{ color: '#6E7070', textAlign: 'right' }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ color: '#000000' }}>{spaceType}</td>
                                        <td style={{ color: '#000000', textAlign: 'center' }}>x{quantity}</td>
                                        <td style={{ color: '#000000', textAlign: 'right' }}>{currency} {total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4">
                            <p style={{ color: '#6E7070', marginBottom: '8px' }}>Total + VAT 16%</p>
                            <h4 style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
                                {currency} {order?.total?.toLocaleString()}
                            </h4>
                        </div>

                        <p className="mt-4" style={{ color: '#6E7070', fontSize: '0.9rem' }}>
                            By Clicking "Pay" I agree to the company's <a href="/terms" style={{ color: '#0D6EFD', textDecoration: 'underline' }}>terms & conditions</a> for the service
                        </p>

                        <div className="d-flex justify-content-between mt-4">
                            <button
                                className="btn"
                                style={{
                                    width: '100px',
                                    borderRadius: '100px',
                                    height: '40px',
                                    border: '1px solid #0D6EFD',
                                    color: '#0D6EFD',
                                    background: 'white'
                                }}
                                onClick={() => history.goBack()}
                            >
                                Back
                            </button>
                            <button
                                className="btn btn-primary"
                                style={{
                                    width: '160px',
                                    borderRadius: '100px',
                                    height: '40px'
                                }}
                                onClick={() => setShowConfirmModal(true)}
                                disabled={isProcessing}
                            >
                                {isProcessing ? (
                                    <div className="spinner-border spinner-border-sm text-white" role="status">
                                        <span className="visually-hidden">Processing...</span>
                                    </div>
                                ) : 'Pay'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirm Modal - Updated to match mobile style */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
                style={{
                    borderRadius: "30px",
                }}
            >
                <Modal.Header
                    style={{
                        border: 'none',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 1
                    }}
                >
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowConfirmModal(false)}
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            fontSize: '1.5rem',
                            color: '#000'
                        }}
                    >
                        ×
                    </button>
                </Modal.Header>
                <Modal.Body
                    className="text-center"
                    style={{
                        padding: "30px 20px",
                        marginTop: "20px"
                    }}
                >
                    <h5
                        className="text-primary"
                        style={{
                            fontSize: "1.3rem",
                            fontWeight: 200,
                            marginBottom: "30px"
                        }}
                    >
                        You are about to be redirected to checkout
                    </h5>
                    <div className="d-flex justify-content-center gap-3">
                        <button
                            className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                width: "8rem"
                            }}
                            onClick={() => setShowConfirmModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                width: "8rem"
                            }}
                            onClick={() => {
                                processPayment();
                                setShowConfirmModal(false);
                                setShowAuthModal(true);
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide('success')}>
                <Modal.Body className="text-center p-4">
                    <img src={successImage} alt="Success" style={{ width: '40%' }} />
                    <h4 className="mt-4">Payment Successful!</h4>
                    <p>Please check your email for booking confirmation</p>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => handleStuffOnModalHide('success')}
                    >
                        Go Back
                    </button>
                </Modal.Body>
            </Modal>

            {/* Failure Modal */}
            <Modal show={showFailureModal} onHide={() => setShowFailureModal(false)}>
                <Modal.Body className="text-center p-4">
                    <img src={rejectedImage} alt="Failed" style={{ width: '40%' }} />
                    <h4 className="mt-4">Payment Failed!</h4>
                    <p className="text-danger">{errorMsg}</p>
                    <button
                        className="btn btn-secondary mt-3"
                        onClick={() => setShowFailureModal(false)}
                    >
                        Close
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BookingOrderSummary;