import React, { FC, useState } from 'react';
import { EllipsisVertical } from "react-ionicons";
import { PageTitle, LeftComponent } from "../../../layout/PageData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BsChevronDown } from "react-icons/bs";
import { UserFAQPayload as userFAQs } from '../../../components/FAQs/UserFAQPayload';
import hostFAQs from "../../../components/FAQs/HostFAQPayload";
import "./styles.scss";

const FAQs: FC = () => {
    const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

    const handleAccordionChange = (panel: string) => (
        _event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    return (
        <div className="faqs-mobile">
            <PageTitle>FAQs</PageTitle>
            <LeftComponent>
                <div
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#sidebarPanel"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "40px",
                        height: "40px",
                    }}
                >
                    <EllipsisVertical color={"#666"} height="24px" width="24px" />
                </div>
            </LeftComponent>

            <div className="hero-section">
                <h1>Frequently Asked Questions</h1>
                <p>Find answers to common questions about Spacia</p>
            </div>

            <div className="faq-section">
                <h2 className="section-title">User FAQs</h2>
                {userFAQs.map((faq, index) => (
                    <Accordion
                        key={index}
                        expanded={expandedAccordion === `user-panel-${index}`}
                        onChange={handleAccordionChange(`user-panel-${index}`)}
                    >
                        <AccordionSummary expandIcon={<BsChevronDown />}>
                            {faq.question}
                        </AccordionSummary>
                        <AccordionDetails>
                            {faq.answer}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            <div className="faq-section">
                <h2 className="section-title">Host FAQs</h2>
                {hostFAQs.map((faq, index) => (
                    <Accordion
                        key={index}
                        expanded={expandedAccordion === `host-panel-${index}`}
                        onChange={handleAccordionChange(`host-panel-${index}`)}
                    >
                        <AccordionSummary expandIcon={<BsChevronDown />}>
                            {faq.question}
                        </AccordionSummary>
                        <AccordionDetails>
                            {faq.answer}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

export default FAQs;
