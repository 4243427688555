import React, {useState, useEffect} from "react";
import SERVICES from "../../../services";
import { Constants } from '../../../utils/constants';
import { useHistory, Link } from "react-router-dom";
import "react-bootstrap-tagsinput/dist/index.css";
import apiService from "../../../services/apiService"; // Import apiService
import 'react-tabs/style/react-tabs.css';
import { v4 as uuidv4 } from 'uuid';
import {PageTitle} from "../../../layout/PageData";
import {SearchOutline} from "react-ionicons";
import Pagination from "../../../components/Pagination";
import InformationModal from "../../../components/informationModal";
import UserProfile from "./userProfile";
import ReservationCard from "../../../components/ReservationCard";
import {Modal, Spinner} from "react-bootstrap";
import UserRow from "./userRow";
import noAvatar from '../../../assets/img/png/no-avatar.png';
import noUserData from '../../../assets/img/png/no-user-data.png';
import plusIcon from '../../../assets/img/png/blue-plus-icon.png';
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../assets/img/svg/success-white-background.svg";
import warningImage from "../../../assets/img/svg/red-warning.svg";
import logger from "../../../utils/logger";

const UserManagement = () => {

	const [userStatus, setUserStatus] = useState('CONFIRMED');
	const [verifiedSearchValue, setVerifiedSearchValue] = useState('');
    const [unVerifiedSearchValue, setUnverifiedSearchValue] = useState('');
	const [verifiedCurrentPage, setVerifiedCurrentPage] = useState(1);
	const [unverifiedCurrentPage, setUnverifiedCurrentPage] = useState(1);
	const [verifiedTotalPageSize, setVerifiedTotalPageSize] = useState(0);
	const [verifiedItemsPerPage, setVerifiedItemsPerPage] = useState(10);
	const [unverifiedTotalPageSize, setUnverifiedTotalPageSize] = useState(0);
	const [unverifiedItemsPerPage, setUnverifiedItemsPerPage] = useState(10);

	const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
	const [noAddedEmail, setNoAddedEmail] = useState(false);
	const [isBulkRoleNotSelected, setIsBulkRoleNotSelected] = useState(false);
	const [isInviteFileNotChosen, setIsInviteFileNotChosen] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [showInviteError, setShowInviteError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [inviteErrorMsg, setInviteErrorMsg] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [requestCompleted, setRequestCompleted] = useState(false);

	const [context, setContext] = useState({});

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);

	// Functions
	const deleteUser = (id) => {
		setShowDeleteModal(true);
		setUserId(id);
	};


	const [currentSelectedUser, setCurrentSelectedUser] = useState({});
	const [currentUserRole, setCurrentUserRole] = useState('');
	const [currentUserId, setCurrentUserId] = useState('');
	const [subAccountRoles, setSubAccountRoles] = useState([]);
	const [bulkSubAccountRoles, setBulkSubAccountRoles] = useState([]);

  const defaultAvatar = 'https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg';


	const [userId, setUserId] = useState(0);
  const [emailOfSelectedUser, setEmailOfSelectedUser] = useState('');

	const [unVerifiedUsers, setUnVerifiedUsers] = useState([]);
	const [verifiedUsers, setVerifiedUsers] = useState([]);

	//set state to toggle edit user
	const [editShow, setEditShow] = useState(false);

	//set state to toggle confirm adding user
	const [confirmUserAddition, setConfirmUserAddition] = useState(false);

	const [openProfileUpdate, setOpenProfileUpdate] = useState(false);
	const [profileUpdated, setProfileUpdated] = useState(false);
	// const [loaded, setLoaded] = useState(false);

	const [role, setRole] = useState("");
	const [bulkRole, setBulkRole] = useState("");
	const [selectedFile, setSelectedFile] = useState('');

	const [state, setState] = useState([]);

	useEffect(() => {
		logger.log('state holding email of invitees:', state);
	}, [state]);

	useEffect(() => {
		console.log('verifiedUsers changed:', verifiedUsers);
	}, [verifiedUsers]);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		console.log('Current user from SERVICES:', currentUser);
		const currentUserRole = currentUser?.role;
    const id = currentUser ? currentUser.id : 0;
		setCurrentUserId(id);

		logger.log('current user role:', currentUserRole);

		setCurrentUserRole(currentUserRole);
    setCurrentUserId(id);

		setUserStatus('CONFIRMED');
	}, []);

	useEffect(() => {
		switch (currentUserRole) {
			case 'ROLE_SUBSCRIBER_OWNER':
				// set value and label
				const ownerSubAccounts = [
					// {
					// 	value: 'ROLE_SUBSCRIBER_APPROVER',
					// 	label: 'Approver'
					// },
					// {
					// 	value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
					// 	label: 'Regular'
					// },
          {
            value: 'ROLE_EMPLOYEE',
            label: 'Employee'
          },
          {
            value: 'ROLE_APPROVER_EMPLOYEE',
            label: 'Administrator'
          }
				];

				setSubAccountRoles(ownerSubAccounts);
				break;

			case 'ROLE_HOST':
				// set value and label
				const hostSubAccounts = [
					{
						value: 'ROLE_HOST_OPERATIONS',
						label: 'Operations Account'
					}
				]
				setSubAccountRoles(hostSubAccounts);
        break;

      case 'ROLE_ADMINISTRATOR':
        // set value and label
        const adminSubAccounts = [
          {
            value: 'ROLE_SUBSCRIBER_OWNER',
            label: 'Corporate Admin'
          }
        ]
        setSubAccountRoles(adminSubAccounts);
		}
	}, [currentUserRole]);

  useEffect(() => {
    switch (currentUserRole) {
      case 'ROLE_SUBSCRIBER_OWNER':
        // set value and label
        const ownerSubAccounts = [
          {
            value: 'EMPLOYEE',
            label: 'Employee'
          }
        ];

        setBulkSubAccountRoles(ownerSubAccounts);
        break;

      case 'ROLE_HOST':
        // set value and label
        const hostSubAccounts = [
          {
            value: 'HOST_OPERATIONS',
            label: 'Operations Account'
          }
        ]
        setBulkSubAccountRoles(hostSubAccounts);
        break;

      case 'ROLE_ADMINISTRATOR':
        // set value and label
        const adminSubAccounts = [
          {
            value: 'OWNER_SUBSCRIBER',
            label: 'Corporate Admin'
          }
        ]
        setBulkSubAccountRoles(adminSubAccounts);
    }
  }, [currentUserRole]);

	const confirmDeleteOpen = async () => {
		const currentUser = SERVICES.getUser();
	  const ownerId = currentUser ? currentUser.id : 0;

		try {
			const response = await apiService.deleteUserById(userId, ownerId);
			setContext('deleted');
			setShowSuccessModal(true);

			switch (userStatus) {
				case 'CONFIRMED':
					break;

				case 'PENDING':
					const newUnVerifiedUsers = unVerifiedUsers;

					const user = unVerifiedUsers.find((u) => u.id === userId);
					newUnVerifiedUsers.splice(unVerifiedUsers.indexOf(user), 1);
					setUnVerifiedUsers(newUnVerifiedUsers);
			}
		} catch (err) {
			setShowErrorModal(true);
		}
	};


	useEffect(() => {
		if (role) {
			setIsRoleNotSelected(false);
		}
		if (bulkRole) {
			setIsBulkRoleNotSelected(false);
		}
		if (selectedFile) {
			setIsInviteFileNotChosen(false);
		}
	}, [role, bulkRole, selectedFile]);

	useEffect(() => {
		if (state.length > 0) {
			setNoAddedEmail(false);
		}
	}, [state]);


	let history = useHistory();

	


	useEffect(() => {
		const loadUserData = async () => {
			console.log('Loading user data with currentUserId:', currentUserId);
			setRequestCompleted(false);
			try {
				let response;
				switch (userStatus) {
					case 'CONFIRMED':
						response = await apiService.getSubAccounts(currentUserId, {
							sortBy: "updatedDate",
							verified: true,
							page: verifiedCurrentPage - 1,
							search: verifiedSearchValue
						});
						console.log('API Response:', response);
						if (response.status === 200) {
							console.log('Setting verified users:', response.data.data);
							setVerifiedUsers(response.data.data || []);
							setVerifiedTotalPageSize(response.data.totalCount || 0);
							setVerifiedItemsPerPage(response.data.pageSize || 10);
						}
						break;

					case 'PENDING':
						response = await apiService.getSubAccounts(currentUserId, {
							sortBy: "createdDate",
							verified: false,
							page: unverifiedCurrentPage - 1,
							search: unVerifiedSearchValue
						});
						if (response.status === 200) {
							setUnVerifiedUsers(response.data.data || []);
							setUnverifiedTotalPageSize(response.data.totalCount || 0);
							setUnverifiedItemsPerPage(response.data.pageSize || 10);
						}
						break;
				}
			} catch (error) {
				logger.error("Error loading user data:", error);
			} finally {
				setRequestCompleted(true);
			}
		};

		loadUserData();
	}, [userStatus, verifiedCurrentPage, unverifiedCurrentPage, verifiedSearchValue, unVerifiedSearchValue, currentUserId]);

	const handleVerifiedSearch = async (e) => {
		e.preventDefault();
		setRequestCompleted(false);
		setVerifiedSearchValue(searchValue);

		try {
			const response = await apiService.getSubAccounts(currentUserId, {
				sortBy: "updatedDate",
				verified: true,
				page: verifiedCurrentPage - 1,
				search: searchValue
			});

			if (response.status === 200) {
				setVerifiedUsers(response.data.data || []);
				setVerifiedTotalPageSize(response.data.totalCount || 0);
			}
		} catch (error) {
			logger.error("Error searching verified users:", error);
		} finally {
			setRequestCompleted(true);
		}
	};

	const handleUnverifiedSearch = async (e) => {
		e.preventDefault();
		setRequestCompleted(false);
		setUnverifiedSearchValue(searchValue);

		try {
			const response = await apiService.getSubAccounts(currentUserId, {
				sortBy: "createdDate",
				verified: false,
				page: unverifiedCurrentPage - 1,
				search: searchValue
			});

			if (response.status === 200) {
				setUnVerifiedUsers(response.data.data || []);
				setUnverifiedTotalPageSize(response.data.totalCount || 0);
			}
		} catch (error) {
			logger.error("Error searching unverified users:", error);
		} finally {
			setRequestCompleted(true);
		}
	};

	const changeVerifiedPage = async (selected) => {
		setRequestCompleted(false);

		try {
			const response = await apiService.getSubAccounts(currentUserId, {
				sortBy: "updatedDate",
				verified: true,
				page: selected - 1,
				search: verifiedSearchValue
			});

			if (response.status === 200) {
				setVerifiedUsers(response.data.data || []);
				setVerifiedTotalPageSize(response.data.totalCount || 0);
				setVerifiedCurrentPage(selected);
			}
		} catch (error) {
			logger.error("Error changing verified page:", error);
		} finally {
			setRequestCompleted(true);
		}
	};

	const changeUnverifiedPage = async (selected) => {
		setRequestCompleted(false);

		try {
			const response = await apiService.getSubAccounts(currentUserId, {
				sortBy: "createdDate",
				verified: false,
				page: selected - 1,
				search: unVerifiedSearchValue
			});

			if (response.status === 200) {
				setUnVerifiedUsers(response.data.data || []);
				setUnverifiedTotalPageSize(response.data.totalCount || 0);
				setUnverifiedCurrentPage(selected);
			}
		} catch (error) {
			logger.error("Error changing unverified page:", error);
		} finally {
			setRequestCompleted(true);
		}
	};

	const handleSwitch = (e) => {
		// Clear search when switching tabs
		setSearchValue('');
		setVerifiedSearchValue('');
		setUnverifiedSearchValue('');

		if (e.target.id === "confirmedBtn") {
			setUserStatus('CONFIRMED');
		} else {
			setUserStatus('PENDING');
		}
	}

	const handleStuffOnModalHide = (showModal) => {
		showModal(false);

		const userManagementContainer = document.querySelector('.user-management-content');
		if (userManagementContainer != null) {
			userManagementContainer.classList.remove('blur-background');
		}
	}

	const componentToRender = () => {
		if (requestCompleted) {
			console.log('Rendering component with:', {
				userStatus,
				verifiedUsers,
				unVerifiedUsers,
				currentUserId
			});
			console.log('userStatus:', userStatus);
			console.log('verifiedUsers:', verifiedUsers);
			console.log('unVerifiedUsers:', unVerifiedUsers);
			if (userStatus === 'CONFIRMED' && Array.isArray(verifiedUsers) && verifiedUsers.length > 0) {
				return (
					verifiedUsers
						.filter(user => user.id !== currentUserId)
						.map((user, i) =>  {
							return (
								<UserRow
									key={uuidv4()}
									user={user}
									profile={user.avatar || noAvatar}
									status={userStatus}
									name={
										user.firstName &&
										user.lastName
											? `${user.firstName} ${user.lastName}`
											: "N/A"
									}
									email={user.username}
								/>
							)
					}
				))
			} else if (userStatus === 'PENDING' && Array.isArray(unVerifiedUsers) && unVerifiedUsers.length > 0) {
				return unVerifiedUsers
					.filter(user => user.id !== currentUserId)
					.map((user, i) =>  {
						return (
							<UserRow
								key={uuidv4()}
								user={user}
								profile={user.avatar || noAvatar}
								status={userStatus}
								name={
									user.firstName &&
									user.lastName
										? `${user.firstName} ${user.lastName}`
										: "N/A"
								}
								email={user.username}
								onDelete={() => deleteUser(user.id)}
							/>
						)
					})
			} else if (userStatus === 'CONFIRMED' && Array.isArray(verifiedUsers) && verifiedUsers.length === 0) {
				return (
					<div className="container-for-perfect-center">
						<div className='perfect-center'>
              <figure className={'w-100 text-center'}>
                <img
                  src={noUserData}
                  alt="no user data"
                  style={{width: '70%'}}
                />
              </figure>
						</div>
					</div>
				)
			} else if (userStatus === 'PENDING' && Array.isArray(unVerifiedUsers) && unVerifiedUsers.length === 0) {
				return (
					<div className="container-for-perfect-center">
						<div className='perfect-center'>
              <figure className={'w-100 text-center'}>
                <img
                  src={noUserData}
                  alt="no user data"
                  style={{width: '70%'}}
                />
              </figure>
							{/*<p style={{fontWeight: 'bold', fontSize: '1.3rem'}}>No Pending Users Found</p>*/}
						</div>
					</div>
				)
			}
		} else if (!requestCompleted) {
			return (
				<div className='container-for-perfect-center'>
					<div className='perfect-center'>
						<Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
							<span className="visually-hidden"></span>
						</Spinner>{" "}
					</div>
				</div>
			)
		}
	}

	return (
		<div className='mobile-view user-management' style={{background: "#FCFCFC"}}>
			<div className="section mt-2 p-3 position-relative" style={{minHeight: '88vh'}}>
				<PageTitle>Users</PageTitle>
				<div className="user-management-content" style={{marginTop: '25px'}}>
					<form className="search-form" onSubmit={(e) => {
						if (userStatus === 'CONFIRMED') {
							handleVerifiedSearch(e);
						} else if (userStatus === 'PENDING') {
							handleUnverifiedSearch(e);
						}
					}}>
						<div className="form-group searchbox">
							<input
								type="text"
								className="form-control"
								placeholder={'Search by name or email'}
								value={searchValue}
								onChange={e => setSearchValue(e.target.value)}
							/>
							<i className="input-icon">
								<SearchOutline
								/>
							</i>
						</div>
					</form>
				</div>
				<div className={'mt-3 text-center'}>
					<div className="btn-group btn-lg " role="group">
						<input type="radio" className="btn-check" name="confirmedBtn" id="confirmedBtn" checked={userStatus === 'CONFIRMED'} onChange={handleSwitch} onClick={handleSwitch} />
						<label className={userStatus === 'CONFIRMED' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="confirmedBtn">Confirmed</label>

						<input type="radio" className="btn-check" name="pendingBtn" id="pendingBtn"   checked={userStatus === 'PENDING'} onChange={handleSwitch}  onClick={handleSwitch} />
						<label className={userStatus === 'PENDING' ? 'reservation-btn btn btn-primary' : 'reservation-btn btn btn-outline-primary'} htmlFor="pendingBtn">Pending</label>

					</div>
				</div>
				<div className="mt-5 users-holder" style={{maxHeight: '45vh'}}>
					{
						componentToRender()
					}
					<div className="position-fixed" style={{bottom: '20px', right: '20px', zIndex: 1000}}>
					<figure className="mb-0">
						<img
							className="btn"
							role="button"
							src={plusIcon}
							alt="add user button"
							style={{width: '100px', height: '70px'}}
							onClick={() => history.push('/add-user')}
						/>
					</figure>
				</div>
				{
					Array.isArray(verifiedUsers) && verifiedUsers.length > 0 &&
					<div className='mobile-pagination' style={{
  marginBottom: "35rem !important",
  paddingBottom: "5rem !important"
}}>
<div style={{ paddingBottom: "1rem",}}></div>
						<Pagination
							className="pagination-bar"
							currentPage={verifiedCurrentPage}
							totalCount={verifiedTotalPageSize}
							pageSize={verifiedItemsPerPage}
							onPageChange={changeVerifiedPage}
						/>
						<div style={{ paddingBottom: "1rem",}}></div>
					</div>
				}
				{
					Array.isArray(unVerifiedUsers) && unVerifiedUsers.length > 0 &&
					<div className='mobile-pagination' style={{
  marginBottom: "35rem !important",
  paddingBottom: "5rem !important"
}}>
<div style={{ paddingBottom: "1rem",}}></div>
						<Pagination
							className="pagination-bar"
							currentPage={unverifiedCurrentPage}
							totalCount={unverifiedTotalPageSize}
							pageSize={unverifiedItemsPerPage}
							onPageChange={changeUnverifiedPage}
						/>
						<div style={{ paddingBottom: "1rem",}}></div>

					</div>
				}
				</div>
				
			</div>

			{/* define modals */}

			{/*Delete Modal*/}
			<Modal show={showDeleteModal} onHide={() => handleStuffOnModalHide(setShowDeleteModal)} onEnter={e => logger.log(e)}>
				<header className={'position-relative'} style={{zIndex: '3'}}>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowDeleteModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '250px'}}>
					<div>
						<p className={'text-black font-weight-bolder mb-4'} style={{fontSize: '1.55rem'}}>Delete Confirmation</p>
						<p style={{fontSize: '1.1rem'}}>
							Are you sure you want to delete user?
						</p>
						<div className={'mt-5 text-center'}>
							<button
								className={'btn text-black text-capitalize'}
								style={{cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => handleStuffOnModalHide(setShowDeleteModal)}
							>
								cancel
							</button>
							<button
								className={'btn btn-primary text-white text-capitalize'}
								style={{cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={confirmDeleteOpen}
							>
								delete
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/*Success Modal*/}
			<Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide(setShowSuccessModal)} onEnter={e => logger.log(e)}>
				<header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
					<p className={'text-black text-center font-weight-bolder mb-0 text-capitalize'} style={{fontSize: '1.55rem', width: '90%'}}>{context}</p>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowSuccessModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '265px', textAlign: 'center'}}>
					<div className={'mx-auto'} style={{width: '80%'}}>
						<p style={{fontSize: '1.1rem'}}>
							You have successfully {context} the user
						</p>
						<figure>
							<img src={successImage} alt="success image"/>
						</figure>
						<div className={'mt-5 text-center'}>
							<button
								className={'btn text-black text-uppercase'}
								style={{cursor: 'pointer', width: '120px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => {
									if (context) {
										logger.log('current context:', context);

										switch (context) {
											case 'deleted':
												handleStuffOnModalHide(setShowSuccessModal);
												break;

											case 'disabled':
												handleStuffOnModalHide(setShowSuccessModal);
												break;

											case 'enabled':
												handleStuffOnModalHide(setShowSuccessModal);
												break;
										}
									}
								}}
							>
								ok
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/*Error Modal*/}
			<Modal show={showErrorModal} onHide={() => handleStuffOnModalHide(setShowErrorModal)} onEnter={e => logger.log(e)}>
				<header className={'position-relative d-flex align-items-center'} style={{zIndex: '3'}}>
					<p className={'text-black text-center font-weight-bolder mb-0'} style={{fontSize: '1.55rem', width: '90%'}}>Sorry</p>
					<figure className="text-right mb-0" style={{padding: '15px'}}>
						<img onClick={() => handleStuffOnModalHide(setShowErrorModal)} src={closeButton} alt="close button" style={{width: '25px'}}/>
					</figure>
				</header>
				<Modal.Body style={{height: '280px', textAlign: 'center'}}>
					<div className={'mx-auto'} style={{width: '80%'}}>
						<p style={{fontSize: '1.1rem'}}>
							Whoa! something went wrong.
						</p>
						<p style={{fontSize: '1.1rem'}}>
							Kindly try again
						</p>
						<figure>
							<img src={warningImage} alt="warning image"/>
						</figure>
						<div className={'mt-4 text-center'}>
							<button
								className={'btn text-black'}
								style={{cursor: 'pointer', width: '165px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px'}}
								onClick={() => handleStuffOnModalHide(setShowErrorModal)}
							>
								Please try again
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default UserManagement;
