import React, { useState, useEffect, useCallback } from 'react';
import { Container, Table, Col, Row, Card, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import InformationModal from '../../../../components/informationModal';
import FeedbackModall from '../../../../components/FeedbackModall';
import PendingApprovalRow from '../../PendingApprovalRow';
import { PendingApprovalStyles } from '../../PendingApprovalStyles';
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import HostsRow from "../../../../components/hosts";
import Pagination from "../../../../components/Pagination";
import { HostStyles } from "../../../../components/HostStyles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { CommissionRateModal, RevenueShareRateModal, StyledModal } from "../../../../components/Modals/ModalStyles";
import { InputTags } from "react-bootstrap-tagsinput";
import { Modal } from "react-bootstrap";
import logger from "../../../../utils/logger";

const ApprovedReservations = () => {
    const [approvedHosts, setApprovedHosts] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedCompanyShareRate, setSelectedCompanyShareRate] = useState(0.0);
    const [searchValue, setSearchValue] = useState('');
    const [ownerId, setOwnerId] = useState(0);
    const [revenueShareRateId, setRevenueShareRateId] = useState(0);

    // Accept Changes Modal
    const [showModal, setShowModal] = useState(false);
    const [showRevenueShareRateModal, setShowRevenueShareRateModal] = useState(false);
    const [showDisableHostModal, setShowDisableHostModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const link = {
        fontWeight: 300,
        color: 'blue'
    };

    // Decline Changes Modal
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const openDeclineModal = () => setShowDeclineModal(true);
    const closeDeclineModal = () => setShowDeclineModal(false);


    // Show Feedback M
    const [showFeedbackModal, setshowFeedbackModal] = useState(false);

    const openDeclineModalFeedback = () => {
        // { state ? setShowFeedbackModal(true) : setShowFeedbackModal(false) }
        setShowDeclineModal(false);
        setshowFeedbackModal(true);
    };

    const unapproveSelectedHosts = useCallback(() => {
        setShowModal(false);
        const companyIds = approvedHosts.filter(o => o.checked === true).map(o => o.companyId);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyIds.join(','), id, false)
            .then(res => {
                if (res.status === 200) {
                    window.location.reload(false);
                }
            })
            .finally(() => {
                setShowModal(false);
            });
    });

    const disableHost = (companyId) => {
        setShowDisableHostModal(false);
        const currentUser = SERVICES.getUser();
        const id = currentUser && currentUser.id;

        apiService.updateCompanyStatus(companyId, id, false)
            .then(res => {
                if (res.status === 200) {
                    setShowDisableHostModal(false);
                    window.location.reload(false);
                }
            })
            .finally(() => {
                setShowDisableHostModal(false);
            });
    };

    const displayRevenueShareRateModal = (show, companyId, ownerId) => {
        setShowRevenueShareRateModal(show);
        setSelectedCompany(companyId);
        setOwnerId(ownerId);
    };

    const displayDisableHostModal = (show, companyId) => {
        setShowDisableHostModal(show);
        setSelectedCompany(companyId);
    };

    const getDefaultRevenueShareRate = (companyId) => {
        const admin = SERVICES.getUser();
        const adminId = admin && admin.id;

        apiService.getRevenueShareRate(companyId, adminId)
            .then(res => {
                const response = res.data;
                const data = response ? response.data : null;
                const revenueShareRate = (data && data.length !== 0) ? data[0] : null;
                const rate = revenueShareRate ? revenueShareRate.rate : 0;
                setSelectedCompanyShareRate(rate);
                setRevenueShareRateId(revenueShareRate ? revenueShareRate.id : 0);
            });
    };

    const updateRevenueShare = (companyId, hostId, rate) => {
        const admin = SERVICES.getUser();
        const adminId = admin && admin.id;

        const revenueShareSettingRequest = {
            companyId: 1,
            rate,
            userId: adminId,
            corporateCustomerIds: [companyId]
        };

        if (revenueShareRateId) {
            apiService.deleteRevenueShareRate(revenueShareRateId, adminId)
                .then(res => {
                    apiService.createRevenueShareRate(revenueShareSettingRequest)
                        .then(res => {
                            setShowRevenueShareRateModal(false);
                        });
                }).catch(err => {
                    logger.log(err.response.data);
                });
        }
    };

    useEffect(() => {
        if (selectedCompany) {
            getDefaultRevenueShareRate(selectedCompany);
        }
    }, [selectedCompany]);

    const destroyModal = () => {
        setSelectedCompany(0);
        setSelectedCompanyShareRate(0.0);
    };

    const closeFeedbackDeclineModal = () => setshowFeedbackModal(false);

    useEffect(() => {
        apiService.getApprovedHosts(currentPage)
            .then(res => {
                if (res.status === 200) {
                    const approvedHosts = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setApprovedHosts((approvedHosts) ? approvedHosts : []);
                }
            });
    }, []);

    const changePage = (selected) => {
        setCurrentPage(selected);
        apiService.getApprovedHosts(selected - 1, searchValue)
            .then(res => {
                if (res.status === 200) {
                    const approvedHosts = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setApprovedHosts((approvedHosts) ? approvedHosts : []);
                }
            }).catch(err => (logger.log(err)));
    };

    const performSearch = (e) => {
        apiService.getApprovedHosts(currentPage, searchValue)
            .then(res => {
                if (res.status === 200) {
                    const approvedHosts = res.data['data'];
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(10);
                    setApprovedHosts((approvedHosts) ? approvedHosts : []);
                }
            });
    };

    return (

        <Container fluid>
            <Row style={{ alignItems: 'center' }}>
                <Col md="5">
                    <h4><b>Approved Hosts</b></h4>
                </Col>
                <Col md="4">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1" onClick={performSearch}><FaSearch /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </InputGroup>
                </Col>
                {/*<Col>*/}
                {/*<button className="button" onClick={openModal}>Unapprove</button>*/}
                {/*<button className="button-calm" onClick={openDeclineModal}>Disable</button>*/}
                {/*</Col>*/}
            </Row>

            <Row className="mt-4">
                <Col>
                    <HostStyles>
                        <Card>
                            <Card.Body>
                                <div className='table-wrapper'>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th><h6>Company</h6></th>
                                                <th><h6>Company Rep.</h6></th>
                                                <th><h6>Documents</h6></th>
                                                <th><h6>Sign Up Date</h6></th>
                                                {/*<th> <h6>Check</h6> </th>*/}
                                                <th> <h6>Action</h6> </th>
                                            </tr>
                                        </thead>
                                        {
                                            Array.isArray(approvedHosts) && approvedHosts.length > 0 &&
                                            approvedHosts.map(approvedHost => {
                                                logger.log('approved host:', approvedHost);
                                                return <HostsRow {...approvedHost} isApprovedHostPage={true}
                                                    displayDisableHostModal={displayDisableHostModal}
                                                    displayRevenueShareRateModal={displayRevenueShareRateModal}
                                                />;
                                            })
                                        }
                                    </Table>
                                    {
                                        Array.isArray(approvedHosts) && approvedHosts.length > 0 &&
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage || 1}
                                            totalCount={totalPageSize}
                                            pageSize={itemsPerPage}
                                            onPageChange={changePage}
                                        />
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </HostStyles>
                </Col>
            </Row>

            <RevenueShareRateModal
                // size="lg"
                show={showRevenueShareRateModal}
                onHide={() => setShowRevenueShareRateModal(false)}
                onExit={destroyModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}
            >
                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
						paddingBottom: 0,

                        paddingRight: "30px",
                        margin: "0px",
                        // height: "20px"
                    }}
                    closeButton
                >
                <div className=""></div>
                </Modal.Header>
                <RevenueShareRateModal.Body>
                    <div className="" style={{ padding: 0 }}>
                        <div
                            style={{
                                padding: 0,
                                paddingLeft: 0,
                            }}
                        >
                            {/*<FaTimes onClick={handleClose} />*/}
                            <h6 className={"text-primary"}
                                style={{ fontSize: "1.2rem", fontWeight: 200 }}>
                                    Rate
                            </h6>
                            <div style={{ marginBottom: '0px' }}>
                                <label style={{ width: '100%', fontSize:"13px" }}>
                                Set Revenue Share Rate
                                    <input
                                        type="number"
                                        className='form-control'
                                        style={{marginTop:"12px", marginBottom: "5px"}}
                                        value={selectedCompanyShareRate}
                                        onChange={e => setSelectedCompanyShareRate(Number(e.target.value))}
                                    />
                                </label>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    padding: 10,
                                    justifyContent: "center",
                                    width: '80%',
                                    margin: '0 auto'
                                }}
                            >
                                  <button
                                    className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width:"8rem"
                                }}
                                    onClick={() => setShowRevenueShareRateModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                  className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "20px",
                                    width:"8rem"
                                }}
                                    onClick={() => updateRevenueShare(selectedCompany, ownerId, selectedCompanyShareRate)}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </RevenueShareRateModal.Body>
            </RevenueShareRateModal>

            {/* Feedback Modal */}
            <InformationModal body="Are you sure you want to unapprove host?" title="Confirm Unapprove Host" isOpen={showModal} isClose={closeModal} onSubmit={unapproveSelectedHosts} acceptButton="Unapprove" declineButton="Cancel" >
                <div style={{fontWeight: 400, fontSize:"1.2rem"}}>Are you sure you want to unapprove hoddst?</div>
            </InformationModal>
            <InformationModal body="Are you sure you want to disable host?" title="Confirm Disable Host" isOpen={showDisableHostModal} isClose={() => setShowDisableHostModal(false)} onSubmit={() => disableHost(selectedCompany)} acceptButton="Disable" declineButton="Cancel" >
                <div style={{fontWeight: 400, fontSize:"1.2rem"}}>Are you sure you want to disable host?</div>
            </InformationModal>

            <FeedbackModall body="Your Changes have been implemented" title="Done" isOpen={showFeedbackModal} isClose={closeFeedbackDeclineModal} onSubmit={closeFeedbackDeclineModal} acceptButton="Okay" doneButton="Okay" declineButton="Cancel">
                <img src="assets/img/Web/Spacia/check.png" alt="check" />

                <div style={{ paddingTop: 10, fontWeight:400 }}>Your Changes have been declined</div>
            </FeedbackModall>
        </Container>
    );
};

export default ApprovedReservations;
