// @ts-nocheck

/**
 * Project: spaces
 * File: Cart
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import Items from '../../data/cart'
import CartItem from "../../components/CartItem";
import {PageTitle, RightComponent} from "../../layout/PageData";
import {EllipsisHorizontal} from "react-ionicons";
import {useDispatch, useSelector} from "react-redux";
import SERVICES from "../../services";
import axios from "axios";
import {Constants} from "../../utils/constants";
import demo from "../../assets/img/Web/Spacia/Rectangle 66.png";
import {resetTotalAmount, saveCartDetails, setCartEntries, setHasItems} from "../../redux/actions/dashboard";
import imgPlaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import {Booking, CartEntry, User} from '../../utils/interfaces';
import {Spinner} from "react-bootstrap";
import classnames from "classnames";
import Search from "../../components/Search";
import {v4 as uuidv4} from "uuid";
import {useHistory} from "react-router-dom";
import logger from "../../utils/logger";

const Cart:  React.FC =(props) => {
    const [entries, setEntries] = useState([] as Array<CartEntry>);
    const [totalAmount, setTotalAmount] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [cartUpdated, setCartUpdated] = useState(false);

    // @ts-ignore
    const allEntries = useSelector(state => state.dashboard.entries);
    // @ts-ignore
    const totalCartAmount = useSelector(state => state.dashboard.totalCartAmount);
    // @ts-ignore
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters);

    const dispatch = useDispatch();
    const history = useHistory();

    const [successAlert, setSuccessAlert] = useState(false);

    const [errorAlert, setErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const userCurrency = SERVICES.getCurrency();

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    }

    useEffect(() => {
        // redirect to sign in page when user is not logged in

        const user = SERVICES.getUser() as User;

        if (!isLoggedIn(user)) {
            history.push('/signin');
        }
    }, []);

    // @ts-ignore
    const cartDetails = useSelector(state => state.dashboard.cartDetails);
    useEffect(() => {
        // dispatch(saveCartDetails([]));
        // dispatch(setCartEntries());
        // dispatch(resetTotalAmount())

        const currentUser = SERVICES.getUser();

        if (currentUser != null) {
            const role = currentUser.role;
            setUserRole(role);

            const from = selectedFilters.from || '';
            const to = selectedFilters.to || '';

            setFromDate(from);
            setToDate(to);

            logger.log('Inside cart');
            logger.log('from:', from);
            logger.log('to:', to);

            // dispatch(addEntry({}));

            // setTotalAmount(totalCartAmount);
            //
            // logger.log('total cart amount: ', totalCartAmount);
            //
            // logger.log('all entries:', allEntries);
            //
            // const reversed = [...allEntries].reverse();
            // setEntries(reversed);
        }
    }, []);

    // re-render page anytime the total amount is updated
    useEffect(() => {
        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;
        logger.log('current cart id is:', cartId);

        // get all cart entries for current cart
        axios.get(`${Constants.BASE_URL}/booking/api/v1/order/get/cart/items`, { params: { cartId }})
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);

                    logger.log(res.data);
                    const cartItems = res.data.data as Array<CartEntry>;
                    let updated = cartItems.map(item => {
                        const startDate = item.startOn;
                        const endDate = item.endOn;

                        let updatedStartDate = startDate.slice(0, startDate.lastIndexOf(':'));
                        let updatedEndDate = endDate.slice(0, endDate.lastIndexOf(':'));

                        return structureEntry(item, updatedStartDate, updatedEndDate);
                    });

                    const entriesList = updated.sort(function(a, b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        // @ts-ignore
                        return new Date(b.createdOn) - new Date(a.createdOn);
                    });

                    // logger.log('a is: ', a);

                    logger.log('updated is:', updated);

                    const totalCartAmount = res.data.totalCartAmount;
                    setTotalAmount(totalCartAmount);

                    // @ts-ignore
                    setEntries(entriesList);
                }
            }).catch(err => {
                setRequestCompleted(true);
        })
    }, [cartUpdated])

    function structureEntry(cartDetails: any, from: string, to: string) {
        logger.log('cart details:', cartDetails);

        return {
            entryId: cartDetails.entryId,
            cartId: cartDetails.cartId,
            owner: {
                id: cartDetails.cartOwner.id,
                username: cartDetails.cartOwner.username
            },
            createdOn: cartDetails.createdOn,
            startOn: from,
            endOn: to,
            price: cartDetails.price,
            billingPeriod: cartDetails.itemInEntry.propertyPrice.billingPeriod,
            quantity: cartDetails.quantity,
            subTotal: cartDetails.subTotal,
            itemInEntry: {
                description: cartDetails.itemInEntry.description,
                image: cartDetails.itemInEntry.media || demo,
                // image: (cartDetails.itemInEntry.media.images && cartDetails.itemInEntry.media.images > 0) ? cartDetails.itemInEntry.media.images[0] : null,
                // imageDescription: cartDetails.itemInEntry.media.description
                imageDescription: cartDetails.itemInEntry.media || 'Nothing here',
                name: cartDetails.itemInEntry.name,
                subCategory: cartDetails.itemInEntry.subCategory,
                address: cartDetails.itemInEntry.location.address
            }
        };
    }

    const submitForApproval = () => {
        setLoading(true);
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/submit/for/approval`;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        logger.log('about to send request to', resourceUrl);

        const currentUser = SERVICES.getUser();

        const id = currentUser.id;

        const billingAccountId = currentUser.inviterId || id;


        axios.post(resourceUrl, null, {
            params: { "cartId": cartId, "userId": id, "billingAccountId": billingAccountId }})
            .then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    logger.log(res.data);

                    setEntries([]);
                    setTotalAmount(0);
                    setSuccessAlert(true)

                    // todo: change this later
                    dispatch(saveCartDetails([]));
                    dispatch(setCartEntries());
                    dispatch(resetTotalAmount());

                    dispatch(setHasItems(false));
                }
                else{
                    setErrorAlert(true);
                    setLoading(false);
                }
            }).catch(err => {
            setErrorAlert(true);
            setLoading(false);
        })
    }

    const acceptInvoice = () => {
        setLoading(true);
        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/accept/invoice`;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        logger.log('about to send request to', resourceUrl);

        const currentUser = SERVICES.getUser();

        const id = currentUser.id;

        const billingAccountId = currentUser.inviterId || id;

        axios.post(resourceUrl, {
            "cartId": cartId,
            "approverId": id,
            "billingUserId": billingAccountId
        }).then(res => {
            if (res.status === 200) {
                setLoading(false);
                logger.log(res.data.data);

                const bookingResponse = res.data.data as Array<Booking>;

                const bookingIdsArray = [] as Array<number>;
                bookingResponse && bookingResponse.forEach(booking => {
                    bookingIdsArray.push(booking.id);
                })

                const currentUser = SERVICES.getUser();

                const userId = currentUser && currentUser.id;

                const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/booking/email`;

                setEntries([]);
                setTotalAmount(0);
                setSuccessAlert(true)
                // todo: change this later
                dispatch(saveCartDetails([]));
                dispatch(setCartEntries());
                dispatch(resetTotalAmount());

                dispatch(setHasItems(false));

                // trigger booking confirmation email
                axios.get(resourceUrl, {params: {bookingIds: bookingIdsArray && bookingIdsArray.join(',')}})
                    .then(res => logger.log(res))
                    .catch(err => logger.log(err));
            }
        }).catch(err => {
            setErrorAlert(true);

            setLoading(false);
        })
    }

    const removeCartEntry = (id: number) => {
        logger.log('about to process request to remove cart entry');

        const resourceUrl = `${Constants.BASE_URL}/booking/api/v1/order/delete/entry/${id}`;

        axios.delete(resourceUrl)
            .then(res => {
                if (res.status === 200) {
                    setEntries(entries.filter(entry => entry.entryId !== id));
                    setCartUpdated(prev => !prev);
                }
            })
            .catch(err => {
                logger.log(err);
            });
    }

    useEffect(() => {
        if (entries.length === 0) {
            dispatch(setHasItems(false));
        } else {
            dispatch(setHasItems(true));
        }
    }, [entries]);

    const [requestCompleted, setRequestCompleted] = useState(false);

    const componentToRender = () => {
        if (requestCompleted && Array.isArray(entries) && entries.length > 0) {
            return (
                <>
                    {
                        entries &&
                        entries.map(entry => {
                            logger.log('entry values:', entry);
                            let image = undefined;

                            const listingItem = entry.itemInEntry;
                            const imageObj = listingItem && listingItem.image;
                            let imageArray = imageObj?.images;

                            const imageAtFirstIndex = imageArray && imageArray[0];

                            image = imageAtFirstIndex ? imageAtFirstIndex.resourceUrl : imgPlaceholder;

                            const billingPeriod = entry.billingPeriod;
                            const price = entry.price;

                            let overall = undefined;
                            if (price && billingPeriod) {
                                let period = null;
                                switch (billingPeriod) {
                                    case 'HOURLY':
                                        period = 'hour';
                                        break;

                                    case 'WEEKLY':
                                        period = 'week';
                                        break;

                                    case 'MONTHLY':
                                        period = 'month';
                                        break;

                                    case 'DAILY':
                                        period = 'day';
                                        break;

                                    default:
                                        period = 'month';
                                }

                                overall = `${price.toLocaleString()} per ${period}`;
                            } else if (price) {
                                overall = price;
                            } else {
                                overall = 'N/A';
                            }

                            return (
                                <CartItem key={entry.entryId} cartEntry={entry} image={image} />
                            )
                        })
                    }
                </>
            )
        } else if (requestCompleted && Array.isArray(entries) && entries.length === 0) {
            return (
                <div className='perfect-center'>
                    <p style={{fontWeight: 'bold', fontSize: '1.3rem'}}>No Items Found</p>
                </div>
            )
        } else if (!requestCompleted) {
            return (
                <div className='perfect-center'>
                    <Spinner size="sm" animation="border" role="status" style={{width: '50px', height: '50px'}}>
                        <span className="visually-hidden"></span>
                    </Spinner>{" "}
                </div>
            )
        }
    }

    // @ts-ignore
    return (
        <div className={classnames('section p-3 mobile-view with-spinner', {'mt-5': (requestCompleted && Array.isArray(entries) && entries.length > 0)})}>
            <PageTitle>Shortlist</PageTitle>
            <RightComponent> <div className={'card-button dropdown'}>
                <button type={'button'} className={'btn btn-link btn-icon'} data-bs-toggle="dropdown">
                    <div className="chip chip-media bg-transparent">
                        <i className={`chip-icon bg-primary`}>
                            <EllipsisHorizontal
                                cssClasses={'md hydrated'}
                                color={'#ffffff'}
                            />
                        </i>
                    </div>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                       Remove Item
                    </a>

                </div>
            </div></RightComponent>
            {
                componentToRender()
            }
            {/*{Items.map(({name, location, date, amount,image, border},key)=><CartItem key={key.toString()} name={name} location={location} amount={amount} image={image} date={date} border={border} />)}*/}

            <div className={'mt-4'} style={{display: (entries.length > 0) ? 'block' : 'none'}}>
                <button role={'button'} className={'btn btn-primary btn-block btn-lg square'}> Confirm to Remove Item</button>
            </div>

            <div className={'mt-4'} style={{display: (entries.length > 0) ? 'block' : 'none'}}>
                <h3>Invoice Details</h3>
                <ul className="listview flush transparent simple-listview no-space mt-3">

                    {/*<li>*/}
                    {/*    <strong>Subtotal</strong>*/}
                    {/*    <span>KSH105,000</span>*/}
                    {/*</li>*/}
                    <li>
                        <h3>Total</h3>
                        <h3>{userCurrency} {totalAmount.toLocaleString() || 0}</h3>
                    </li>
                </ul>
            </div>
            <div className={'d-flex justify-content-between mt-2'} style={{display: (entries.length > 0) ? 'block' : 'none'}}>
              {/*<button type={'button'} className={'btn btn-secondary btn-lg text-dark'}>Decline Invoice</button>*/}
              <button
                  type={'button'}
                  disabled={entries.length === 0}
                  className={'btn btn-primary btn-lg'}
                  onClick={(userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL') ? submitForApproval : acceptInvoice}
                  style={{display: (entries.length > 0) ? 'block' : 'none'}}
              >
                  {(userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL') ? 'Submit For Approval' : 'Book'}
                  <div style={{display: loading ? 'inline-block' : 'none', paddingLeft: '5px', paddingRight: '15px', width: '10px', height: '10px'}}>
                      <Spinner animation="border" role="status">
                          <span className="visually-hidden" />
                      </Spinner>
                  </div>
              </button>
            </div>
        </div>
    )
}
export default Cart
