// @ts-nocheck

/**
 * Project: spaces
 * File: RegisterHost
 * Created by Cascade
 * Copyright spaces
 */

import React, { FormEvent, useState } from "react";
import { CloseCircle, EyeOutline, EyeOffOutline } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import { PageTitle, RightComponent, LeftComponent } from "../../layout/PageData";
import { toAbsoluteUrl } from "../../layout/helpers";
import apiService from "../../services/apiService";
import { toast } from 'react-toastify';
import { Modal } from "react-bootstrap";
import successImage from '../../assets/img/png/success-with-bubbles.png';
import closeButton from "../../assets/img/svg/blue-close-button.svg";
import logger from "../../utils/logger";
import PhoneInput from 'react-phone-number-input';

const StyledInput = ({
    type,
    id,
    label,
    value,
    onChange,
    showPasswordToggle = false,
    required = true
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <input
                    type={showPasswordToggle ? (showPassword ? "text" : "password") : type}
                    className="form-control input-new-rounded floating-input"
                    id={id}
                    placeholder=" "
                    value={value}
                    onChange={onChange}
                    required={required}
                    style={{
                        backgroundColor: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor={id}
                    style={{
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.02em",
                        color: "#263238",
                    }}
                >
                    {label}
                </label>
                <i className="clear-input">
                    {showPasswordToggle ? (
                        <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                            {showPassword ? (
                                <EyeOffOutline color={"#00000"} title={"Hide password"} />
                            ) : (
                                <EyeOutline color={"#00000"} title={"Show password"} />
                            )}
                        </div>
                    ) : (
                        value && <CloseCircle
                            color={"#00000"}
                            title={"Clear"}
                            onClick={() => onChange({ target: { value: '' } })}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </i>
            </div>
        </div>
    );
};

const StyledPhoneInput = ({ value, onChange }) => {
    return (
        <div className="form-group basic">
            <div className="input-wrapper">
                <PhoneInput
                    international
                    defaultCountry="KE"
                    value={value}
                    onChange={onChange}
                    className="input-new-rounded floating-input"
                    style={{
                        border: '1px solid #E4E6EF',
                        borderRadius: '40px',
                        padding: '0.75rem 1rem',
                        background: 'white',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        WebkitTextFillColor: '#000'
                    }}
                />
                <label
                    className="floating-label-new-background"
                    htmlFor="phone"
                    style={{
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.02em",
                        color: "#263238",
                        transform: value ? 'translateY(-20px) scale(0.8)' : 'none'
                    }}
                >
                    Phone Number
                </label>
            </div>
            <style jsx>{`
                .PhoneInput {
                    margin: 0;
                    padding: 0;
                }
                
                .PhoneInputInput {
                    border: none !important;
                    background: white !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    outline: none !important;
                    width: 100% !important;
                }

                .PhoneInputInput:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }

                .PhoneInputCountry {
                    margin-right: 8px;
                }
            `}</style>
        </div>
    );
};

const RegisterHost = () => {
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('ROLE_HOST');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState<boolean>(false);

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        logger.log('Terms checkbox changed:', checked);
        setTermsAgreed(checked);
    };
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const roleOptions = [
        { value: 'ROLE_HOST', label: 'Host' },
        { value: 'ROLE_SUBSCRIBER_INDIVIDUAL', label: 'Individual' }
    ];

    const handleRegister = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        if (!firstName || !lastName || !email || !phone || !password || !confirmPassword) {
            setError('Please fill in all required fields');
            setIsLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            setIsLoading(false);
            return;
        }

        if (!termsAgreed) {
            setError('Please agree to the Terms & Conditions');
            setIsLoading(false);
            return;
        }

        logger.log('Registration Payload:');
        const registrationPayload = {
            avatar: "https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg",
            companyId: 1,
            confirmPassword,
            contacts: [
                {
                    isContactable: true,
                    isPrimary: true,
                    type: "mobile",
                    value: phone,
                }
            ],
            firstName,
            lastName,
            password,
            username: email,
            role
        };

        logger.log(JSON.stringify(registrationPayload));
        logger.log('Registration request: POST https://api.spacia.africa/users/api/v1/users');



        try {
            const response = await apiService.signup(registrationPayload);

            if (response.status === 201) {
                toast.success('Account created successfully! Please check your email for verification.', {
                    position: "top-right",
                    theme: "colored",
                    autoClose: 8000
                });

                // Navigate to sign in immediately
                history.push('/signin');

                // Show success modal
                setShowSuccessModal(true);

                // Close modal after 7 seconds
                setTimeout(() => {
                    setShowSuccessModal(false);
                }, 7000);
            }
        } catch (err) {
            logger.error("Registration error:", err);
            const errorMessage = err.response?.data?.message || 'Registration failed. Please try again.';
            toast.error(errorMessage, {
                position: "top-right",
                theme: "colored"
            });
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="position-relative overflow-hidden" style={{ marginTop: "-56px" }}>
                <PageTitle>{false}</PageTitle>
                <RightComponent>{false}</RightComponent>
                <LeftComponent>{false}</LeftComponent>

                <div className="section position-relative p-0">
                    <img
                        className="w-100"
                        decoding={"async"}
                        src={toAbsoluteUrl("/img/Rectangle 18.png")}
                        alt={"Logo"}
                    />

                    <div className="section mt-2 position-absolute"
                        style={{ bottom: "88px", left: "16px" }}>
                        <h2 className={"text-capitalize text-white"}>Sign Up</h2>
                        <h4 className={"mt-2 text-white"}>Join SPACIA as a Host or Individual!</h4>
                    </div>
                </div>

                <div className="section mb-5 p-2 position-relative"
                    style={{
                        backgroundColor: "white",
                        borderTopRightRadius: "30px",
                        borderTopLeftRadius: "30px",
                        marginTop: "-21px",
                    }}>
                    <form onSubmit={handleRegister}>
                        <div className="card-body pb-1">
                            <div className="form-group basic mb-3">
                                <div className="input-wrapper">
                                    <select
                                        className="form-control input-new-rounded floating-input"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        style={{
                                            backgroundColor: 'white',
                                            WebkitBoxShadow: '0 0 0 1000px white inset',
                                            WebkitTextFillColor: '#000'
                                        }}
                                    >
                                        {roleOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        className="floating-label-new-background"
                                        style={{
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            letterSpacing: "0.02em",
                                            color: "#263238",
                                            transform: 'translateY(-20px) scale(0.8)'
                                        }}
                                    >
                                        Sign up as
                                    </label>
                                </div>
                            </div>
                            <StyledInput
                                type="text"
                                id="firstName"
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <StyledInput
                                type="text"
                                id="lastName"
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <StyledInput
                                type="email"
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <StyledPhoneInput
                                value={phone}
                                onChange={setPhone}
                            />
                            <StyledInput
                                type="password"
                                id="password"
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                showPasswordToggle={true}
                            />
                            <StyledInput
                                type="password"
                                id="confirmPassword"
                                label="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                showPasswordToggle={true}
                            />

                            <div className="mb-3" style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                                <div
                                    onClick={() => setTermsAgreed(!termsAgreed)}
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        border: '2px solid #0D6EFD',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: termsAgreed ? '#0D6EFD' : 'white',
                                        flexShrink: 0,
                                        marginTop: '2px'
                                    }}
                                >
                                    {termsAgreed && (
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3334 4L6.00008 11.3333L2.66675 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )}
                                </div>
                                <div
                                    onClick={() => setTermsAgreed(!termsAgreed)}
                                    style={{
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        color: '#263238',
                                        userSelect: 'none',
                                        flex: 1
                                    }}
                                >
                                    I agree to the <a href="/legal/terms" target="_blank" style={{ color: '#0D6EFD', textDecoration: 'none', fontWeight: 600 }}>Terms & Conditions</a>
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg w-100"
                                    style={{ borderRadius: "40rem", height: "48px" }}
                                    disabled={isLoading || !termsAgreed}
                                >
                                    {isLoading ? (
                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : 'Create Account'}
                                </button>

                                <div className="text-center">
                                    <p className="text-dark mb-0" style={{ fontSize: '14px' }}>
                                        Already have an account? <Link to="/signin" style={{ color: '#0D6EFD', textDecoration: 'none', fontWeight: 600 }}>Sign In</Link>
                                    </p>
                                </div>

                                <div className="text-center mt-3">
                                    <Link 
                                        to="/home" 
                                        className="d-block mb-3" 
                                        style={{ 
                                            color: '#4F46E5', 
                                            textDecoration: 'none', 
                                            fontSize: '0.9rem',
                                            fontWeight: '500'
                                        }}
                                    >
                                        ← Go to Home
                                    </Link>
                                    <p className="text-muted mb-1" style={{ fontSize: '0.7rem', opacity: 0.6 }}>
                                        Powered by theSOFTtribe
                                    </p>
                                    <p className="text-muted text-capitalize mb-0" style={{ fontSize: '12px' }}>
                                        &copy;2024 SPACIA All rights reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {error && (
                    <div className="alert alert-danger mt-3">
                        {error}
                    </div>
                )}
            </div>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => setShowSuccessModal(false)}>
                        <img src={closeButton} alt="close" />
                    </button>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={successImage} alt="Success" className="mb-4" />
                    <h3>Account Created Successfully!</h3>
                    <p>Please check your email to verify your account.</p>
                    <p>Once verified, you can log in to access your account.</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RegisterHost;
