import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

interface LogoutModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ show, onClose, onConfirm }) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const handleConfirm = async () => {
        setIsLoggingOut(true);
        await onConfirm();
        setIsLoggingOut(false);
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            style={{
                borderRadius: "12px"
            }}
        >
            <Modal.Header
                style={{
                    border: 'none',
                    padding: '15px 15px 0 15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    minHeight: '40px'
                }}
            >
                <button
                    onClick={onClose}
                    style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '20px',
                        color: '#000',
                        padding: '0',
                        margin: '0',
                        opacity: 0.5,
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '15px',
                        top: '10px'
                    }}
                    aria-label="Close"
                >
                    ×
                </button>
            </Modal.Header>
            <Modal.Body
                className="text-center"
                style={{
                    padding: "5px 20px 24px 20px"
                }}
            >
                <h5
                    style={{
                        fontSize: "18px",
                        color: "#0D6EFD",
                        marginTop: "10px",
                        marginBottom: "24px",
                        fontWeight: "normal"
                    }}
                >
                    Are you sure you want to logout?
                </h5>
                <div className="d-flex justify-content-center gap-3">
                    <button
                        className="btn"
                        style={{
                            borderRadius: "100px",
                            width: "120px",
                            border: "1px solid #0D6EFD",
                            color: "#0D6EFD",
                            background: "white"
                        }}
                        onClick={onClose}
                        disabled={isLoggingOut}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn"
                        style={{
                            borderRadius: "100px",
                            width: "120px",
                            border: "1px solid #0D6EFD",
                            color: "#0D6EFD",
                            background: "white"
                        }}
                        onClick={handleConfirm}
                        disabled={isLoggingOut}
                    >
                        {isLoggingOut ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Logging out...</span>
                            </div>
                        ) : 'Confirm'}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LogoutModal;
