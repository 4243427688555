/**
 * Project: spaces
 * File: ReservationCard
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import {EllipsisHorizontal, Location} from "react-ionicons";
import {data} from "../data/reservations";
import {Booking, PropertyImage, Status} from "../utils/interfaces";
import moment from "moment";
import logger from "../utils/logger";

interface Props {
    booking: Booking;
    hasIcon: boolean;
    showCancelBookingModal: any;
}
const ReservationCard: (props: Props) => JSX.Element = (props: Props) => {
    const { booking, hasIcon, showCancelBookingModal } = props;

    const [chipBackground, setChipBackground] = useState('');
    const [propertyDesc, setPropertyDesc] = useState('');
    const [address, setAddress] = useState('');
    const [startOn, setStartOn] = useState('');
    const [endOn, setEndOn] = useState('');
    const [propertyImage, setPropertyImage] = useState('');
    const [isCancelled, setIsCancelled] = useState(false);

    useEffect(() => {
        const status = booking.status;
        let chipBg = '';
        if (status != null) {
            const value = status.value;
            if (value === 'active') {
                setChipBackground('bg-success');
            } else {
                setChipBackground('bg-warning');
                if (value === 'cancelled') {
                    setIsCancelled(true);
                }
            }
        }

        const listingItem = booking.listingItem;
        const description = listingItem?.description;
        setPropertyDesc(description || '');

        const location = listingItem?.location;
        const address = location?.address;
        setAddress(address || '');

        const media = listingItem?.media;
        const mediaImages = media?.images;
        const anyRandomImage = (mediaImages != null && mediaImages.length > 0)
            ? mediaImages[0] : {} as PropertyImage;
        const imageUrl = anyRandomImage.resourceUrl;
        setPropertyImage(imageUrl);

        const startOn = booking.startOn;
        const from = formatDate(startOn);
        setStartOn(from);

        const endOn = booking.endOn;
        const to = formatDate(endOn);
        setEndOn(to);
    }, [booking]);

    const truncateText = (text: string) => {
        if (text.length > 45) {
            return text.substring(0, 44) + '...';
        } else {
            return text;
        }
    }

    function formatDate(date: string) {
        let formattedDate = '-';

        try {
            if (date != null) {
                formattedDate = moment(date).format('DD/MM/yyyy');
            }
        } catch (e) {
            logger.log('an error occurred while converting string to Date');
        }

        return formattedDate;
    }

    const displayCancelBookingModal = (e: any) => {
        e.preventDefault();
        showCancelBookingModal(booking.id)
    }

    return (
        <div className="card reservation mb-2" style={{ 
            borderRadius: '12px', 
            border: 'none', 
            boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
        }}>
            <div className={'d-flex p-3'}>
                <div className="reservation-image me-3" style={{
                    width: '60px',
                    height: '60px',
                    flexShrink: 0,
                    position: 'relative',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}>
                    {propertyImage ? (
                        <img 
                            src={propertyImage} 
                            alt={propertyDesc} 
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#e9ecef',
                            borderRadius: '4px'
                        }}>
                            <img 
                                src="/assets/img/Web/Spacia/property-location-mobile.svg" 
                                alt="Property Icon"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    opacity: 0.5
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={'flex-grow-1 min-width-0'}>
                    <div className={'d-flex justify-content-between align-items-start mb-1'}>
                        <div className="min-width-0" style={{ flex: 1 }}>
                            <h6 className="mb-1 text-truncate" style={{ 
                                fontSize: '14px', 
                                fontWeight: 600,
                                color: '#333',
                                lineHeight: '1.3'
                            }}>
                                {propertyDesc || 'Unnamed Property'}
                            </h6>
                            <div className={'d-flex align-items-center'}>
                                <Location
                                    color={'#9E9E9E'}
                                    title={''}
                                    width={'13px'}
                                    height={'13px'}
                                />
                                <small className="text-muted ms-1 text-truncate" style={{ 
                                    fontSize: '12px',
                                    lineHeight: '1.2'
                                }}>
                                    {address || 'No address provided'}
                                </small>
                            </div>
                        </div>
                        {hasIcon && !isCancelled && (
                            <div className={'card-button dropdown ms-2'} style={{ flexShrink: 0 }}>
                                <button 
                                    type={'button'} 
                                    className={'btn btn-link btn-icon p-0'} 
                                    style={{ marginTop: '-5px' }}
                                    data-bs-toggle="dropdown"
                                >
                                    <EllipsisHorizontal
                                        cssClasses={'md hydrated'}
                                        color={'#666'}
                                        height="20px"
                                        width="20px"
                                    />
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item text-danger" href="#" onClick={displayCancelBookingModal}>
                                        <span style={{ fontSize: '13px' }}>Cancel Reservation</span>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="reservation-details mt-2">
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <div style={{ flex: 1 }}>
                                <small className="text-muted d-block mb-1" style={{ 
                                    fontSize: '11px', 
                                    letterSpacing: '0.3px',
                                    lineHeight: '1'
                                }}>From</small>
                                <div style={{ 
                                    fontSize: '13px', 
                                    fontWeight: 500, 
                                    color: '#444',
                                    lineHeight: '1.2'
                                }}>{startOn}</div>
                            </div>
                            <div style={{ width: '1px', height: '30px', background: '#eee', margin: '0 15px' }}></div>
                            <div style={{ flex: 1 }}>
                                <small className="text-muted d-block mb-1" style={{ 
                                    fontSize: '11px', 
                                    letterSpacing: '0.3px',
                                    lineHeight: '1'
                                }}>To</small>
                                <div style={{ 
                                    fontSize: '13px', 
                                    fontWeight: 500, 
                                    color: '#444',
                                    lineHeight: '1.2'
                                }}>{endOn}</div>
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between align-items-center mt-2 pt-2'} style={{ borderTop: '1px solid #f5f5f5' }}>
                            <div className="d-flex align-items-center flex-grow-1 min-width-0">
                                <small className="text-muted text-truncate" style={{ fontSize: '12px' }}>
                                    {booking.requestingUser?.firstName} {booking.requestingUser?.lastName}
                                </small>
                            </div>
                            <div className="ms-2" style={{ flexShrink: 0 }}>
                                <span 
                                    className={`badge ${chipBackground}`} 
                                    style={{ 
                                        textTransform: 'capitalize',
                                        fontSize: '11px',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        fontWeight: 500,
                                        opacity: isCancelled ? 0.75 : 1,
                                        lineHeight: '1.1',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {booking.status?.value}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReservationCard
