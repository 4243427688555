import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../layout/helpers";
import successImage from '../../../assets/img/png/success-with-bubbles.png';
import rejectedImage from '../../../assets/img/png/rejected-image.png';
import closeButton from "../../../assets/img/svg/blue-close-button.svg";
import apiService from '../../../services/apiService';
import SERVICES from '../../../services';
import { resetBundleQuantity } from '../../../redux/actions/dashboard';
import logger from "../../../utils/logger";
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from '../../../components/mobile/ConfirmModal';

const PackageSelection: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // Get current user from service instead of redux
    const currentUser = useMemo(() => SERVICES.getUser(), []);

    // Local state
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [currency, setCurrency] = useState("KES");
    const [selectedBundle, setSelectedBundle] = useState<any>(null);

    const organizationSize = useMemo(() => {
        const size = parseInt(localStorage.getItem("organizationSize") || "0");
        return Math.max(0, size); // Ensure non-negative
    }, []);

    const getPreferredBundleType = (): string => {
        let preferredBundleType = "LOW";
        if (organizationSize >= 20) {
            preferredBundleType = "HIGH";
        } else if (organizationSize > 10 && organizationSize < 20) {
            preferredBundleType = "MEDIUM";
        }
        return preferredBundleType;
    };

    useEffect(() => {
        const fetchBundles = async () => {
            try {
                const res = await apiService.getAllBundles();
                const { data } = res.data;

                if (data && data.length > 0) {
                    const preferredBundleType = getPreferredBundleType();
                    const filteredBundles = data.filter(
                        (bundle: any) => bundle.bundleType.value === preferredBundleType
                    );

                    dispatch(resetBundleQuantity(filteredBundles));
                    setSelectedBundle(filteredBundles[0]);
                    const cost = filteredBundles[0]?.pricing?.cost || 0;
                    const total = Math.max(0, cost * organizationSize);
                    setTotalOrderAmount(total);
                    setCurrency(filteredBundles[0]?.pricing?.currency || "KES");
                }
            } catch (error) {
                logger.error('Error fetching bundles:', error);
                toast.error('Failed to load packages. Please try again.');
            }
        };

        fetchBundles();
    }, []);

    const handleConfirmOrder = async () => {
        if (!selectedBundle) {
            toast.error('Please select a package to continue.');
            return;
        }
        
        if (!currentUser?.id) {
            toast.error('User information is missing. Please log in again.');
            return;
        }

        if (organizationSize <= 0) {
            toast.error('Please set a valid organization size.');
            return;
        }

        setIsLoading(true);
        try {
            const bundleRequest = {
                ownerId: currentUser.id,
                bundleTypeAndQuantityList: [
                    {
                        bundleType: selectedBundle.bundleType.value,
                        quantity: parseInt(organizationSize.toString())
                    }
                ]
            };

            logger.log('Sending bundle request:', bundleRequest);
            
            // Use the correct API endpoint
            const res = await apiService.createBundlePurchaseOrder(bundleRequest);
            
            if (!res?.data?.data) {
                throw new Error('Failed to get order summary');
            }
            
            const responseData = res.data.data;

            logger.log('Confirm order API response:', res.data);
            logger.log('Response data:', responseData);

            if (!responseData) {
                throw new Error('No response data received from the API');
            }

            // Save in local storage exactly like desktop
            SERVICES.setConfirmOrderResponse(responseData);

            // Navigate to summary page with exact same state structure as desktop
            history.push({
                pathname: '/package-order-summary',
                state: {
                    currency: currency,
                    summary: responseData,
                    referer: 'package-signup'
                }
            });
            // }
        } catch (error: any) {
            logger.error('Failed to confirm order:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to process order. Please try again.';
            setErrorMsg(errorMessage);
            setShowFailureModal(true);
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const logoPath = '/assets/img/new-logo.svg';

    return (
        <div style={{ padding: '20px' }}>
            <div className="text-center mb-6">
                <img
                    alt="Logo"
                    src={logoPath}
                    className="h-16px mb-4"
                />
            </div>
            <div className="text-center mb-4">
                <h2 style={{ fontSize: '24px', fontWeight: '600', marginBottom: '12px' }}>Confirm Your Package</h2>
                <p className="text-muted" style={{ fontSize: '16px' }}>
                    Based on your team size of {organizationSize}, we recommend:
                </p>
            </div>

            {selectedBundle && (
                <div className="package-details">
                    <div className="package-card" style={{
                        background: '#fff',
                        borderRadius: '12px',
                        padding: '24px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        marginBottom: '24px'
                    }}>
                        <h3 style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#0D6EFD',
                            marginBottom: '16px'
                        }}>
                            {selectedBundle.bundleType?.label || 'Corporate Plus'}
                        </h3>

                        <div className="price-tag" style={{ marginBottom: '20px' }}>
                            <div style={{ fontSize: '28px', fontWeight: '700', color: '#2B2F38' }}>
                                {currency} {selectedBundle.pricing?.cost.toLocaleString()}
                                <span style={{ fontSize: '16px', fontWeight: '400', color: '#6B7280' }}> /user/month</span>
                            </div>
                        </div>

                        <div className="features">
                            {selectedBundle.offers?.map((offer: any, index: number) => (
                                <div key={index} className="feature-item" style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    marginBottom: '12px',
                                    fontSize: '15px',
                                    color: '#4B5563'
                                }}>
                                    <i className="fas fa-check-circle text-primary me-2 mt-1"></i>
                                    <span>
                                        {offer.propertyType.label}: {offer.accessDuration.duration} {offer.accessDuration.periodType.value}
                                        {offer.allowedNumberOfCheckIns === -1 ? ' (Unlimited)' : ` (${offer.allowedNumberOfCheckIns} check-ins)`}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="order-summary" style={{
                        background: '#F9FAFB',
                        borderRadius: '12px',
                        padding: '20px',
                        marginBottom: '24px'
                    }}>
                        <h4 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '16px' }}>Order Summary</h4>
                        <div className="summary-item d-flex justify-content-between mb-2" style={{ fontSize: '15px' }}>
                            <span>Cost per User</span>
                            <span>{currency} {selectedBundle.pricing?.cost.toLocaleString()}</span>
                        </div>
                        <div className="summary-item d-flex justify-content-between mb-2" style={{ fontSize: '15px' }}>
                            <span>Number of Users</span>
                            <span>{organizationSize}</span>
                        </div>
                        <div className="summary-item d-flex justify-content-between pt-3"
                            style={{
                                borderTop: '1px solid #E5E7EB',
                                marginTop: '12px'
                            }}>
                            <strong style={{ fontSize: '16px' }}>Total Amount</strong>
                            <strong style={{ fontSize: '16px', color: '#0D6EFD' }}>
                                {currency} {totalOrderAmount.toLocaleString()}
                            </strong>
                        </div>
                    </div>

                    <div className="d-flex gap-3">
                        <button
                            className="btn btn-outline-primary flex-grow-1"
                            onClick={() => history.goBack()}
                            style={{ borderRadius: '8px' }}
                        >
                            Back
                        </button>
                        <button
                            className="btn btn-primary flex-grow-1"
                            onClick={handleConfirmOrder}
                            disabled={isLoading}
                            style={{ borderRadius: '8px' }}
                        >
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Processing...
                                </>
                            ) : (
                                "Continue"
                            )}
                        </button>
                    </div>
                </div>
            )}
            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Body className="text-center p-4">
                    <img src={successImage} alt="Success" className="mb-4" style={{ width: '100px' }} />
                    <h3>Order Confirmed!</h3>
                    <p>Your package order has been successfully processed.</p>
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => history.push('/package-order-summary')}
                    >
                        View Order Summary
                    </button>
                </Modal.Body>
            </Modal>

            {/* Failure Modal */}
            <Modal show={showFailureModal} onHide={() => setShowFailureModal(false)} centered>
                <Modal.Body className="text-center p-4">
                    <img src={rejectedImage} alt="Failed" className="mb-4" style={{ width: '100px' }} />
                    <h3>Order Failed</h3>
                    <p>{errorMsg}</p>
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => setShowFailureModal(false)}
                    >
                        Try Again
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PackageSelection;
