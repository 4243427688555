/**
 * Project: spaces
 * File: Search
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from "../layout/helpers";
import {Location} from "react-ionicons";
import {Search as Props} from "../data/search";
import { useHistory   } from 'react-router-dom'
import imgplaceholder from "../assets/img/Web/Spacia/imgplaceholder.png";
import SERVICES from "../services"
import logger from "../utils/logger";

const Search: (props: Props) => JSX.Element = ({listing, fromPropertiesPage}) => {
    const history = useHistory();

    const [location, setLocation] = useState('');
    const [price, setPrice] = useState('');
    const [listingName, setListingName] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        const location = listing.location;
        const address = location ? location.address : '-';

        logger.log('location address:', address);
        setLocation(address);

        const name = (listing.name) ? listing.name : '-';
        setListingName(name);

        // get property price
        const propertyPrice = listing.propertyPrice;
        const price = propertyPrice && propertyPrice.price;
        const billingPeriod = propertyPrice && propertyPrice.billingPeriod;

        let overall = '-';
        if (price && billingPeriod) {
            let period = null;
            switch (billingPeriod) {
                case 'HOURLY':
                    period = 'hour';
                    break;

                case 'WEEKLY':
                    period = 'week';
                    break;

                case 'MONTHLY':
                    period = 'month';
                    break;

                case 'DAILY':
                    period = 'day';
                    break;

                default:
                    period = 'month';
            }

            overall = `${price.toLocaleString()}/${period}`;
        } else if (price) {
            overall = `${price}`;
        } else {
            overall = 'N/A';
        }

        setPrice(overall);

        const image = listing.media && listing.media.images[0] ?
            listing.media.images[0].resourceUrl :
            imgplaceholder;

        setImage(image);
    }, []);

    const handleClick = () => {
        const locationState = {
            source: (fromPropertiesPage) ? 'properties-page' : null,
            listing
        }
        history.push('/details', locationState);
    }

    const truncateText = (text: string) => {
        if (text.length > 25) {
            return text.substring(0, 24) + '...';
        } else {
            return text;
        }
    }

    const currentUser = SERVICES.getUser();
    const allowedRoles = ['ROLE_HOST', 'ROLE_HOST_OPERATIONS', 'ROLE_ADMINISTRATOR'];
    const showPrice = allowedRoles.includes(currentUser.role);
    logger.log(currentUser)
    
    return (
        <div className={'card shadowed mb-3'} role={'button'} style={{ height: '100px' }} onClick={handleClick}>
            <div className={'card-body position-relative h-100  pb-0 pl-145px '} style={{ paddingTop: '10px' }}>
                <img src={toAbsoluteUrl(`${image}`)} alt={'img'} className="position-absolute top-0 start-0 bottom-0 imaged-left h-inherit w130 " />
                <div className="chip chip-success mb-05 position-absolute top-0 bottom-0 left-57px r-top-right-0 r-bottom-right-0">
                    <span className="chip-label">Available</span>
                </div>
                <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <h4 className={'fw-bold'}>{listingName}</h4>
                    <div className={'w-100'} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <Location
                            color={'#6E7070'}
                            title={''}
                            width={'16px'}
                            height={'16px'}
                        />
                        <p className={'p-0 fontsize-sub d-inline'} style={{ width: '100%', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {location}
                        </p>
                    </div>
                    {showPrice && (
                        <p className={'p-0 fontsize-sub mb-0 position-absolute bottom-0'}>
                            {price}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
    
}

export default Search
