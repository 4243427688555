import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../layout/helpers";
import apiService from "../../services/apiService";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../components/Settings/alerts/SuccessAlert";
import { CloseCircle } from "react-ionicons";
import { AxiosError } from "axios";
import { usePageData } from "../../layout/PageData";
import logger from "../../utils/logger";

interface ApiErrorResponse {
    errors?: Array<{
        message: string;
    }>;
    data?: any;
}

const ResetPasswordMobile = () => {
    const [user, setUser] = useState('');
    const [successAlert, setSuccessAlert] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { setPageTitle, setLeftComponent, setRightComponent } = usePageData();

    useEffect(() => {
        setPageTitle(false);
        setLeftComponent(false);
        setRightComponent(false);
    }, [setPageTitle, setLeftComponent, setRightComponent]);

    const resetButton = async () => {
        if (!user || isLoading) return;

        setIsLoading(true);
        setResetError(false);
        setErrorMsg('');

        try {
            const response = await apiService.resetPassword(user);
            logger.log(response);
            setSuccessAlert(true);
            history.push("/gotomail");
        } catch (err) {
            const error = err as AxiosError<ApiErrorResponse>;
            logger.log(error.response?.data);
            const errors = error.response?.data?.errors || [];
            const errorMsg = errors.length > 0 ? errors[0].message : 'An error occurred. Please try again.';
            setErrorMsg(errorMsg);
            setResetError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="position-relative overflow-hidden" style={{ marginTop: "-56px" }}>
            <div className="section position-relative p-0">
                <img
                    className="w-100"
                    decoding={"async"}
                    src={toAbsoluteUrl("/img/Rectangle 18.png")}
                    alt={"Logo"}
                />

                <div className="section mt-2 position-absolute"
                    style={{ bottom: "88px", left: "16px" }}>
                    <h2 className={"text-capitalize text-white"}>Reset Password</h2>
                    <h4 className={"mt-2 text-white"}>Get back to your account!</h4>
                </div>
            </div>

            <div className="section mb-5 p-2 position-relative"
                style={{
                    backgroundColor: "white",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                    marginTop: "-21px",
                    transform: "scaleX(1.02)",
                    transformOrigin: "center",
                }}>
                <div className="card-body pb-1">
                    <SuccessAlert
                        message={"Reset link has been sent to your email"}
                        show={successAlert}
                        close={() => setSuccessAlert(false)}
                    />
                    <ErrorAlert
                        message={errorMsg}
                        show={resetError}
                        close={() => setResetError(false)}
                    />

                    <div className="mt-2 text-center">
                        <p className="text-muted">
                            Please provide your email address below so we can send you a link to reset your password
                        </p>
                    </div>

                    <div className="form-group basic">
                        <div className="input-wrapper">
                            <input
                                type="email"
                                className="form-control input-new-rounded floating-input"
                                id="email1"
                                autoComplete="username"
                                placeholder=" "
                                value={user}
                                onChange={(e) => setUser(e.target.value)}
                                style={{
                                    backgroundColor: 'white',
                                    WebkitBoxShadow: '0 0 0 1000px white inset',
                                    WebkitTextFillColor: '#000'
                                }}
                            />
                            <label
                                className="floating-label-new-background"
                                htmlFor="email1"
                            >
                                E-mail
                            </label>
                            {user && (
                                <i className="clear-input">
                                    <CloseCircle
                                        color={"#00000"}
                                        title={"Clear"}
                                        onClick={() => setUser('')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </i>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-3">
                        <button
                            className="btn btn-primary btn-lg w-100"
                            disabled={!user || isLoading}
                            onClick={resetButton}
                            style={{
                                borderRadius: "40rem",
                                cursor: !user || isLoading ? 'not-allowed' : 'pointer'
                            }}
                        >
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : 'Request Password Reset'}
                        </button>

                        <div className="text-center">
                            <p className="text-dark mb-0">
                                Want to login? <Link to="/signin" style={{ color: '#0D6EFD', textDecoration: 'none' }}>Sign In</Link>
                            </p>
                        </div>

                        <div className="text-center mt-3">
                            <Link 
                                to="/home" 
                                className="d-block mb-3" 
                                style={{ 
                                    color: '#4F46E5', 
                                    textDecoration: 'none', 
                                    fontSize: '0.9rem',
                                    fontWeight: '500'
                                }}
                            >
                                ← Go to Home
                            </Link>
                            <p className="text-muted mb-1" style={{ fontSize: '0.7rem', opacity: 0.6 }}>
                                Powered by theSOFTtribe
                            </p>
                            <p className="text-muted text-capitalize mb-0">
                                &copy;2024 SPACIA All rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordMobile;
