import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from "../layout/helpers";
import {
    LogoFacebook,
    LogoInstagram,
    LogoTwitter,
} from "react-ionicons";
import { User } from '../utils/interfaces';
import SERVICES from "../services";

const Footer = () => {
    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    };

    const redirectBasedOnRole = () => {
        const currentUser = SERVICES.getUser() as User;
        let destination = "/startBooking";

        if (!isLoggedIn(currentUser)) {
            destination = "/signin";
        } else {
            const role = currentUser.role;

            switch (role) {
                case 'ROLE_ADMINISTRATOR':
                    destination = "/dashboard";
                    break;
                case 'ROLE_SUBSCRIBER_OWNER':
                    destination = "/startBooking";
                    break;
                default:
                    destination = "/properties";
            }
        }
        return destination;
    };

    return (
        <footer style={{ backgroundColor: "#000000", paddingTop: "5rem", paddingBottom: "3rem" }} className="position-relative overflow-hidden">
            <div className="container py-4">
                <div className="row g-4 justify-content-between">
                    <div className="col-lg-4 col-md-6">
                        <div className="pe-lg-5">
                            <img 
                                decoding="async" 
                                src={toAbsoluteUrl("/img/logo-black-alt.png")} 
                                alt="logo" 
                                style={{ width: "110px", height: "110px" }}
                                className="mb-3" 
                            />
                            <p className="text-white-50 mb-3" style={{fontSize: "0.9rem"}}>
                                Discover and book the perfect workspace for your needs. Join our community of professionals and businesses finding their ideal space.
                            </p>
                            <div className="d-flex gap-3 social-links">
                                <a href="https://www.facebook.com/Spacia-Africa-185163956487562" 
                                   target='_blank' 
                                   rel='noreferrer noopener nofollow'
                                   className="social-icon-link p-2 rounded-circle"
                                   style={{backgroundColor: "rgba(255,255,255,0.1)"}}>
                                    <LogoFacebook color="#ffffff" width="20px" height="20px" />
                                </a>
                                <a href="https://twitter.com/SpaciaAfrica_" 
                                   target='_blank' 
                                   rel='noreferrer noopener nofollow'
                                   className="social-icon-link p-2 rounded-circle"
                                   style={{backgroundColor: "rgba(255,255,255,0.1)"}}>
                                    <LogoTwitter color="#ffffff" width="20px" height="20px" />
                                </a>
                                <a href="https://www.instagram.com/spaciaafrica/" 
                                   target='_blank' 
                                   rel='noreferrer noopener nofollow'
                                   className="social-icon-link p-2 rounded-circle"
                                   style={{backgroundColor: "rgba(255,255,255,0.1)"}}>
                                    <LogoInstagram color="#ffffff" width="20px" height="20px" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="row g-4">
                            <div className="col-6 col-md-4">
                                <h6 className="text-white fw-bold mb-3 text-uppercase" style={{letterSpacing: "1px", fontSize: "0.9rem"}}>Get Started</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-2">
                                        <Link to={redirectBasedOnRole()} className="text-white-50 text-decoration-none hover-white">Find a space</Link>
                                    </li>
                                    <li>
                                        <Link to="/host" className="text-white-50 text-decoration-none hover-white">Become A host</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-4">
                                <h6 className="text-white fw-bold mb-3 text-uppercase" style={{letterSpacing: "1px", fontSize: "0.9rem"}}>Solutions</h6>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link to="/corporate-page" className="text-white-50 text-decoration-none hover-white">Enterprise</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-4">
                                <h6 className="text-white fw-bold mb-3 text-uppercase" style={{letterSpacing: "1px", fontSize: "0.9rem"}}>Support</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-2">
                                        <a href="mailto:sales@spacia.africa" className="text-white-50 text-decoration-none hover-white">Contact us</a>
                                    </li>
                                    <li className="mb-2">
                                        <a href="/corporate-page#faqSection" className="text-white-50 text-decoration-none hover-white">FAQ</a>
                                    </li>
                                    <li className="mb-2">
                                        <a href={'/terms-of-use'} target="_blank" rel="noopener noreferrer" className="text-white-50 text-decoration-none hover-white">Terms & conditions</a>
                                    </li>
                                    <li>
                                        <a href={'/privacy-policy'} target="_blank" rel="noopener noreferrer" className="text-white-50 text-decoration-none hover-white">Privacy policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row border-1 w-100 mt-5 mb-4" style={{ borderBottom: "1px solid rgba(209, 213, 219, 0.5)" }} />

                <div className="row align-items-center pt-2">
                    <div className="col-lg-4 text-center text-lg-start">
                        <p className="text-white-50 mb-0 small">The Address 15th Floor, Muthangari Dr</p>
                    </div>
                    <div className="col-lg-4 text-center">
                        <p className="text-white-50 mb-0 small">&copy; 2022 SPACIA. All rights reserved</p>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <a className="text-white-50 text-decoration-none me-3 small" href="mailto:sales@spacia.africa">sales@spacia.africa</a>
                        <a className="text-white-50 text-decoration-none small" href="tel:+254732188105">+254 732 188 105</a>
                    </div>
                </div>
                <div className="row mt-0">
                    <div className="col-12 text-center">
                        <p className="text-white-50 mb-0" style={{ fontSize: '0.7rem', fontWeight: 300 }}>Powered by theSOFTtribe</p>
                    </div>
                </div>
            </div>

            <style>{`
                .hover-white:hover {
                    color: white !important;
                    transition: color 0.2s ease;
                }
                .social-icon-link {
                    transition: transform 0.2s ease, background-color 0.2s ease;
                }
                .social-icon-link:hover {
                    transform: translateY(-3px);
                    background-color: rgba(255,255,255,0.2) !important;
                }
            `}</style>
        </footer>
    );
};

export default Footer;
