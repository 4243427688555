import {Card, Table} from "react-bootstrap";

import {v4 as uuidv4} from "uuid";
import Pagination from "../../../../../../components/Pagination";
import React, {useEffect, useState} from "react";
import UserActivity from "../../../../../../components/user-activity";
import SERVICES from '../../../../../../services';
import apiService from '../../../../../../services/apiService';
import logger from "../../../../../../utils/logger";

const HomeDashboard = () => {
    const defaultAvatar = 'https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg';

    const [checkInActivities, setCheckInActivities] = useState<any[]>([]);

    const inactive = {
        color: "#9B9B9B",
        fontWeight: "bold",
    };

    const approver = {
        backgroundColor: "#FEE6C2",
        color: "#F99500",
        border: "none",
        fontWeight: "bold",
        borderRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
    };

    useEffect(() => {
        const currentUser = SERVICES.getUser();
        logger.log('current logged in user:', currentUser);
        const ownerId = currentUser ? currentUser.id : 0;

        apiService.getCheckInActivities(ownerId)
            .then(res => {
                logger.log('response:', res.data);
                setCheckInActivities(res.data.data || []);
            })
            .catch(error => {
                logger.error('Error fetching check-in activities:', error);
                setCheckInActivities([]);
            });
    }, []);

    return (
        <section>
            <div className={'mb-4'} style={{marginTop: '100px'}}>
                <p style={{fontSize: '1.3rem'}}>Activity</p>
            </div>
            <Card className="min-h-50" style={{width: '95%'}}>
                <Card.Body>
                    <Table striped>
                        <thead>
                        <tr className="tableUnderline">
                            <th>Name</th>
                            <th>Location</th>
                            <th>Check in</th>
                            <th>Check out</th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {checkInActivities && Array.isArray(checkInActivities) && checkInActivities.length > 0
                            && checkInActivities.map((activity, i) =>  {
                                const location = activity.location;
                                const address = location ? location.address : '-';

                                const checkInAt = activity.checkInAt;
                                const checkOutAt = activity.checkOutAt;

                                const firstName = activity?.firstName;
                                const lastName = activity?.lastName;

                                let name = '-';

                                if (firstName && lastName) {
                                    name = `${firstName} ${lastName}`
                                } else if (firstName) {
                                    name = firstName
                                } else if (lastName) {
                                    name = lastName
                                }

                                return (
                                    <UserActivity
                                        key={uuidv4()}
                                        profile={defaultAvatar}
                                        name={name}
                                        location={location}
                                        checkInDate={checkInAt}
                                        checkOutDate={checkOutAt}
                                    />
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </section>
    );
};

export default HomeDashboard;