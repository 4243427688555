import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { PageTitle } from "../../../../layout/PageData";
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import logger from "../../../../utils/logger";

const Packages = () => {
    const [packages, setPackages] = useState<any[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<any>(null);
    const [newPrice, setNewPrice] = useState<string>("");
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchPackages();
    }, []);

    const fetchPackages = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser ? currentUser.id : 0;
            const response = await apiService.getBundlePricings(userId);

            if (response.data && Array.isArray(response.data.data)) {
                setPackages(response.data.data);
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            logger.error("Error fetching packages:", error);
            setError("Failed to fetch packages. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePackageSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = packages.find(pkg => pkg.id === parseInt(event.target.value));
        setSelectedPackage(selected);
        setNewPrice(selected ? selected.cost.toString() : "");
    };

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPrice(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!selectedPackage) {
            setErrorMessage("Please select a package");
            setShowErrorAlert(true);
            return;
        }

        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser ? currentUser.id : 0;
            const response = await apiService.updateBundlePricing(
                selectedPackage.id,
                {
                    ...selectedPackage,
                    cost: parseFloat(newPrice)
                },
                userId
            );

            if (response.status === 200) {
                setShowSuccessAlert(true);
                fetchPackages();
                setSelectedPackage(null);
                setNewPrice("");
            } else {
                setErrorMessage("Failed to update price. Please try again.");
                setShowErrorAlert(true);
            }
        } catch (error) {
            logger.error("Error updating price:", error);
            setErrorMessage("Failed to update price");
            setShowErrorAlert(true);
        }
    };

    if (isLoading) {
        return <div className="section mt-2 p-3">Loading...</div>;
    }

    if (error) {
        return (
            <div className="section mt-2 p-3">
                <Alert variant="danger">{error}</Alert>
            </div>
        );
    }

    return (
        <div className="mobile-view" style={{ background: "#FCFCFC" }}>
            <div className="section mt-2 p-3">
                <PageTitle>Manage Packages</PageTitle>

                {/* Success and Error Alerts */}
                <SuccessAlert 
                    show={showSuccessAlert} 
                    message="Price updated successfully" 
                    close={() => setShowSuccessAlert(false)} 
                />
                <ErrorAlert 
                    show={showErrorAlert} 
                    message={errorMessage} 
                    close={() => setShowErrorAlert(false)} 
                />

                <div className="card mt-3">
                    <div className="card-body">
                        {packages.length === 0 ? (
                            <Alert variant="info">No packages available.</Alert>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Package</Form.Label>
                                    <Form.Control 
                                        as="select" 
                                        onChange={handlePackageSelect}
                                        className="form-control"
                                    >
                                        <option value="">Choose a package</option>
                                        {packages.map(pkg => (
                                            <option key={pkg.id} value={pkg.id}>
                                                {pkg.bundle.summary} - Current Price: {pkg.cost} {pkg.currency}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        {selectedPackage ? `New Price in ${selectedPackage.currency}` : 'New Price'}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={newPrice}
                                        onChange={handlePriceChange}
                                        placeholder="Enter new price"
                                        className="form-control"
                                    />
                                </Form.Group>

                                <Button 
                                    type="submit" 
                                    disabled={!selectedPackage}
                                    className="btn btn-primary w-100"
                                >
                                    Update Price
                                </Button>
                            </Form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Packages;