import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { usePageData } from "../../layout/PageData";
import { useHistory } from "react-router-dom";
import logger from "../../utils/logger";

interface ScannedData {
    route?: string;
    [key: string]: any;
}

const QRCodeScan: React.FC = () => {
    const [data, setData] = useState<string>('');
    const [error, setError] = useState<string>('');
    const history = useHistory();
    const { setPageTitle } = usePageData();

    useEffect(() => {
        setPageTitle('QR Code Scanner');
    }, [setPageTitle]);

    const handleResult = (result: { text: string } | null, error: Error | null) => {
        if (result) {
            setData(result.text);
            // Handle the scanned data - you can navigate to a specific route or perform an action
            try {
                const scannedData: ScannedData = JSON.parse(result.text);
                if (scannedData.route) {
                    history.push(scannedData.route);
                }
            } catch (err) {
                setError('Invalid QR code format');
            }
        }

        if (error) {
            logger.error(error);
            setError('Error accessing camera');
        }
    };

    return (
        <div className="section">
            <div className="card">
                <div className="card-body">
                    {error && (
                        <div className="alert alert-danger mb-2" role="alert">
                            {error}
                        </div>
                    )}
                    
                    <div className="section-title">Scan QR Code</div>
                    <div className="wide-block pt-2 pb-2">
                        <QrReader
                            onResult={handleResult}
                            constraints={{
                                facingMode: 'environment'
                            }}
                            containerStyle={{ width: '100%' }}
                            videoStyle={{ width: '100%' }}
                            scanDelay={500}
                            videoId="video"
                        />
                        {data && (
                            <div className="mt-2">
                                <div className="section-title">Scanned Data</div>
                                <div className="card">
                                    <div className="card-body">
                                        <pre className="text-wrap">{data}</pre>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QRCodeScan;
